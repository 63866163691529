import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';

@Injectable()
export class ModalStateService {
    private activeModals: NgbModalRef[] = [];

    constructor(private router: Router) {
        this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe(event => {
            this.activeModals.forEach(modal => modal.close());
        });
    }

    public register(modalRef: NgbModalRef) {
        modalRef.result
            .then(_ => this.removeFromActiveModal(modalRef))
            .catch(_ => this.removeFromActiveModal(modalRef));

        this.activeModals.push(modalRef);
    }

    private removeFromActiveModal(modalRef: NgbModalRef) {
        const index = this.activeModals.indexOf(modalRef, 0);
        if (index > -1) {
            this.activeModals.splice(index, 1);
        }
    }
}

