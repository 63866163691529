import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
import { TodoTask } from '../todo-task';

export class AcceptCvChangesTask extends TodoTask{
    taskDescription = `${this.fullName} har sendt sit cv til godkendelse. \nVenligst check dette cv`;
    type = TaskTypeEnum.CVCreatedNotSend;
    taskName = "Gammel cv sendt til godkendelse";
    completeTaskManually = false;

    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/personal-data`
    }
}