import { TaskTypeEnum } from "src/app/core/models/enum/task-type-enum";
import { TodoTask } from "../todo-task";

export class ContractFollowUpTask extends TodoTask {

  taskDescription = `${this.fullName} kontrakt udløber snart. Venligst lav en opfølgning af kontrakten.`;
  taskName = "Kontrakt udløber snart"
  completeTaskManually = true;

  public getRouteLink(): string {
    return `/search/profile/edit/${this.profileId}/personal-data`
  }

}