/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./show-email.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ShowEmailComponent = [i0.styles];
var RenderType_ShowEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowEmailComponent, data: {} });
export { RenderType_ShowEmailComponent as RenderType_ShowEmailComponent };
export function View_ShowEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header panel-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer panel-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Annuller"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailLog.subject; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.emailLog.content; _ck(_v, 7, 0, currVal_1); }); }
export function View_ShowEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-email", [], null, null, null, View_ShowEmailComponent_0, RenderType_ShowEmailComponent)), i1.ɵdid(1, 114688, null, 0, i2.ShowEmailComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowEmailComponentNgFactory = i1.ɵccf("app-show-email", i2.ShowEmailComponent, View_ShowEmailComponent_Host_0, { emailLog: "emailLog" }, {}, []);
export { ShowEmailComponentNgFactory as ShowEmailComponentNgFactory };
