import { TodoTask } from "../todo-task";
export class AccountManagerNewTaskCustomer extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `Der er blevet oprettet en ny opgave venligst check dette`;
        this.taskName = "Account Manager: Ny opgave fra kunde";
        this.completeTaskManually = true;
    }
    getRouteLink() {
        return `/tasks/tasks`;
    }
}
