import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { LinkedInUser } from '../models/LinkedIn-user';
import { UserTmp } from '../models/user-tmp';
import { SomePost } from '../models/facebook-post';

@Injectable({
  providedIn: 'root'
})
export class LinkedInApiService {

  private apiBaseUrl = `${environment.apiUrl}linkedIn`;

  constructor(private httpClient: HttpClient) { }

  public getAccessToken(code: string, redirectUrl: string, state: string): Observable<Map<string, any>> {
    var url = `${this.apiBaseUrl}/GetAccessToken?code=${code}&redirectUrl=${redirectUrl}&state=${state}`
    return this.httpClient.get<Map<string, any>>(url);
  }

  public getProfileLiteInfo(accessToken: string){
    return this.httpClient.get(`${this.apiBaseUrl}/GetPerson?accessToken=${accessToken}`);
  }

  public authenticate(code: string, state: string, redirectUrl: string): Observable<LinkedInUser>{
    return this.httpClient.get<LinkedInUser>(`${this.apiBaseUrl}/Auth?code=${code}&state=${state}&redirectUrl=${redirectUrl}`)
  }

  public socialAuth(code: string, state: string, redirectUrl: string) {
    return this.httpClient.get<boolean>(`${this.apiBaseUrl}/AuthOrg?code=${code}&state=${state}&redirectUrl=${redirectUrl}`);
  }
  public createUser(userTmp: UserTmp): Observable<User>{
    return this.httpClient.post<User>(`${this.apiBaseUrl}/createUser`, userTmp);
  }

  public linkedInPost(linkedInPost: FormData){
    return this.httpClient.post<boolean>(`${this.apiBaseUrl}/LinkedInPost`, linkedInPost);
  }
}
