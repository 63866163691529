import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}notification`;
    }
    post() {
        return this.httpClient.post(this.apiBaseUrl, null);
    }
    getCurrentUserNotifications(page, itemsPerPage) {
        const params = new HttpParams()
            .set("page", "" + page)
            .set("limit", "" + itemsPerPage);
        return this.httpClient.get(`${this.apiBaseUrl}/user`, { params: params });
    }
    getCurrentUserUnreadNotificationCount() {
        return this.httpClient.get(`${this.apiBaseUrl}/user-unread-count`, {});
    }
    setNotificationRead(notificationId) {
        return this.httpClient.post(`${this.apiBaseUrl}/read/${notificationId}`, {});
    }
}
NotificationApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationApiService_Factory() { return new NotificationApiService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationApiService, providedIn: "root" });
