import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as SignalR from "@microsoft/signalr";
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastrService: ToastrService, private router: Router) {
  }

  private hubConnection: SignalR.HubConnection;

  private listeners: ((...args: any[]) => void)[] = [];

  public startConnection(user: User) {
    setTimeout(() => {
      this.hubConnection = new SignalR.HubConnectionBuilder()
        .withUrl(environment.appUrl + `notificationHub?userId=${user.id}`).build()

      this.hubConnection.start()
        .then(() => {
          console.log("Connection to notification hub has been started")
        })
        .catch((error) => {
          console.log(error);
        })
    }, 2000);
  }

  public notificationListener() {
    setTimeout(() => {
      this.hubConnection.on('sendNotification', (data) => {
        var test = this.toastrService.info(data.content, data.header);
        test.onTap.subscribe(action => {
          this.router.navigate([data.link]);
        });

        // Notify listeners
        this.listeners.forEach(listener => listener(data));
      });
    }, 2500);
  }

  public addNotificationListener(listener: (...args: any[]) => void) {
      this.listeners.push(listener);
  }

  public removeNotificationListener(listener: (...args: any[]) => void) {
      this.listeners = this.listeners.filter(l => l !== listener);
  }

  public hasConn() {
    return this.hubConnection != null ? true : false;
  }
}
