import { Activity } from 'src/app/core/models/activity';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
import { TodoTaskStatusEnum } from 'src/app/core/models/enum/todo-task-status-enum';


export class TodoTasksDto {
    public id: number;
    public userId: number;
    public relatedContext: number;
    public fullName: string;
    public profileId: number;
    public adminId: number;
    public priority: number;
    public taskStatus: TodoTaskStatusEnum;
    public taskTypeId: number;
    public createdDate: Date;
    public activity: Activity;
    public deadline: Date;
    public completedDate: Date;
}