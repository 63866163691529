<div class="notification-title">
    Notifikationer
</div>

<div *ngIf="totalNotifications === 0" class="notification-empty">
    Du har ingen notifikationer
</div>

<div *ngIf="totalNotifications !== 0" class="notification-pagination">
    <div class="notification-page-button" [class.disabled]="page <= 0" (click)="onPrevPageClick()"><i class="fa fa-chevron-left"></i></div>
    <div>Side {{ page + 1 }} af {{ getTotalNumberOfPages() }} </div>
    <div class="notification-page-button" [class.disabled]="page >= getTotalNumberOfPages() - 1" (click)="onNextPageClick()"><i class="fa fa-chevron-right"></i></div>
</div>

<div class="notification-list">
    <div *ngFor="let notificationItem of notificationListItems" class="notification-list-item" [class.notification-unread]="!notificationItem.isRead" (click)="onClickNotificationItem($event, notificationItem)">

        <!-- Header -->
        <div class="notification-item-header">
            {{notificationItem.header}}
        </div>

        <!-- Body when collapsed -->
        <div class="notification-item-body-collapsible notification-item-body-collapsed" *ngIf="expandedNotificationId != notificationItem.id">
            <div class="notification-item-body-summary">
                {{notificationItem.content}}
            </div>
            <div class="notification-item-body-time">
                {{ notificationItem.createdDate | date: dateFormatEnum.Full}}
            </div>
        </div>

        <!-- Body when expanded -->
        <div class="notification-item-body-collapsible notification-item-body-expanded" *ngIf="expandedNotificationId == notificationItem.id">
            <div class="notification-item-body-summary">
                {{notificationItem.content}}
            </div>
            <div class="notification-item-body-other">
                <div class="notification-item-body-link">
                    <a (click)="onGoToClick(notificationItem.link)" class="btn btn-primary">Gå til</a>
                </div>
                <div class="notification-item-body-time">
                    {{ notificationItem.createdDate | date: dateFormatEnum.Full}}
                </div>
            </div>
        </div>

    </div>
</div>

