import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { link } from 'fs';
import { SpinnerService } from '../core/services/spinner.service';
import { LinkedInApiService } from '../core/api/linked-in-api.service';
import { LinkedInService } from '../core/services/linked-in.service';

@Component({
  selector: 'app-linked-in-social-auth',
  templateUrl: './linked-in-social-auth.component.html',
  styleUrls: ['./linked-in-social-auth.component.scss']
})
export class LinkedInSocialAuthComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private linkedInApiService: LinkedInApiService,
    private linkedInService: LinkedInService,
    private router: Router,
    private spinner: SpinnerService,
  ) { }

  ngOnInit() {
    const code = this.activatedRoute.snapshot.queryParams.code;
    const state = this.activatedRoute.snapshot.queryParams.state;
    const redirectUrl = window.location.origin + window.location.pathname;

    this.linkedInApiService.socialAuth(code, state, redirectUrl).subscribe(result => {
      if (result) {
        this.linkedInService.linkedInSocialAuthenticated = true;
        if (!window.opener) {
          this.router.navigate([`tasks/${state}`]);
        }
        else {
          window.close();
        }
      }
    });
  }

}
