import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}user`;
    }
    forgotPassword(email) {
        let params = new HttpParams().set('email', encodeURIComponent(email));
        return this.httpClient.post(`${this.apiBaseUrl}/forgotPassword`, params);
    }
    changePassword(changePassword) {
        return this.httpClient.post(`${this.apiBaseUrl}/changePassword`, changePassword);
    }
    createUser(userTmp) {
        return this.httpClient.post(`${this.apiBaseUrl}/createUser`, userTmp);
    }
    confirmUserCreation(securityToken) {
        let params = new HttpParams();
        params = params.set('securityToken', securityToken);
        return this.httpClient.post(`${this.apiBaseUrl}/confirmUserCreation`, params);
    }
    patchLanguage(value) {
        let params = new HttpParams();
        params = params.set('lang', value);
        return this.httpClient.patch(`${this.apiBaseUrl}/patchLanguage`, params);
    }
    requestChangePassword() {
        return this.httpClient.get(`${this.apiBaseUrl}/requestChangePassword`);
    }
}
UserApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserApiService_Factory() { return new UserApiService(i0.ɵɵinject(i1.HttpClient)); }, token: UserApiService, providedIn: "root" });
