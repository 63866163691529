import { TodoTask } from "../todo-task";
export class StartupPackageTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} skal modtage en opstartspakke`;
        this.taskName = "Opstartspakke til konsulent";
        this.completeTaskManually = true;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/profile-log`;
    }
}
