import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SurveyAnswerChoice } from '../models/survey-answer-choice';

@Injectable({
    providedIn: 'root'
})

export class SurveyAnswerChoiceApiService {
    private apiBaseUrl = `${environment.apiUrl}surveyAnswerChoice`;

    constructor(private httpClient: HttpClient) { };

    public postChoice(choice: SurveyAnswerChoice) {
      return this.httpClient.post<SurveyAnswerChoice>(this.apiBaseUrl, choice);
    }

    public patchChoice(choice: SurveyAnswerChoice) {
      return this.httpClient.patch<SurveyAnswerChoice>(this.apiBaseUrl, choice);
    }

    public deleteChoice(id: number) {
      return this.httpClient.delete<SurveyAnswerChoice>(`${this.apiBaseUrl}/${id}`);
    }
  }