<div class="wrapper" [class.vh-100]="showMessage">
    <div class="text-center header-wrapper">
        <a href="/">
            <img class="m-4" src="assets/images/pitten_plain_red.svg" height="45" alt="PITTEN" />
        </a>

        <h1 *ngIf="survey.externalTitle else internalTitle;" class="m-0" style="font-size: 34px;">{{survey.externalTitle}}</h1>
        <ng-template #internalTitle>
            <h1 class="m-0" style="font-size: 34px;">{{survey.title}}</h1>
        </ng-template>

        <p *ngIf="!showMessage" class="mb-5 w-50 mx-auto">
            {{survey.description}}
        </p>
    </div>

    <div class=" row ">
        <div class=" d-none d-sm-block col-md-1 col-lg-3 ">
        </div>
        <div class=" col-12 col-sm-6 col-md-10 col-lg-6 text-left ">
            <div *ngIf="showMessage">
                <div class="text-center p-3">
                    <h3 *ngIf="!limitReached" class="mt-5">Tak for at besvare survey.</h3>
                    <h3 *ngIf="limitReached" class="mt-5">Spørgeskemaet er lukket for besvarelser</h3>
                </div>
                <div class="text-center mt-5 btn-container">
                    <a href="/" class="btn btn-primary mr-3">Pitten</a>
                    <a href="https://www.linkedin.com/company/peopleitdk/ " class="btn btn-primary ml-3">Linkedin</a>
                </div>
            </div>

            <div *ngIf="!showMessage">
                <div class="question-box" *ngFor="let item of survey.surveyQuestions; let i=index">
                    <p class="font-weight-bold " style="color:#6d6d7d">{{i + 1}}. spørgsmål</p>
                    <p class="text-uppercase h3">{{item.question}}</p>
                    <div [ngSwitch]="item.answerType">
                        <ng-container *ngSwitchCase="0">
                            <app-stars-question [item]="item" (answerChanged)="answerChanged(surveyAnswers[i], $event)">
                            </app-stars-question>
                        </ng-container>
                        <ng-container *ngSwitchCase="1" class="textarea-wrapper">
                            <p class="text-length m-0">Maks 1000 tegn
                                <span>{{surveyAnswers[i].answer? surveyAnswers[i].answer.length:0}}</span>/1000
                            </p>
                            <textarea maxlength="1000"
                                [(ngModel)]="surveyAnswers[i].answer"></textarea>
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                            <app-yes-no-question [index]="i" (answerChanged)="answerChanged(surveyAnswers[i], $event)">
                            </app-yes-no-question>
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                            <app-multiple-choice-question [question]="item" (answerChanged)="answerChanged(surveyAnswers[i], $event)">
                            </app-multiple-choice-question>
                        </ng-container>
                        <div *ngIf="survey.surveyQuestions[i].comment">
                            <p></p>
                            <p></p>
                            <p>Uddybende kommentar? Tilføj her:</p>
                            <p class="text-length m-0">Maks 1000 tegn
                                <span>{{surveyAnswers[i].comment? surveyAnswers[i].comment.length:0}}</span>/1000
                            </p>
                            <textarea maxlength="1000"
                                [(ngModel)]="surveyAnswers[i].comment"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center pb-4 pt-3" *ngIf="!showMessage">
                <button class="btn btn-success " (click)="sendAnswers()">Send svar</button>
            </div>
        </div>
        <div class="d-none d-sm-block col-md-1 col-lg-3">
        </div>
    </div>
</div>