export class TaskSkill {
  constructor() {}

  id: number;
  skill: string;
  isRequired: boolean;
  isTech: boolean;

  /**
   * Split the TaskSkills into 3 categories.
   * `requiredCompetences`, `wantedCompetences`, and `technologies`.  
   * Determind based on their `isRequired` and `isTech` properties.
   */
  public static split(taskSkills: TaskSkill[]): {
    requiredCompetences: TaskSkill[];
    wantedCompetences: TaskSkill[];
    technologies: TaskSkill[];
  } {
    return {
      // Export and conditions
      requiredCompetences: taskSkills.filter(ts => ts.isRequired && !ts.isTech),
      wantedCompetences: taskSkills.filter(ts => !ts.isRequired && !ts.isTech),
      technologies: taskSkills.filter(ts => ts.isRequired && ts.isTech),
    }
  }
}