import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';

export class CvSentTask extends TodoTask{

    taskDescription = `${this.fullName} har sendt sit CV til godkendelse, review CV'et og enten godkend eller afvis`;
    type = TaskTypeEnum.CVSentForApproval;
    taskName = "Cv er blevet sendt ";
    completeTaskManually = false;
    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}