import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from '../../../../../core/models/enum/task-type-enum';
export class ProfileLogAddedTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} har skrevet eller redigeret en kommentar`;
        this.type = TaskTypeEnum.ProfileLogAdded;
        this.taskName = "Kommentar skrevet af konsulent";
        this.completeTaskManually = true;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/profile-log`;
    }
}
