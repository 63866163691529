<div class="modal-header">
        <h5>{{ title }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 note">
                   {{ message }}
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-12 text-right">
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="accept()">{{ confirmButton }}</button>
            <button type="button" class="btn btn-primary margin-left-10px" data-dismiss="modal" (click)="decline()">{{ declineButton }}</button>
        </div>
    </div>
