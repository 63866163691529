/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-image.component";
var styles_ProfileImageComponent = [i0.styles];
var RenderType_ProfileImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileImageComponent, data: {} });
export { RenderType_ProfileImageComponent as RenderType_ProfileImageComponent };
function View_ProfileImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "searchimage"]], [[8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, (("url(" + (_co.getUrl() || _co.missingImageSrc)) + ")")); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.alt, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [["class", "profile-image img-responsive"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "rounded-circle": 0, "fit-image": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = "profile-image img-responsive"; var currVal_3 = _ck(_v, 3, 0, _co.roundedImg, _co.isCard); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.getUrl() || _co.missingImageSrc), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.alt, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ProfileImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileImageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["regular", 2]], null, 0, null, View_ProfileImageComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showAsBackground; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProfileImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-image", [], null, null, null, View_ProfileImageComponent_0, RenderType_ProfileImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProfileImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileImageComponentNgFactory = i1.ɵccf("app-profile-image", i3.ProfileImageComponent, View_ProfileImageComponent_Host_0, { isCard: "isCard", alt: "alt", roundedImg: "roundedImg", missingImageSrc: "missingImageSrc", showAsBackground: "showAsBackground", imagePath: "imagePath", width: "width", height: "height" }, {}, []);
export { ProfileImageComponentNgFactory as ProfileImageComponentNgFactory };
