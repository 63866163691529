import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EmailLogApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}EmailLog`;
    }
    GetEmails(page, itemsPerPage) {
        const params = new HttpParams()
            .set("page", "" + page)
            .set("limit", "" + itemsPerPage);
        return this.httpClient.get(`${this.apiBaseUrl}/getEmails`, { params: params });
    }
}
EmailLogApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailLogApiService_Factory() { return new EmailLogApiService(i0.ɵɵinject(i1.HttpClient)); }, token: EmailLogApiService, providedIn: "root" });
