import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmModalService } from './confirm-modal.service';
import { Observable, of } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(component: CanComponentDeactivate) {

    /*
    The return value would be true, unless the canActivate function,
    defined on the component returns false,
    in which case the function will open a Dialog Box,
    to ask if we want to stay on the page or leave the page.
    */
   return component.canDeactivate ? component.canDeactivate() : of(true);
  }
}
