import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkedInService {

  linkedInSocialAuthenticated: boolean = false;

  constructor() { }


  public linkedInLogin(redirectUri: string, scopes: string, state) {
    //"https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" + this.app_id + "&redirect_uri=" + this.callback + "&state=" + this.csrf + "&scope=" + this.scopes;
    var baseLinkedInUrl = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=";
    var scopes = scopes.replace(/ /g, "%20");
    var ssl = false;

    var fullUrl = `${baseLinkedInUrl}${environment.linkedInAppId}&redirect_uri=${redirectUri}&state=${state}&scope=${scopes}`;
    window.location.href = fullUrl;
  }

  public linkedInLoginPopup(redirectUri: string, scopes: string, state, onClosed?: () => void) {
    var baseLinkedInUrl = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=";
    var scopes = scopes.replace(/ /g, "%20");
    var ssl = false;

    var fullUrl = `${baseLinkedInUrl}${environment.linkedInAppId}&redirect_uri=${redirectUri}&state=${state}&scope=${scopes}`;
    let authWinName = "linkedInLogin" + Math.random(); // Noticed an issue if using the same name twice in 1 session.
    let authWin: Window;
    let attemptsLeft = 50;
    do {
      authWin = window.open(fullUrl, authWinName, "width=800,height=600");
    } while (!authWin && --attemptsLeft > 0)

    if (authWin) {
      let int = setInterval(() => {
        if (authWin.closed) {
          if (typeof onClosed === "function") onClosed();
          clearInterval(int);
        }
      }, 500);
    }
  }
}
