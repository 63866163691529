import { PagingModule } from '../shared-ui/paging/paging.module';
import { NgModule } from "@angular/core";
import { SurveysRoutingModule } from './surveys-routing.module';
import { SurveysComponent } from './components/surveys/surveys.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AddSurveyComponent } from './components/add-survey/add-survey.component';
import { SurveyResultComponent } from './components/survey-result/survey-result.component';
import { ContextMenuModule } from '../shared-ui/context-menu/context-menu.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        SurveysComponent,
        AddSurveyComponent,
        SurveyResultComponent,
    ],
    imports: [
        CommonModule,
        SurveysRoutingModule,
        PagingModule,
        FormsModule,
        ContextMenuModule,
        TranslateModule

    ]
})

export class SurveysModule { }