import { environment } from 'src/environments/environment';
import { map, publishReplay, refCount } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CommonDataService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}commondata`;
    }
    getConsultantRoles() {
        if (!this.consultantRoles) {
            this.consultantRoles = this.httpClient.get(`${this.apiBaseUrl}/getconsultantroles`).pipe(map(data => data), publishReplay(1), // this tells Rx to cache the latest emitted
            refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );
        }
        return this.consultantRoles;
    }
    getCustomers() {
        if (!this.customers) {
            this.customers = this.httpClient.get(`${this.apiBaseUrl}/getCustomers`).pipe(map(data => data), publishReplay(1), // this tells Rx to cache the latest emitted
            refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );
        }
        return this.customers;
    }
    getConsultantStatuses() {
        if (!this.consultantStatuses) {
            this.consultantStatuses = this.httpClient.get(`${this.apiBaseUrl}/getconsultantStatusesSearch`).pipe(map(data => data), publishReplay(1), // this tells Rx to cache the latest emitted
            refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );
        }
        return this.consultantStatuses;
    }
}
CommonDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonDataService_Factory() { return new CommonDataService(i0.ɵɵinject(i1.HttpClient)); }, token: CommonDataService, providedIn: "root" });
