import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagingComponent } from './paging/paging.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [PagingComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [PagingComponent]
})
export class PagingModule { }
