<header>
    <div class="customer-language-picker-area">
        <div class="picker-label">
            <label class="switch">
                <input
                    type="checkbox"
                    (change)="useLang($event.target.checked ? 'en' : 'da')"
                    [checked]="currentLang === 'en'"
                />
                <span class="slider round">
                    <span class="lang lang-da">Dansk</span>
                    <span class="slider-color"></span>
                    <span class="lang lang-en">English</span>
                </span>
            </label>
        </div>
    </div>
</header>

<div class="login-page">
    <section class="login-page__body">
        <div class="login-page__center">
            <div class="login-page__panel">
                <div class="login-page__panel-header">
                    <a href="/" class="login-page__logo">
                        <img
                            src="assets/images/Pitten_plain_new.svg"
                            alt="PITTEN"
                        />
                    </a>
                </div>
                <div class="login-page__panel-body">
                    <p class="login-page__title">
                        {{ "manual.welcome" | translate }}
                    </p>
                    <p class="login-page__subtitle">
                        {{ "loginPage.loginTag" | translate }}
                    </p>
                    <form
                        id="loginForm"
                        [formGroup]="loginForm"
                        (ngSubmit)="onSubmit()"
                        class="login-page__form"
                    >
                        <div
                            class="login-page__form-group"
                            [hidden]="displayTwoFactor"
                        >
                            <div class="login-page__input-group">
                                <input
                                    formControlName="email"
                                    type="text"
                                    class="login-page__input"
                                    placeholder="E-mail"
                                />
                            </div>
                        </div>

                        <div
                            class="login-page__form-group"
                            [hidden]="displayTwoFactor"
                        >
                            <div class="login-page__label-group"></div>
                            <div class="login-page__input-group">
                                <input
                                    formControlName="password"
                                    type="password"
                                    class="login-page__input"
                                    placeholder="Password"
                                />
                                <a
                                    routerLink="/forgot-password"
                                    class="login-page__link"
                                    >{{ "loginPage.forgotPw" | translate }}</a
                                >
                            </div>
                        </div>

                        <div
                            class="login-page__form-group"
                            [hidden]="!displayTwoFactor"
                        >
                            <label class="login-page__label"
                                >Two-Factor Authentication code</label
                            >
                            <div
                                class="login-page__input-group login-page__two-factor"
                            >
                                <input
                                    formControlName="twoFactorCode"
                                    type="text"
                                    class="login-page__input"
                                />
                                <span class="login-page__input-icon"
                                    ><i class="fa fa-lock"></i
                                ></span>
                            </div>
                        </div>

                        <div class="login-page__action">
                            <button
                                type="submit"
                                class="login-page__submit-btn new-btn new-btn-dark"
                            >
                                {{ "loginPage.login" | translate }}
                            </button>
                        </div>

                        <div class="login-page__footer">
                            <p class="login-page__footer-text">
                                {{ "loginPage.ifUser?" | translate
                                }}<a
                                    routerLink="/create-profile"
                                    class="login-page__footer-text--link"
                                    >{{ "loginPage.signUp" | translate }}</a
                                >
                            </p>
                            <div class="login-page__footer-links">
                                <a
                                    href="https://people-it.dk/nyheder/"
                                    target="_blank"
                                    >{{
                                        "loginPage.newsFromPeopleIT" | translate
                                    }}</a
                                >
                                <a
                                    href="/assets/data-protection-and-privacy-policy.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >{{
                                        "labels.dataAndPrivacy" | translate
                                    }}</a
                                >
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="login-page__background"></div>
