import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
/**Controls the url prefix for which language this application is running on */
export class LangService {
    constructor(translateService) {
        this.translateService = translateService;
    }
    changeLang(lang) {
        this.translateService.use(lang);
        localStorage.setItem('lang', lang);
    }
    getSavedLang() {
        let lang = localStorage.getItem('lang');
        if (lang) {
            return lang;
        }
        return 'da';
    }
}
LangService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LangService_Factory() { return new LangService(i0.ɵɵinject(i1.TranslateService)); }, token: LangService, providedIn: "root" });
