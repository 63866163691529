/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./notification.component";
import * as i3 from "../core/services/notification.service";
import * as i4 from "../core/services/authentiation.service";
var styles_NotificationComponent = [i0.styles];
var RenderType_NotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationComponent, data: {} });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
export function View_NotificationComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_NotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i1.ɵdid(1, 114688, null, 0, i2.NotificationComponent, [i3.NotificationService, i4.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationComponentNgFactory = i1.ɵccf("app-notification", i2.NotificationComponent, View_NotificationComponent_Host_0, {}, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
