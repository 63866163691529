<div class="modal-header panel-heading">
  <h2 class="modal-title">{{emailLog.subject}}</h2>
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div [innerHTML]="emailLog.content"></div>

</div>
<div class="modal-footer panel-footer">
  <button type="button" (click)="close()" class="btn btn-secondary">Annuller</button>
</div>
