import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyApiService } from 'src/app/core/api/survey-api.service';
import { Survey } from 'src/app/core/models/survey';
import { SurveyQuestion } from 'src/app/core/models/survey-question';
import { SurveyAnswer } from 'src/app/core/models/survey-answer';
import { AnswerTypeEnum } from 'src/app/core/models/enum/answer-type-enum';
import { ToastrService } from 'ngx-toastr';
import { SurveyResponse } from 'src/app/core/models/survey-response';
import { SpinnerService } from '../../../core/services/spinner.service';

@Component({
  selector: 'app-survey-attempt',
  templateUrl: './survey-attempt.component.html',
  styleUrls: ['./survey-attempt.component.scss']
})
export class SurveyAttemptComponent implements OnInit {

  private sharedSurveyId;
  public survey: Survey = new Survey;
  public surveyAnswers: SurveyAnswer[] = [];
  public showMessage: boolean = false;
  public limitReached: boolean = false;
  public isAnonymous: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private surveyApiService: SurveyApiService,
    private spinner: SpinnerService,
  ) { }

  //TODO: Is form required for this part of the application
  ngOnInit() {
    this.sharedSurveyId = this.activatedRoute.snapshot.params.id;
    //this.readStateLocalStorage();
    this.spinner.show();
    //In case this is survey for specific user
    if (this.sharedSurveyId != null) {
      this.surveyApiService.getSurvey(this.sharedSurveyId).subscribe(result => {
        this.processData(result);
        this.isAnonymous = false;
      });
    }
    else {
      let uid = this.activatedRoute.snapshot.queryParams.uid;
      this.surveyApiService.getSharedSurvey(uid).subscribe(result => {
        this.processData(result);
        //Doing this in case we dont have any shared survey id for anonymous users;
        this.surveyApiService.getSharedSurveyURL(this.survey.id).subscribe(result => {
          this.sharedSurveyId = result.id;
          this.isAnonymous = true;
        })
      }, e => {
        this.limitReached = true;
        this.showMessage = true;
      })
    }
  }

  private processData(result: Survey){
    this.spinner.hide();
    this.survey = result;
    this.survey.surveyQuestions.forEach(element => {
      var surveyAnswer = new SurveyAnswer();
      surveyAnswer.surveyQuestionId = element.id;
      if (element.answerType == AnswerTypeEnum.Range) {
        surveyAnswer.answer = "1";
      }
      this.surveyAnswers.push(surveyAnswer);
    });
  }

  private saveStateLocalStorage() {
    if (this.isAnonymous) {
      let uid = this.activatedRoute.snapshot.queryParams.uid
      localStorage.setItem(uid, "answered");
    }
    else localStorage.setItem(this.sharedSurveyId, "answered");
  }

  private readStateLocalStorage() {
    if (this.sharedSurveyId) {
      if (localStorage.getItem(this.sharedSurveyId)) {
        this.showMessage = true;
      }
    }
    else{
      var uid = this.activatedRoute.snapshot.queryParams.uid
      if (localStorage.getItem(uid)) {
        this.showMessage = true;
      }
    }
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  radioChange(event, item: SurveyQuestion) {
    var target = event.target;
    if (target.checked) {

    }
  }

  answerChanged(answer: SurveyAnswer, event) {
    answer.answer = event;
  }

  sendAnswers() {
    var bool: boolean = false;
    var count = 0;
    this.surveyAnswers.forEach(element => {
      if (element.answer == null || element.answer.length == 0) {
        count++;
      }
    });
    if (count == 0) {
      var surveyResponse = new SurveyResponse();
      surveyResponse.surveySharedId = this.sharedSurveyId
      surveyResponse.surveyAnswers = this.surveyAnswers;
      surveyResponse.surveyId = this.survey.id;
      this.showMessage = true
      this.surveyApiService.postAnswer(surveyResponse).subscribe(result => {
        this.toastrService.success("Din survey er modtaget");
        //this.saveStateLocalStorage();
      });
    }
    else {
      this.toastrService.error("Du skal udfylde alle spørgsmål tak");
      console.log(this.surveyAnswers);
    }
  }

}
