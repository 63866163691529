import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransContent } from '../../models/trans-content';

@Pipe({
  name: 'translateContent'
})
export class TranslateContentPipe implements PipeTransform {

  currentLang;
  constructor(private translateService: TranslateService) {
    this.currentLang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe(result => {
      this.currentLang = result;
    })
  }

  transform(value: TransContent): any {
    console.log(value);
    switch (this.currentLang) {
      case 'da':
        return value.name
      case 'en':
        return value.nameUk
      default:
        break;
    }
  }

}
