import { environment } from "src/environments/environment";
import { HttpClient, HttpResponse, } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TaskApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}task`;
    }
    get(taskId) {
        return this.httpClient.get(`${this.apiBaseUrl}/${taskId}`);
    }
    searchTasks(searchText) {
        return this.httpClient.get(`${this.apiBaseUrl}/taskSmalls?searchText=${searchText}`);
    }
    searchActiveTasks(searchText, profileId) {
        return this.httpClient.get(`${this.apiBaseUrl}/taskActiveSmalls?searchText=${searchText}&profileId=${profileId}`);
    }
    find(taskSearchParameters) {
        return this.httpClient.post(`${this.apiBaseUrl}/find`, taskSearchParameters);
    }
    getAll() {
        return this.httpClient.get(`${this.apiBaseUrl}/getAll`);
    }
    getAllNoFilter() {
        return this.httpClient.get(`${this.apiBaseUrl}/getAllNoFilter`);
    }
    getTasksByProfileId(profileId) {
        return this.httpClient.get(`${this.apiBaseUrl}/getTasksByProfileId/${profileId}`);
    }
    getAsCsv(taskId) {
        return this.httpClient.get(`${this.apiBaseUrl}/getAsCsv/${taskId}`, {
            observe: "response",
            responseType: "blob",
        });
    }
    findProfileTasks(profileTaskSearchParameters) {
        return this.httpClient.post(`${this.apiBaseUrl}/findProfileTasks`, profileTaskSearchParameters);
    }
    /**
     * getProfileTasks
     * Finds all the tasks this profile is in
     */
    getProfileTasks(profileId) {
        return this.httpClient.get(`${this.apiBaseUrl}/getProfileTasks/${profileId}`);
    }
    /**
     * getProfiles
     * Finds the profiles related to specific task by id
     */
    getProfiles(taskId) {
        return this.httpClient.get(`${this.apiBaseUrl}/getProfiles/${taskId}`);
    }
    addProfiles(addProfilesToTask) {
        return this.httpClient.post(`${this.apiBaseUrl}/addProfiles`, addProfilesToTask);
    }
    saveComment(taskConsultantComment) {
        return this.httpClient.post(`${this.apiBaseUrl}/saveComment`, taskConsultantComment);
    }
    updateTasksProfileRelations(profileTasks) {
        return this.httpClient.post(`${this.apiBaseUrl}/updateTasksProfileRelations`, profileTasks);
    }
    deleteTaskProfileRelation(profileTaskId) {
        return this.httpClient.delete(`${this.apiBaseUrl}/deleteTaskProfileRelation/${profileTaskId}`);
    }
    sendEmail(taskSendEmail) {
        return this.httpClient.post(`${this.apiBaseUrl}/sendEmail`, taskSendEmail);
    }
    save(task) {
        return this.httpClient.post(`${this.apiBaseUrl}/save`, task);
    }
    /**
     * Updates a small portion of the task, including UpdatedDate if necessary.
     */
    patchSmall(task) {
        // Automatically set the UpdatedDate to the current time if TaskPositionEnum has changed.
        if (task.taskPosition !== undefined) {
            task.updatedDate = new Date();
        }
        return this.httpClient.patch(`${this.apiBaseUrl}/patchSmallTask`, task);
    }
    delete(taskId) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${taskId}`);
    }
    extractData(taskStatus, startDate, endDate) {
        const params = { taskStatus, startDate, endDate };
        return this.httpClient.post(`${this.apiBaseUrl}/extractData`, params, { observe: "response", responseType: "blob" });
    }
}
TaskApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TaskApiService_Factory() { return new TaskApiService(i0.ɵɵinject(i1.HttpClient)); }, token: TaskApiService, providedIn: "root" });
