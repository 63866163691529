import { AnswerTypeEnum } from './enum/answer-type-enum';
import { SurveyAnswerChoice } from './survey-answer-choice';

export class SurveyQuestion{
    id?: number;
    order: number;
    question?: string;
    answerType: AnswerTypeEnum;
    surveyId?: number;
    surveyAnswerChoices?: SurveyAnswerChoice[]
    comment: boolean;
}