import { TodoTask } from "../todo-task";
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';

export class DateChangedTask extends TodoTask{
    taskDescription =
    `${this.fullName} har opdateret sin rådighedsdato \nvenligst check dette`;
    type = TaskTypeEnum.AvailableDateChanged;
    taskName = "Rådighedsdato opdateret";
    completeTaskManually = true;

    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}