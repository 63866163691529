import { TodoTask } from "../todo-task";
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
export class DateChangedTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} har opdateret sin rådighedsdato \nvenligst check dette`;
        this.type = TaskTypeEnum.AvailableDateChanged;
        this.taskName = "Rådighedsdato opdateret";
        this.completeTaskManually = true;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}
