import { Injectable, EventEmitter } from '@angular/core';
import { ProfileDetailModeEnum } from '../models/enums/profile-detail-mode-enum';
import { AuthenticationService } from '../../core/services/authentiation.service';
import { User } from '../../core/models/user';
import { ProfileStatusEnum } from 'src/app/core/models/enum/Profile-status-enum';
import { ProfileSectionStatus } from '../../core/models/profile-section-status';
import { ProfileSectionEnum } from 'src/app/core/models/enum/profile-section-enum';
import { ProfileApiService } from '../../core/api/profile-api.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { ProfileStatus } from '../../core/models/profile-status';
import { ProfileSectionStatusEnum } from 'src/app/core/models/enum/profile-section-status-enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileDetailsService {
    public onDataSaved = new EventEmitter<any>();

    private profileId: number;
    private profileDetailModeEnum: ProfileDetailModeEnum;
    private currentUser: User;
    private profileStatus: ProfileStatus;
    private disableEditModeAttr = false;
    private profileIdSubject = new BehaviorSubject<number>(0)
    private cvLangSubject = new BehaviorSubject<string>(null);

    public cvLang$ = this.cvLangSubject.asObservable();

    constructor(authenticationService: AuthenticationService,
                private profileApiService: ProfileApiService,
                private spinner: SpinnerService) {
        authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    public disableEditMode() {
        this.disableEditModeAttr = true;
    }
    public setProfileId(profileId: number) {
        this.profileId = profileId;
        this.profileIdSubject.next(profileId);
    }

    public getProfileId(): number {
        return this.profileId;
    }

    public getProfileIdObserver(): Observable<number> {
        return this.profileIdSubject.asObservable();
    }

    public setProfileDetailModeEnum(profileDetailModeEnum: ProfileDetailModeEnum) {
        this.profileDetailModeEnum = profileDetailModeEnum;
    }

    public setProfileStatus(profileStatus: ProfileStatus) {
        this.profileStatus = profileStatus;
    }

    // Method to update the profileSectionStatus, which can be called by each of the profile section components
    public updateProfileSectionStatus(sectionEnum: ProfileSectionEnum, statusEnum: ProfileSectionStatusEnum): void {
        const updatedStatus = this.profileStatus.profileSectionStatuss.map(section => {
          if (section.profileSectionEnum === sectionEnum) {
            section.profileSectionStatusEnum = statusEnum;
          }
          return section;
        });
        this.profileStatus.profileSectionStatuss = updatedStatus;
      }

    // Create a method to update the ProfileStatusEnum
    public updateProfileStatusEnum(statusEnum: ProfileStatusEnum): void {
        this.profileStatus.profileStatusEnum = statusEnum;
    }

    public getProfileSectionStatus(): ProfileSectionStatus[] {
        if (!this.profileStatus) {
            return [];
        }

        return this.profileStatus.profileSectionStatuss;
    }

    public getProfileStatus(): ProfileStatusEnum {
        if (this.profileStatus) {
            return this.profileStatus.profileStatusEnum;
        }
        return ProfileStatusEnum.Na;
    }

    public getProfileDetailModeEnum(): ProfileDetailModeEnum {
        return this.profileDetailModeEnum;
    }

    public getCurrentUser(): User {
        return this.currentUser;
    }

    public isAdmin(): boolean {
        return this.profileDetailModeEnum === ProfileDetailModeEnum.Admin ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.AdminEdit;
    }

    public isProfile(): boolean {
        return this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileCreate ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileEdit;
    }

    public editMode(): boolean {
        if (this.disableEditModeAttr) {
            return false;
        }

        return this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileCreate ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileEdit ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.AdminEdit ||
            this.currentUser.canEdit !== false;
    }

    public getSectionStatus(profileSectionEnum: ProfileSectionEnum): ProfileSectionStatusEnum {
        if (this.profileStatus) {
            return this.profileStatus.profileSectionStatuss.find((profileSection) =>
                profileSection.profileSectionEnum === profileSectionEnum).profileSectionStatusEnum;
        }
        return ProfileSectionStatusEnum.NA;
    }

    public isProfileUnderCreation(): boolean {
        if (this.profileStatus) {
            return this.profileStatus.profileStatusEnum === ProfileStatusEnum.NotTotalCreated;
        }
        return false;
    }

    public initProfileSectionStatus() {
        this.spinner.show();
        this.profileApiService.getProfileStatus(this.profileId).subscribe(result => {
            this.setProfileStatus(result);
            this.spinner.hide();
        });
    }

    public get lang() {
        return this.cvLangSubject.value;
    }

    public setLang(lang: string) {
        this.cvLangSubject.next(lang);
    }
}
