import * as i0 from "@angular/core";
export class SpinnerService {
    constructor() {
        this.showSpinner = false;
    }
    show() {
        this.showSpinner = true;
    }
    hide() {
        this.showSpinner = false;
    }
}
SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
