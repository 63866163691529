import { TaskTypeEnum } from "src/app/core/models/enum/task-type-enum";
import { TodoTask } from "../todo-task";

export class StartupPackageTask extends TodoTask {
  taskDescription = `${this.fullName} skal modtage en opstartspakke`;
  taskName = "Opstartspakke til konsulent";
  completeTaskManually = true;

  public getRouteLink(): string {

    return `/search/profile/edit/${this.profileId}/profile-log`;
  }

}