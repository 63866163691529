import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';

export class CvNotOkTask extends TodoTask{
    taskDescription = `${this.fullName} cv er ikke ok venligst informere konsulenten`;
    type = TaskTypeEnum.CVNotOk;
    taskName = "Cv er ikke ok";
    completeTaskManually = false;

    public getRouteLink(): string {

        return `/search/profile/edit/${this.profileId}/personal-data`
    }
}