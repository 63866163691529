import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivityType } from '../models/activity-type';
import { Activity } from 'src/app/core/models/activity';
import { TodoTasksDto } from 'src/app/dashboard/components/todo-list-full/models/todo-task-dto';
import { TodoTaskType } from '../models/todo-task-type';


@Injectable({
  providedIn: 'root'
})
export class ActivityApiService {

  private apiBaseUrl = `${environment.apiUrl}Activities`;

  constructor(private httpClient: HttpClient) { }

  public getActivityTypes() {
    return this.httpClient.get<ActivityType[]>(`${this.apiBaseUrl}/getTypes`);
  }

  public postActivity(todoTask: TodoTasksDto) {
    return this.httpClient.post<Activity>(`${this.apiBaseUrl}`, todoTask);
  }

  public getActivities() {
    return this.httpClient.get<Activity[]>(`${this.apiBaseUrl}`);
  }

  public patch(todoTask: TodoTasksDto) {
    return this.httpClient.patch(this.apiBaseUrl, todoTask);
  }

  public getTodoTypes() {
    return this.httpClient.get<TodoTaskType[]>(`${this.apiBaseUrl}/getTodoTypes`);
  }
}
