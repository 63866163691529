import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/services/authentiation.service';
import { NotificationService } from '../core/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  constructor(
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(result => {
      if (result != null) {
        this.notificationService.startConnection(result);
        this.notificationService.notificationListener();
      }
    });
  }
}
