import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SurveyApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}survey`;
    }
    ;
    postSurvey(survey) {
        return this.httpClient.post(`${this.apiBaseUrl}`, survey);
    }
    postSurveyQuestion(question) {
        return this.httpClient.post(`${this.apiBaseUrl}/postQuestion`, question);
    }
    postAnswer(surveyResponse) {
        return this.httpClient.post(`${this.apiBaseUrl}/postAnswer`, surveyResponse);
    }
    get(id) {
        return this.httpClient.get(`${this.apiBaseUrl}/${id}`);
    }
    getSurvey(id) {
        return this.httpClient.get(`${this.apiBaseUrl}/getSurvey/${id}`);
    }
    getSharedSurvey(uid) {
        return this.httpClient.get(`${this.apiBaseUrl}/getSharedSurvey?accessUid=${uid}`);
    }
    getSurveys() {
        return this.httpClient.get(`${this.apiBaseUrl}/getSurveys`);
    }
    getSurveysDisableFiltered() {
        return this.httpClient.get(`${this.apiBaseUrl}/getSurveys/open`);
    }
    getResult(id) {
        return this.httpClient.get(`${this.apiBaseUrl}/getResult/${id}`);
    }
    getSharedSurveyURL(id) {
        return this.httpClient.get(`${this.apiBaseUrl}/getSharedSurveyURL/${id}`);
    }
    getSurveyCsvByPost(result) {
        return this.httpClient.post(`${this.apiBaseUrl}/getSurveyCsvByPost`, result, {
            observe: 'response',
            responseType: 'blob'
        });
    }
    deleteSurvey(surveyId) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${surveyId}`);
    }
    deleteSurveyAttempt(surveyAttemptId) {
        return this.httpClient.delete(`${this.apiBaseUrl}/DeleteSurveyAttempt/${surveyAttemptId}`);
    }
    deleteQuestion(questionId) {
        return this.httpClient.delete(`${this.apiBaseUrl}/deleteQuestion/${questionId}`);
    }
    patchSurvey(survey) {
        return this.httpClient.patch(`${this.apiBaseUrl}`, survey);
    }
    patchSurveyQuestion(question) {
        return this.httpClient.patch(`${this.apiBaseUrl}/patchSurveyQuestion`, question);
    }
    sendSurvey(surveyReciever) {
        return this.httpClient.post(`${this.apiBaseUrl}/sendSurvey`, surveyReciever);
    }
    putSharedSurvey(sharedSurvey) {
        return this.httpClient.put(`${this.apiBaseUrl}/putSharedSurvey`, sharedSurvey);
    }
}
SurveyApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SurveyApiService_Factory() { return new SurveyApiService(i0.ɵɵinject(i1.HttpClient)); }, token: SurveyApiService, providedIn: "root" });
