import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SomePost } from '../models/facebook-post';

@Injectable({
  providedIn: 'root'
})
export class FacebookApiService {

  private apiBaseUrl = `${environment.apiUrl}Facebook`;

  constructor(private httpClient: HttpClient) { }

  public get(code: string, redirectUri: string){
    return this.httpClient.get(`${this.apiBaseUrl}?code=${code}&redirectUri=${redirectUri}`);
  }

  public pagePost(post: SomePost){
    return this.httpClient.post<Observable<boolean>>(`${this.apiBaseUrl}/pagePost`, post);
  }
}
