
<div class="btn-group" data-toggle="buttons">
  <label class="btn btn-primary form-check-label" [class.text]="surveyAnswer == 'false'" for="yes+{{index}}">Ja
    <input class="form-check-input d-none" type="radio" value="true" autocomplete="off" name="relevance+{{index}}"
      [(ngModel)]="surveyAnswer" (change)="emitValue($event)" id="yes+{{index}}">
  </label>
  <label class="ml-1 btn btn-primary form-check-label" [class.text]="surveyAnswer == 'true'" for="no+{{index}}">Nej
    <input class="form-check-input d-none" type="radio" value="false" autocomplete="off" name="relevance+{{index}}"
      [(ngModel)]="surveyAnswer" (change)="emitValue($event)" id="no+{{index}}">
  </label>
</div>