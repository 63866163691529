export class GeneralHelper {

    public static getListOfYear(start = 1920, end = (new Date()).getFullYear()): number[] {
        const years: number[] = [];
        for (let year = end; year >= start; year--) {
            years.push(year);
        }

        return years;
    }

    public static getListOfYearEnd(){
        let years: (number | string)[]= []
        years = this.getListOfYear()
        return years
    }
}
