<aside id="sidebar-left" [ngClass]="{'animation':showNavigationMenu}" class="sidebar-left">

  <!-- NAVIGATION-MENU BUTTON -->
  <div class="menu-btn d-none d-md-block d-lg-block d-xl-block ">
    <div class="menu-btn-inner navigation-menu-btn" (click)="toggleNavigationMenu()"  [ngClass]="{'close':showNavigationMenu}">
      <div class="btn-line" [ngClass]="{'btn-line-transform':showNavigationMenu}"></div>
      <div class="btn-line" [ngClass]="{'btn-line-transform':showNavigationMenu}"></div>
      <div class="btn-line" [ngClass]="{'btn-line-transform':showNavigationMenu}"></div>
    </div>
  </div>

  <!-- NAVIGATION-MENU CONTENT -->
  <div class="nano menu-content menu-content-navigation" [ngClass]="{'animation':showNavigationMenu}">
    <div class="nano-content">
      <nav class="nav-main" role="navigation">
        <app-navigation-menu [currentUser]="currentUser" [isAdmin]="isAdmin" [roleText]="roleText" (closeMenusFunc)="closeMenus($event)" (logoutFunc)="logout()"></app-navigation-menu>
      </nav>
    </div>
  </div>

  <!-- NOTIFICATION-MENU BUTTON -->
  <div *ngIf="isAdmin" class="menu-btn d-none d-md-block d-lg-block d-xl-block " (click)="toggleNotificationMenu()">
    <div class="menu-btn-inner notification-menu-btn" [ngClass]="{'close':showNotificationMenu}">
      <i class="fa fa-bell-o notification-menu-icon"></i>
    </div>
    <div *ngIf="unreadNotificationCount > 0" class="notification-unread-count-bubble">{{ unreadNotificationCount }}</div>
  </div>

  <!-- NOTIFICATION-MENU CONTENT -->
  <div class="nano menu-content menu-content-notification" [ngClass]="{'animation':showNotificationMenu}">
    <div class="nano-content">
      <nav class="nav-main" role="navigation">
        <app-notification-menu *ngIf="showNotification" (onUnreadCountChanged)="onUnreadNotificationCountUpdated($event)"></app-notification-menu>
      </nav>
    </div>
  </div>
<!-- </aside> -->

<!-- TODO: Make it so that the buttons don't have to be repeated twice (One for normal and one for mobile). (Currently not done it, so the menu-content can have different y-pos (just below their buttons) in desktop-mode) -->
<div class="menubar-mobile d-block d-sm-block d-md-none">

    <!-- NAVIGATION-MENU BUTTON for mobile menu -->
    <div class="menu-btn d-block d-sm-block " (click)="toggleNavigationMenu()">
      <div class="menu-btn-inner navigation-menu-btn" [ngClass]="{'close':showNavigationMenu}">
        <div class="btn-line" [ngClass]="{'btn-line-transform':showNavigationMenu}"></div>
        <div class="btn-line" [ngClass]="{'btn-line-transform':showNavigationMenu}"></div>
        <div class="btn-line" [ngClass]="{'btn-line-transform':showNavigationMenu}"></div>
      </div>
    </div>

    <!-- NOTIFICATION-MENU BUTTON for mobile menu -->
    <div *ngIf="isAdmin" class="menu-btn d-block d-sm-block " >
      <div class="menu-btn-inner notification-menu-btn" (click)="toggleNotificationMenu()" [ngClass]="{'close':showNotificationMenu}">
        <i class="fa fa-bell-o notification-menu-icon"></i>
      </div>
      <div *ngIf="unreadNotificationCount > 0" class="notification-unread-count-bubble">{{ unreadNotificationCount }}</div>
    </div>

</div>
