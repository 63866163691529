<div class="todo-list-component">
    <div class="d-flex justify-content-between align-items-center">
        <h2> {{ "labels.todos" | translate }} </h2>
        <a *ngIf="isAdmin" title="Opret aktivitet" class="mr-4" (click)="openActivityModal()"><i
                class="fa fa-bell"></i></a>
    </div>

    <table>
        <tr class="table-row">
            <th class="checkable"></th>
            <th style="width: 100px;" class="sortable task-type" [ngClass]="isTaskTypeNameAsc? 'asc' : 'desc'" (click)="sort('taskName')" class="sortable">
                {{ "labels.taskType" | translate }}
                <span class="arrows"></span>
            </th>
            <th style="width: 80px;">
                {{ "labels.priority" | translate }}
            </th>
            <th style="width: 35%;" (click)="sort('description')" class="sortable" [ngClass]="isDescriptionAsc? 'asc' : 'desc'">
                {{ "labels.description" | translate }}
            </th>
            <th style="width: 150px;" (click)="sort('date')" class="sortable" [ngClass]="isDateAsc ? 'asc' : 'desc'">
                {{ "labels.created" | translate }}
                <span class="arrows"></span>
            </th>
            <th style="width: 150px;" (click)="sort('deadline')" class="sortable" [ngClass]="isDeadlineAsc ? 'asc' : 'desc'">
                {{ "labels.deadline" | translate }}
                <span class="arrows"></span></th>
            <th (click)="sort('consultantName')" [ngClass]="isConsultNameAsc ? 'asc' : 'desc'" class="sortable">
                {{ "labels.consultant" | translate }}
                <span class="arrows"></span>
            </th>
        </tr>
        <ng-container *ngIf="tasks.length">
            <tr *ngFor="let task of tasks" [ngClass]="{'row-hover': task.taskStatus != 1, 'is-check-checked': task.selected,
                'highlighted-row': task.profileId == currentProfileId}">
                <!-- <tr *ngFor="let task of tasks" [ngClass]="{'greyed-out': task.taskStatus == 1, 'row-hover': task.taskStatus != 1, 'is-check-checked': task.selected,
                'highlighted-row': task.profileId == currentProfileId}"> -->
                <td>
                    <div *ngIf="task.completeTaskManually == true">
                        <label class="checkbox-label d-flex" *ngIf="task.taskStatus === 0 || task.taskStatus === 1">
                            <input type="checkbox" (change)="taskCheckChanged(task)" [(ngModel)]="task.selected">
                            <span class="checkmark-checkbox"></span>
                        </label>
                    </div>
                </td>
                <td (click)="taskClickEvent(task)" [ngClass]="{'greyed-out': task.taskStatus == 1}">{{task.taskName}}
                </td>
                <td [ngClass]="{'greyed-out': task.taskStatus == 1}">
                    <select [ngModel]="task.priority" [disabled]="task.taskStatus" (ngModelChange)="priorityChanged(task, $event)">
                        <option [value]=1>1</option>
                        <option [value]=2>2</option>
                        <option [value]=3>3</option>
                        <option [value]=4>4</option>
                        <option [value]=5>5</option>
                    </select>
                </td>
                <td [ngClass]="{'greyed-out': task.taskStatus == 1}">
                    <span class="row">
                        <span class="col-10">
                            <p (click)="flipShowNote(task)">{{task.taskDescription}}</p>
                        </span>
                    <span (click)="flipShowNote(task)" class="col-2"><i *ngIf="task.activity" class="fa fa-sticky-note-o pull-right"></i></span>
                    </span>
                    <ng-container *ngIf="task.showNote && task.activity">
                        <label class="note-label">Note:</label>
                        <p *ngIf="!task.activity.noteEditMode" (click)="setNoteEditMode(task, true)">
                            {{task.activity.note}} <i class="fa fa-pencil"></i></p>
                        <div *ngIf="task.activity.noteEditMode" class="row">
                            <span class="col-10">
                                <textarea class="note-editable-box" [(ngModel)]="task.activity.note"></textarea>
                            </span>
                            <span class="col-2">
                                <i (click)="saveNote(task)" class="fa fa-check check pull-right"></i>
                            </span>
                        </div>
                    </ng-container>
                </td>
                <td (click)="taskClickEvent(task)" [ngClass]="{'greyed-out': task.taskStatus == 1}">{{task.createdDate | date: 'dd/MM/yyyy'}}</td>
                <td [ngClass]="{'greyed-out': task.taskStatus == 1}">
                    <input *ngIf="task.deadline" class="form-control datepicker" name="deadlineDate" [(ngModel)]="task.ngbDeadline" (ngModelChange)="updateDeadline(task)" [minDate]="ngbToday" [readonly]="true" type="text" (click)="deadlineDatePicker.toggle()" ngbDatepicker
                        #deadlineDatePicker="ngbDatepicker">
                </td>
                <td (click)="fullNameClickEvent(task)" [ngClass]="{'column-hover': task.taskStatus == 1}">
                    {{task.fullName}}</td>
            </tr>
        </ng-container>
    </table>
    <div class="my-4 ml-4">
        <a class="see-more" *ngIf="!showFinishedTasks" (click)="loadFinishedTodos()">{{ "labels.viewCompletedTasks" | translate }}</a>
        <a class="see-more" *ngIf="showFinishedTasks" (click)="loadFinishedTodos()">{{ "labels.hideCompletedTasks" | translate }}</a>
    </div>
    <ng-container *ngIf="showFinishedTasks">
        <table>
            <tr class="greyed-out">
                <th></th>
                <th style="width: 100px;"> {{ "labels.taskType" | translate }} </th>
                <th style="width: 80px;"> {{ "labels.priority" | translate }} </th>
                <th style="width: 35%;"> {{ "labels.description" | translate }} </th>
                <th style="width: 150px"> {{ "labels.created" | translate }} </th>
                <th style="width: 150px"> {{ "labels.finished" | translate }} </th>
                <th> {{ "labels.consultant" | translate }} </th>
            </tr>
            <tr *ngFor="let task of finishedTasks" class="greyed-out" [ngClass]="{'highlighted-row': task.profileId == currentProfileId}">
                <td class="checkable"></td>
                <td style="width: 100px;">{{task.taskName}}</td>
                <td style="width: 80px;">{{task.priority}}</td>
                <td style="width: 250px;">{{task.taskDescription}}</td>
                <td>{{task.createdDate | date: 'dd/MM/yyyy'}}</td>
                <td>
                    <input *ngIf="task.completedDate" class="form-control datepicker" value="{{task.completedDate | date: 'dd-MM-yyyy'}}" [readonly]="true" type="text">
                </td>
                <td>{{task.fullName}}</td>
            </tr>
        </table>
    </ng-container>
</div>