<div class="task-modal">
    <div class="task-modal-header">
        <p *ngIf="!editMode && isAdmin" class="task-modal--title">
            {{ "labels.createNewTask" | translate }}
        </p>
        <p *ngIf="!editMode && !isAdmin" class="task-modal--title">
            {{ "labels.suggestNewTask" | translate }}
        </p>
        <p *ngIf="editMode" class="task-modal--title">
            {{ "labels.editTask" | translate }}
        </p>
        <button
            type="button"
            class="task-modal--close close"
            (click)="close()"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div *ngIf="taskForm" class="task-modal-body">
        <div class="form-progress">
            <img
                src="assets/images/progress-form-empty.svg"
                *ngIf="currentPage === 1"
                alt="Progress bar"
            />
            <img
                src="assets/images/progress-form.svg"
                *ngIf="currentPage === 2"
                alt="Progress bar"
            />
            <div class="form-progress-text">
                <p [ngClass]="{ 'font-bold': currentPage === 1 }">
                    {{ "menu.tasks" | translate }}
                </p>
                <p [ngClass]="{ 'font-bold': currentPage === 2 }">
                    {{ "menu.consultant" | translate }}
                </p>
            </div>
        </div>
        <form id="demo-form" [formGroup]="taskForm" class="task-modal-form">
            <div [ngClass]="{ 'hidden-form': currentPage !== 1 }">
                <div class="task-form-container">
                    <div class="task-form-group">
                        <div class="task-form-body" *ngIf="isTaskOpen">
                            <div class="" *ngIf="isAdmin">
                                <div class="">
                                    <label class="control-label">{{
                                        "labels.customer" | translate
                                    }}</label>
                                    <div>
                                        <select
                                            formControlName="customerId"
                                            class="task-select"
                                            (change)="onSelectChange($event)"
                                        >
                                            <option
                                                [ngValue]="null"
                                                data-default
                                            >
                                                {{
                                                    "labels.chooseCustomer"
                                                        | translate
                                                }}
                                            </option>
                                            <option
                                                *ngFor="
                                                    let customer of customers$
                                                        | async
                                                "
                                                [ngValue]="customer.id"
                                            >
                                                {{ customer.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="task-form-group" *ngIf="isAdmin">
                                <div>
                                    <label class="control-label">{{
                                        "labels.allowedStakeholders" | translate
                                    }}</label>
                                    <div
                                        ngbDropdown
                                        class="task-select-dropdown"
                                    >
                                        <button
                                            ngbDropdownToggle
                                            class="new-btn new-btn-light task-dropdown-toggle"
                                        >
                                            {{
                                                "labels.selectPeople"
                                                    | translate
                                            }}
                                        </button>
                                        <div
                                            ngbDropdownMenu
                                            class="task-dropdown-menu"
                                        >
                                            <ng-container
                                                *ngIf="
                                                    customerProfiles$
                                                        | async as customerProfiles
                                                "
                                            >
                                                <div
                                                    *ngIf="
                                                        customerProfiles.length;
                                                        else noPersonsAvailable
                                                    "
                                                >
                                                    <div
                                                        *ngFor="
                                                            let person of customerProfiles
                                                        "
                                                    >
                                                        <label
                                                            class="dropdown-item checkbox-item"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                (change)="
                                                                    handleSelectPerson(
                                                                        {
                                                                            person: person,
                                                                            selected:
                                                                                $event
                                                                                    .target
                                                                                    .checked
                                                                        }
                                                                    )
                                                                "
                                                                [checked]="
                                                                    selectedCustomerProfilesHas(
                                                                        person
                                                                    )
                                                                "
                                                            />
                                                            {{
                                                                person.firstname +
                                                                    " " +
                                                                    person.surname
                                                            }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #noPersonsAvailable>
                                                <div class="dropdown-item">
                                                    {{
                                                        "labels.noPersonAvailable"
                                                            | translate
                                                    }}
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <ul class="task-list">
                                    <li
                                        *ngFor="
                                            let person of selectedCustomerProfiles
                                        "
                                        class="task-list-item"
                                    >
                                        {{ getConsultantName(person) }}
                                        <span
                                            class="task-list-delete"
                                            (click)="
                                                handleSelectPerson({
                                                    person: person,
                                                    selected: false
                                                })
                                            "
                                            >&times;</span
                                        >
                                    </li>
                                </ul>
                            </div>

                            <!--Input for tasks-->
                            <div>
                                <div>
                                    <label class="control-label">{{
                                        "labels.task" | translate
                                    }}</label>
                                    <div>
                                        <input
                                            type="text"
                                            formControlName="title"
                                            name="Ref"
                                            class="task-input task-input--full"
                                            [placeholder]="
                                                'labels.taskExample' | translate
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="task-modal-container">
                                    <!--Input for task description textarea-->
                                    <div class="task-modal-description">
                                        <label class="control-label">{{
                                            "labels.taskDescription" | translate
                                        }}</label>
                                        <div class="task-modal-container">
                                            <textarea
                                                [placeholder]="
                                                    'placeholders.task'
                                                        | translate
                                                "
                                                formControlName="description"
                                                class="task-input text-area"
                                                rows="2"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!--Radio buttons new project-->
                                    <div>
                                        <div>
                                            <label class="control-label">{{
                                                "labels.newProject" | translate
                                            }}</label>
                                        </div>
                                        <div class="task-radio">
                                            <label
                                                class="radio-label"
                                                for="new"
                                            >
                                                <input
                                                    type="radio"
                                                    id="new"
                                                    name="isNewProject"
                                                    [value]="true"
                                                    formControlName="isNewProject"
                                                />
                                                <span class="label"></span>
                                                {{ "labels.yes" | translate }}
                                            </label>
                                            <label
                                                for="old"
                                                class="radio-label"
                                            >
                                                <input
                                                    type="radio"
                                                    id="old"
                                                    name="isNewProject"
                                                    [value]="false"
                                                    formControlName="isNewProject"
                                                />
                                                <span class="label"></span>
                                                {{ "labels.no" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Task location section-->
                    <div *ngIf="isAdmin">
                        <div>
                            <label class="control-label">{{
                                "labels.admin" | translate
                            }}</label>
                            <div>
                                <select
                                    formControlName="adminId"
                                    class="consultant-role"
                                >
                                    <option
                                        *ngFor="let admin of admins$ | async"
                                        [ngValue]="admin.adminId"
                                    >
                                        {{ admin.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label class="control-label">{{
                                "labels.location" | translate
                            }}</label>
                            <div>
                                <input
                                    type="text"
                                    formControlName="location"
                                    name="location"
                                    class="task-input--full task-input"
                                    [placeholder]="
                                        'placeholders.location' | translate
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <!--Task date section-->
                    <div class="column-container">
                        <label class="control-label">
                            {{ "labels.startDate" | translate }}
                            <div>
                                <div class="input-group">
                                    <input
                                        class="task-datepicker--full task-datepicker"
                                        placeholder="dd-mm-yyyy"
                                        formControlName="ngbStartDate"
                                        ngbDatepicker
                                        [readonly]="true"
                                        (click)="ds.toggle()"
                                        #ds="ngbDatepicker"
                                    />
                                </div>
                            </div>
                        </label>
                        <label class="control-label">
                            {{ "labels.endDate" | translate }}
                            <div>
                                <div class="input-group">
                                    <input
                                        class="task-datepicker task-datepicker--full"
                                        placeholder="dd-mm-yyyy"
                                        formControlName="ngbEndDate"
                                        ngbDatepicker
                                        [readonly]="true"
                                        (click)="de.toggle()"
                                        #de="ngbDatepicker"
                                    />
                                </div>
                            </div>
                        </label>
                    </div>
                    <!--DATE AND HOURS section-->
                    <div class="column-container">
                        <div class="control-label">
                            <label>
                                {{ "labels.dateFreeText" | translate }}
                            </label>
                            <input
                                type="text"
                                formControlName="dateFreeText"
                                name="Ref"
                                class="task-input-small task-input--full"
                                [placeholder]="
                                    'placeholders.dateFree' | translate
                                "
                            />
                        </div>
                        <div class="control-label">
                            <label>{{
                                "labels.hoursPerWeek" | translate
                            }}</label>
                            <div>
                                <input
                                    [placeholder]="
                                        'placeholders.amount' | translate
                                    "
                                    formControlName="hours"
                                    class="task-input-small task-input--full"
                                />
                            </div>
                        </div>
                    </div>
                    <!--Travel and team section-->
                    <div class="column-container-wide">
                        <div>
                            <label class="control-label">{{
                                "labels.travelActivity" | translate
                            }}</label>
                            <div class="task-radio">
                                <label class="radio-label" for="travel_yes">
                                    <input
                                        id="travel_yes"
                                        name="travelActivity"
                                        type="radio"
                                        [value]="true"
                                        formControlName="travelActivity"
                                    />
                                    <span class="checkmark"></span>
                                    {{ "labels.yes" | translate }}
                                </label>
                                <label for="travel_no" class="radio-label">
                                    <input
                                        id="travel_no"
                                        name="travelActivity"
                                        type="radio"
                                        [value]="false"
                                        formControlName="travelActivity"
                                    />
                                    <span class="checkmark"></span>
                                    {{ "labels.no" | translate }}
                                </label>
                            </div>
                        </div>
                        <!--TEAM SECTION -->
                        <div>
                            <div>
                                <label class="control-label">{{
                                    "labels.team" | translate
                                }}</label>
                            </div>
                            <div class="task-radio">
                                <label class="radio-label" for="team_yes">
                                    <input
                                        id="team_yes"
                                        name="isTeam"
                                        type="radio"
                                        [value]="true"
                                        formControlName="isTeam"
                                    />
                                    <span class="checkmark"></span>
                                    {{ "labels.yes" | translate }}
                                </label>
                                <label for="team_no" class="radio-label">
                                    <input
                                        id="team_no"
                                        name="isTeam"
                                        type="radio"
                                        [value]="false"
                                        formControlName="isTeam"
                                    />
                                    <span class="checkmark"></span>
                                    {{ "labels.no" | translate }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- Project owner section-->
                    <div>
                        <div>
                            <label class="control-label">{{
                                "labels.projectOwner" | translate
                            }}</label>
                            <div>
                                <input
                                    [placeholder]="
                                        'placeholders.projectOwner' | translate
                                    "
                                    formControlName="projectOwner"
                                    class="task-input task-input--full"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- OPTION section-->
                    <div>
                        <div>
                            <label class="control-label">{{
                                "labels.optionMonth" | translate
                            }}</label>
                            <div>
                                <select
                                    class="task-select"
                                    formControlName="option"
                                    (change)="onSelectChange($event)"
                                >
                                    <option [ngValue]="null" data-default>
                                        {{
                                            "labels.chooseMonthExtension"
                                                | translate
                                        }}
                                    </option>
                                    <option
                                        *ngFor="let index of months"
                                        [value]="index"
                                    >
                                        {{ index }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isAdmin">
                        <div>
                            <label class="control-label">{{
                                "labels.freeText" | translate
                            }}</label>
                            <div>
                                <textarea
                                    [placeholder]="
                                        'placeholders.notes' | translate
                                    "
                                    formControlName="freeText"
                                    class="form-control"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--NEXT PAGE FORM-->
            <div [ngClass]="{ 'hidden-form': currentPage !== 2 }">
                <div class="task-form-group">
                    <div class="task-form-header"></div>
                    <div class="task-form-body">
                        <!-- Consultant Role -->
                        <div class="task-modal-container">
                            <div class="control-label">
                                <label class="control-label">{{
                                    "labels.consultantRole" | translate
                                }}</label>
                                <select
                                    formControlName="consultantRoleId"
                                    (change)="onSelectChange($event)"
                                    [ngClass]="{
                                        'invalid-select':
                                            formSubmitted &&
                                            taskForm.controls.consultantRoleId
                                                .invalid
                                    }"
                                    class="task-select task-select--half"
                                >
                                    <option [ngValue]="null" data-default>
                                        {{ "labels.chooseRole" | translate }}
                                    </option>
                                    <option
                                        *ngFor="
                                            let role of consultantRoles$ | async
                                        "
                                        [ngValue]="role.id"
                                    >
                                        {{ role | translateContent }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="task-modal-container">
                            <div class="control-label">
                                <label class="control-label">{{
                                    "labels.numberYearsExperience" | translate
                                }}</label>
                                <select
                                    formControlName="yearsOfExperience"
                                    (change)="onSelectChange($event)"
                                    [ngClass]="{
                                        'invalid-select':
                                            formSubmitted &&
                                            taskForm.controls.yearsOfExperience
                                                .invalid
                                    }"
                                    class="task-select task-select--half"
                                >
                                    <option [ngValue]="null" data-default>
                                        {{ "labels.chooseAmount" | translate }}
                                    </option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option selected="selected" value="5">
                                        5
                                    </option>
                                    <option value="6">5+</option>
                                    <option value="11">10+</option>
                                    <option value="21">20+</option>
                                </select>
                            </div>
                        </div>

                        <div class="control-label">
                            <label for="requirements">{{
                                "labels.competenceRequirements" | translate
                            }}</label>
                            <div class="column-container">
                                <input
                                    type="text"
                                    formControlName="requiredCompetences"
                                    [placeholder]="
                                        'placeholders.competence' | translate
                                    "
                                    id="requirements"
                                    (keyup.enter)="addRequiredCompetence()"
                                    [required]="requiredCompetences.length == 0"
                                    [ngClass]="{
                                        'invalid-select':
                                            formSubmitted &&
                                            taskForm.controls
                                                .requiredCompetences.invalid
                                    }"
                                    class="task-input-small task-input--half"
                                />
                                <button
                                    class="new-btn new-btn-light"
                                    (click)="addRequiredCompetence()"
                                >
                                    + {{ "labels.add" | translate }}
                                </button>
                            </div>
                            <ul>
                                <li
                                    *ngFor="
                                        let competence of requiredCompetences
                                    "
                                >
                                    {{ competence.skill | titlecase }}
                                    <span
                                        (click)="
                                            deleteRequiredCompetence(competence)
                                        "
                                        >&times;</span
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="control-label">
                            <label for="competenceExpectation">{{
                                "labels.competenceWishes" | translate
                            }}</label>
                            <div class="column-container">
                                <input
                                    type="text"
                                    formControlName="wantedCompetences"
                                    [placeholder]="
                                        'placeholders.competence' | translate
                                    "
                                    id="competenceExpectation"
                                    (keyup.enter)="addWantedCompetence()"
                                    class="task-input-small task-input--half"
                                />
                                <button
                                    class="new-btn new-btn-light"
                                    (click)="addWantedCompetence()"
                                >
                                    + {{ "labels.add" | translate }}
                                </button>
                            </div>

                            <ul>
                                <li
                                    *ngFor="let competence of wantedCompetences"
                                >
                                    {{ competence.skill | titlecase }}
                                    <span
                                        (click)="
                                            deleteWantedCompetence(competence)
                                        "
                                        >&times;</span
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="control-label">
                            <label for="technologyExperience">{{
                                "labels.experienceWithTech" | translate
                            }}</label>
                            <div class="column-container">
                                <input
                                    type="text"
                                    formControlName="requiredTechnologies"
                                    [placeholder]="
                                        'placeholders.tech' | translate
                                    "
                                    id="competenceExperience"
                                    (keyup.enter)="addRequiredTechnology()"
                                    class="task-input-small task-input--half"
                                />
                                <button
                                    class="new-btn new-btn-light"
                                    (click)="addRequiredTechnology()"
                                >
                                    + {{ "labels.add" | translate }}
                                </button>
                            </div>
                            <ul>
                                <li *ngFor="let technology of techonologies">
                                    {{ technology.skill | titlecase }}
                                    <span
                                        (click)="
                                            deleteRequiredTechnology(technology)
                                        "
                                        >&times;</span
                                    >
                                </li>
                            </ul>
                        </div>

                        <div>
                            <label class="control-label">{{
                                "labels.languageRequirement" | translate
                            }}</label>
                            <input
                                type="text"
                                formControlName="languageRequirement"
                                id="languageRequirement"
                                class="task-input task-input--full"
                                [placeholder]="
                                    'placeholders.langauge' | translate
                                "
                            />
                        </div>

                        <!-- Deadline -->
                        <div class="task-modal-container">
                            <div class="">
                                <label class="control-label">{{
                                    "labels.deadline" | translate
                                }}</label>
                                <input
                                    class="task-datepicker task-datepicker--full"
                                    placeholder="dd-mm-yyyy"
                                    formControlName="ngbDeadline"
                                    ngbDatepicker
                                    #dl="ngbDatepicker"
                                    [readonly]="true"
                                    (click)="dl.toggle()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--FOOTER MODAL-->
            <div class="task-modal-footer">
                <!-- Show this button on the first page -->
                <button
                    *ngIf="currentPage === 1"
                    class="new-btn new-btn-dark modal-next"
                    (click)="goToNextPage()"
                >
                    {{ "labels.next" | translate }}
                </button>

                <!-- Show these buttons on the second page -->
                <button
                    *ngIf="currentPage === 2 && isAdmin"
                    class="new-btn new-btn-dark modal-confirm"
                    (click)="save()"
                >
                    {{ "labels.saveTask" | translate }}
                </button>
                <button
                    *ngIf="currentPage === 2 && !isAdmin"
                    class="new-btn new-btn-dark modal-confirm"
                    (click)="save()"
                >
                    {{ "labels.sendSuggestion" | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
