import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProfileApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}profile`;
    }
    get(profileId) {
        if (profileId) {
            const params = new HttpParams().set('profileId', profileId + '');
            return this.httpClient.get(`${this.apiBaseUrl}/getProfile`, { params });
        }
        else {
            return this.httpClient.get(`${this.apiBaseUrl}/getProfile`);
        }
    }
    getProfileStatus(profileId) {
        const params = new HttpParams().set('profileId', profileId + '');
        return this.httpClient.get(`${this.apiBaseUrl}/getProfileStatus`, { params });
    }
    getOwnProfilesContract() {
        return this.httpClient.get(`${this.apiBaseUrl}/getOwnProfilesContract`);
    }
    findProfiles(profileSearchParameters) {
        return this.httpClient.post(`${this.apiBaseUrl}/findProfiles`, profileSearchParameters);
    }
    searchProfiles(searchText) {
        return this.httpClient.get(`${this.apiBaseUrl}/searchProfiles?searchText=${searchText}`);
    }
    getAsCsv(profileSearchParameters) {
        return this.httpClient.post(`${this.apiBaseUrl}/getAsCsv`, profileSearchParameters, {
            observe: 'response',
            responseType: 'blob'
        });
    }
    getFocusProfiles(baseSearchParameters) {
        return this.httpClient.post(`${this.apiBaseUrl}/GetWithFocus`, baseSearchParameters);
    }
    // Hvilken model?
    getTopTenConsultants(baseSearchParameters) {
        return this.httpClient.post(`${this.apiBaseUrl}/getTopTenConsultants`, baseSearchParameters);
    }
    approveProfile(profileId) {
        const params = new HttpParams().set('profileId', profileId + '');
        return this.httpClient.post(`${this.apiBaseUrl}/approveProfile`, params);
    }
    sendProfileToApproval() {
        return this.httpClient.post(`${this.apiBaseUrl}/sendProfileToApproval`, undefined);
    }
    forceSendProfileToApproval(profileId) {
        return this.httpClient.post(`${this.apiBaseUrl}/forceSendProfileToApproval`, profileId);
    }
    save(profilePersonalData) {
        return this.httpClient.post(`${this.apiBaseUrl}`, profilePersonalData);
    }
    delete(profileId) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${profileId}`);
    }
    removeDeletableProfiles() {
        return this.httpClient.delete(`${this.apiBaseUrl}/removeDeletableProfiles`);
    }
    getMinMaxExperienceSince() {
        return this.httpClient.get(`${this.apiBaseUrl}/getminmaxexp`);
    }
    //TODO: Should these routes api calls be moved to another service maybe new widget-api.service.ts file?
    getThisMonthProfiles() {
        return this.httpClient.get(`${this.apiBaseUrl}/getthisMonthProfiles`);
    }
    getExpiringProfiles() {
        return this.httpClient.get(`${this.apiBaseUrl}/getExpiringProfiles`);
    }
    getConsultantsOnContract() {
        return this.httpClient.get(`${this.apiBaseUrl}/getConsultantsOnContract`);
    }
    getOwnProfiles() {
        return this.httpClient.get(`${this.apiBaseUrl}/getownprofiles`);
    }
    getPresentableProfiles(profileIds) {
        let query = '';
        for (let i = 0; i < profileIds.length; i++) {
            i == 0 ? query += '?' : query += '&';
            const element = profileIds[i];
            query += `profileId=${element}`;
        }
        return this.httpClient.get(`${this.apiBaseUrl}/getPresentableProfiles${query}`);
    }
    getSuggestedProfiles() {
        return this.httpClient.get(`${this.apiBaseUrl}/getSuggestedProfiles`);
    }
    sendEmail(sendEmail) {
        return this.httpClient.post(`${this.apiBaseUrl}/sendEmailToConsultants`, sendEmail);
    }
    postDocuments(formData) {
        return this.httpClient.post(`${this.apiBaseUrl}/postDocuments`, formData);
    }
    deleteDocument(id) {
        return this.httpClient.delete(`${this.apiBaseUrl}/deleteDocument/${id}`);
    }
    getProfileById(profileId) {
        return this.httpClient.get(`${this.apiBaseUrl}/${profileId}`);
    }
}
ProfileApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileApiService_Factory() { return new ProfileApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ProfileApiService, providedIn: "root" });
