import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { ConsultantRole } from '../models/consultant-role';
import { ConsultantStatus } from '../models/consultant-status';
import { Customer } from '../models/customer';

@Injectable({
    providedIn: 'root'
})
export class CommonDataService {
    private apiBaseUrl = `${environment.apiUrl}commondata`;
    private consultantRoles: Observable<ConsultantRole[]>;
    private consultantStatuses: Observable<ConsultantStatus[]>;
    private customers: Observable<Customer[]>;

    constructor(private httpClient: HttpClient) { }

    public getConsultantRoles(): Observable<ConsultantRole[]> {
        if (!this.consultantRoles) {
            this.consultantRoles = this.httpClient.get<ConsultantRole[]>(`${this.apiBaseUrl}/getconsultantroles`).pipe(
                map(data => data),
                publishReplay(1), // this tells Rx to cache the latest emitted
                refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );

        }
        return this.consultantRoles;
    }


    public getCustomers(): Observable<Customer[]> {
        if (!this.customers) {
            this.customers = this.httpClient.get<Customer[]>(`${this.apiBaseUrl}/getCustomers`).pipe(
                map(data => data),
                publishReplay(1), // this tells Rx to cache the latest emitted
                refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );
        }
        return this.customers;
    }

    public getConsultantStatuses(): Observable<ConsultantStatus[]> {
        if (!this.consultantStatuses) {
            this.consultantStatuses = this.httpClient.get<ConsultantStatus[]>(`${this.apiBaseUrl}/getconsultantStatusesSearch`).pipe(
                map(data => data),
                publishReplay(1), // this tells Rx to cache the latest emitted
                refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
            );

        }
        return this.consultantStatuses;
    }
}
