import { TaskTypeEnum } from "src/app/core/models/enum/task-type-enum";
import { TodoTask } from "../todo-task";

export class AccountManagerNewTaskCustomer extends TodoTask {

  taskDescription = `Der er blevet oprettet en ny opgave venligst check dette`;
  taskName = "Account Manager: Ny opgave fra kunde"
  completeTaskManually = true;

  public getRouteLink(): string {
    return `/tasks/tasks`
  }

}