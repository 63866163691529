import { UserRoleEnum } from './enum/user-role-enum';
import { ProfileStatusEnum } from './enum/Profile-status-enum';

export class User {
    public id: number;
    public name: string;
    public username: string;
    public password: string;
    public token?: string;
    public userRoleEnumDto: UserRoleEnum;
    public profileId?: number;
    public adminId?: number;
    public createUserId?: number;
    public customerProfileId: number;
    public lastLogin?: Date;
    public createdDate?: Date;
    public firstLogin: boolean;
    public imagePath: string;
    public phone: string;
    public email: string;
    public canEdit: boolean;
    public twoFactorCode: string;
    public requireTwoFactor: boolean;
    public mobile: string;
    public isSuperAdmin: boolean;
    public isCentralUser: boolean;
}
