import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginPageComponent } from "./login/pages/login-page/login-page.component";
import { AuthGuard } from "./core/helpers/auth-guard";
import { UserRoleEnum } from "./core/models/enum/user-role-enum";
import { MainComponent } from "./main/main.component";
import { MainAdminComponent } from "./main-admin/main-admin.component";
import { MainProfileComponent } from "./main-profile/main-profile.component";
import { ForgotPasswordComponent } from "./forgot-password/Pages/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { EmailArchivePageComponent } from "./email-archive/pages/email-archive-page/email-archive-page.component";
import { LinkedInAuthComponent } from "./linked-in-auth/linked-in-auth.component";
import { FacebookAuthComponent } from "./facebook-auth/facebook-auth.component";
import { LinkedInSocialAuthComponent } from "./linked-in-social-auth/linked-in-social-auth.component";
import { SurveyAttemptComponent } from "./surveys/components/survey-attempt/survey-attempt.component";
import { MainCustomerProfileComponent } from "./main-customer-profile/main-customer-profile.component";

const routes: Routes = [
    {
        path: "login",
        component: LoginPageComponent,
    },
    /* {
        path: "forgot-password",
        component: ForgotPasswordComponent,
    }, */
    {
        path: "change-password",
        component: ChangePasswordComponent,
    },
    {
        path: "linkedIn-auth",
        component: LinkedInAuthComponent,
    },
    {
        path: "surveys/shared",
        component: SurveyAttemptComponent,
    },
    {
        path: "linked-in-social-auth",
        component: LinkedInSocialAuthComponent,
    },
    {
        path: "surveys/attempt/:id",
        component: SurveyAttemptComponent,
        canActivate: [AuthGuard],
        data: { userRoleEnum: [UserRoleEnum.Profile] },
    },

    // {
    //     path:'activities',
    //     loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule),
    //     canActivate: [AuthGuard],
    //     data: {UserRoleEnum: [UserRoleEnum.SuperAdmin]}
    // },
    {
        path: "",
        component: MainAdminComponent,
        canActivate: [AuthGuard],
        data: { userRoleEnum: [UserRoleEnum.Admin] },
        children: [
            {
                path: "",
                redirectTo: "search",
                pathMatch: "full",
            },
            {
                path: "search",
                loadChildren: () =>
                    import("./search/search.module").then(
                        (m) => m.SearchModule
                    ),
            },
            {
                path: "facebook-auth",
                component: FacebookAuthComponent,
            },
            {
                path: "consent",
                loadChildren: () =>
                    import("./consent/consent.module").then(
                        (m) => m.ConsentModule
                    ),
            },
            {
                path: "cookies",
                loadChildren: () =>
                    import("./cookies/cookies.module").then(
                        (m) => m.CookiesModule
                    ),
            },
            {
                path: "tasks",
                loadChildren: () =>
                    import("./tasks/tasks.module").then((m) => m.TasksModule),
            },
            {
                path: "dashboard",
                loadChildren: () =>
                    import("./dashboard/dashboard.module").then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: "administrator",
                loadChildren: () =>
                    import("./administrators/administrators.module").then(
                        (m) => m.AdministratorsModule
                    ),
            },
            {
                path: "competence-category",
                loadChildren: () =>
                    import(
                        "./competence-category/competence-category.module"
                    ).then((m) => m.CompetenceCategoryModule),
            },
            {
                path: "work-locations",
                loadChildren: () =>
                    import("./work-locations/work-locations.module").then(
                        (m) => m.WorkLocationsModule
                    ),
            },
            {
                path: "surveys",
                loadChildren: () =>
                    import("./surveys/surveys.module").then(
                        (m) => m.SurveysModule
                    ),
            },
            {
                path: "email-templates",
                loadChildren: () =>
                    import("./email-templates/email-templates.module").then(
                        (m) => m.EmailTemplatesModule
                    ),
            },
            {
                path: "profile-details",
                loadChildren: () =>
                    import("./profile-details/profile-details.module").then(
                        (m) => m.ProfileDetailsModule
                    ),
            },
            {
                path: "email-archive",
                component: EmailArchivePageComponent,
            },
            {
                path: "print-admin-template",
                loadChildren: () =>
                    import("./print-template/print-template.module").then(
                        (m) => m.PrintTemplateModule
                    ),
            },
            {
                path: "customers",
                loadChildren: () =>
                    import("./admin-customer/admin-customer.module").then(
                        (m) => m.AdminCustomerModule
                    ),
            },
            {
                path: "activities",
                loadChildren: () =>
                    import("./activities/activities.module").then(
                        (m) => m.ActivitiesModule
                    ),
            },
        ],
    },
    {
        path: "",
        component: MainProfileComponent,
        canActivate: [AuthGuard],
        data: { userRoleEnum: [UserRoleEnum.Profile] },
        children: [
            {
                path: "",
                redirectTo: "edit-my-profile-page",
                pathMatch: "full",
            },
            {
                path: "edit-my-profile-page",
                loadChildren: () =>
                    import("./edit-my-profile/edit-my-profile.module").then(
                        (m) => m.EditMyProfileModule
                    ),
            },
            {
                path: "manuals",
                loadChildren: () =>
                    import("./manuals/manuals.module").then(
                        (m) => m.ManualsModule
                    ),
            },
            {
                path: "print-template",
                loadChildren: () =>
                    import("./print-template/print-template.module").then(
                        (m) => m.PrintTemplateModule
                    ),
            },
            {
                path: "survey/attempt/:id",
                component: SurveyAttemptComponent,
            },
        ],
    },
    {
        path: "",
        component: MainCustomerProfileComponent,
        canActivate: [AuthGuard],
        data: { userRoleEnum: [UserRoleEnum.CustomerProfile] },
        children: [
            {
                path: "",
                redirectTo: "customer",
                pathMatch: "full",
            },
            {
                path: "customer",
                loadChildren: () =>
                    import("./customer/customer.module").then(
                        (m) => m.CustomerModule
                    ),
            },
        ],
    },
    {
        path: "",
        component: MainComponent,
        children: [
            {
                path: "",
                redirectTo: "create-profile",
                pathMatch: "full",
            },
            {
                path: "create-profile",
                loadChildren: () =>
                    import("./create-profile/create-profile.module").then(
                        (m) => m.CreateProfileModule
                    ),
            },
        ],
    },
    {
        path: "",
        component: MainComponent,
        children: [
            /* {
                path: "",
                redirectTo: "forgot-password",
                pathMatch: "full",
            },
            {
                path: "forgot-password",
                loadChildren: () =>
                    import("./forgot-password/forgot-password.module").then(
                        (m) => m.ForgotPasswordModule
                    ),
            }, */
            {
                path: 'forgot-password',
                loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
            }
        ],
    },
    // otherwise redirect to home
    { path: "**", redirectTo: "" },
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes), // { enableTracing: true }
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
