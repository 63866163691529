import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {User} from '../../../core/models/user';
import { UserApiService } from 'src/app/core/api/user-api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {

    @Output() public closeMenusFunc = new EventEmitter<MouseEvent>(); // Method defined in the parent component. Called from closeMenus() below.
    @Output() public logoutFunc = new EventEmitter<void>(); // Method defined in the parent component. Called from logout() below.
    @Input() public currentUser : User;
    @Input() public isAdmin : boolean;
    @Input() public roleText = '';

    public adminLinks: Links[] = [
        { menuItemName: "Søgning", route: "/search" },
        { menuItemName: "Opgaver", route: "/tasks" },
        { menuItemName: "Administrator", route: "/administrator" },
        { menuItemName: "Kunder", route: "/customers" },
        { menuItemName: "ToDo's", route: "/activities" },
        { menuItemName: "Performance", route: "/activities/performance" },
        { menuItemName: "Indstillinger", route: "menuheader",addClass:true },
        { menuItemName: "Samtykke", route: "/consent" },
        { menuItemName: "Kompetencegrupper", route: "/competence-category" },
        { menuItemName: "Arbejdslokationer", route: "/work-locations" },
        { menuItemName: "Surveys", route: "/surveys" },
        { menuItemName: "Emailskabeloner", route: "/email-templates" },
        { menuItemName: "Email arkiv", route: "/email-archive" },
    ];
    public profileLinks:Links[] = [
        { menuItemName: "Mit CV", route: "/edit-my-profile-page" },
        { menuItemName: "Vejledning", route: "/manuals" },
    ];
    public customerProfileLinks: Links[] = [
        { menuItemName: "Min side", route: "/customer", icon: "assets/images/User.svg"},
        { menuItemName: "Opgaver", route: "/customer/tasks", icon: "assets/images/checkSquare.svg"}
    ]

    constructor(private translateService: TranslateService, private userApiService: UserApiService, private toastr: ToastrService, private router: Router) {
        this.translateService.stream("menu").subscribe(result => {
            this.adminLinks = [
                { menuItemName: result.search, route: "/search" },
                { menuItemName: result.tasks, route: "/tasks" },
                { menuItemName: result.administrator, route: "/administrator" },
                { menuItemName: result.customers, route: "/customers" },
                { menuItemName: result.todos, route: "/activities" },
                { menuItemName: result.performance, route: "/activities/performance" },
                { menuItemName: result.settings, route: "menuheader",addClass:true },
                { menuItemName: result.consent, route: "/consent" },
                { menuItemName: result.competenceGroups, route: "/competence-category" },
                { menuItemName: result.workLocations, route: "/work-locations" },
                { menuItemName: result.surveys, route: "/surveys" },
                { menuItemName: result.emailTemplates, route: "/email-templates" },
                { menuItemName: result.emailArchive, route: "/email-archive" },
            ];
            this.profileLinks = [
                { menuItemName: result.myCv, route: "/edit-my-profile-page"},
                { menuItemName: result.help, route: "/manuals" },
            ];
            this.customerProfileLinks = [
                { menuItemName: result.myPage, route: "/customer", icon: "assets/images/User-icon.svg" },
                { menuItemName: result.tasks, route: "/customer/tasks", icon: "assets/images/CheckSquare.svg" }
            ]

        })
    }

    ngOnInit() {

    }

        requestChangePassword() {
            this.userApiService.requestChangePassword().subscribe({
                next: (response) => {
                    this.router.navigate(['/change-password'], { queryParams: { token: response.token } });
                },
                error: (error) => {
                    this.toastr.error("Der er sket en fejl!");
                    console.error("Kunne ikke få sikkerhedstoken:", error);
                }
            });
        }

    public closeMenus(e:MouseEvent) {
        this.closeMenusFunc.emit(e);
    }

    public logout() {
        this.logoutFunc.emit();
    }

}

class Links {
    menuItemName: string;
    route: string;
    addClass?:boolean;
    icon?:string;
}
