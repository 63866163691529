<h2> {{ "labels.addNewSurvey" | translate }} </h2>
<hr>
<section class="panel">
	<div class="row">
		<div class="col-4">
			<form (ngSubmit)="onSubmit(surveyForm.value)" #surveyForm="ngForm">
				<div class="form-group">
					<h3> {{ "labels.surveyTitle" | translate }} </h3>
					<p> {{ "placeholders.evaluationTitle" | translate }} </p>
					<input class="input-field" required type="text" [ngModel]="title" name="title">
				</div>
                <br>
                <div class="form-group">
                    <h3> {{ "labels.externalTitle" | translate }} </h3>
                    <p> {{ "placeholders.externalTitle" | translate }} </p>
                    <input class="input-field" type="text" [ngModel]="externalTitle" name="externalTitle">
                </div>
				<br>
				<div class="form-group">
					<h3> {{ "labels.description" | translate }} </h3>
					<p> {{ "placeholders.startAndTermination" | translate }} </p>
					<textarea class="description" required [(ngModel)]="description" name="description"
						form="surveyForm"></textarea>
				</div>
				<div class="form-group">
					<h3> {{ "labels.answerLimit" | translate }} </h3>
					<p> {{ "placeholders.descriptionAnswerLimit" | translate }} </p>
					<input type="text" class="col-5 input-field" [placeholder]=" 'placeholders.noLimit' | translate"
						[(ngModel)]="answerLimit" name="answerLimit" form="surveyForm" (keypress)="keyPressNumbers($event)">
				</div>
				<br>
				<div class="form-group">
					<h3> {{ "labels.enableSurvey" | translate }} </h3>
					<p> {{ "placeholders.descriptionEnableSurvey" | translate }}</p>
					<div class="radio-toolbar">
						<!-- <button class="btn-active mr-5">Aktiver</button> -->
						<input required type="radio" id="activated" value="1" form="surveyForm" [(ngModel)]="isOpen"
							[ngModelOptions]="{standalone: true}">
						<label class="label-activated" for="activated"> {{ "labels.activate" | translate }} </label>
						<!-- <button class="btn-later">Jeg gør det senere</button> -->
						<input class="ml-4" required type="radio" id="notActivated" value="0" form="surveyForm" [(ngModel)]="isOpen"
							[ngModelOptions]="{standalone: true}">
						<label class="label-later" for="notActivated"> {{ "labels.later" | translate }} </label>
					</div>
				</div>
				<br>
				<div class="form-group">
					<h3> {{ "labels.addQuestions" | translate }} </h3>
					<p> {{ "placeholders.questionRangeOrText" | translate }} </p>
				<div>
						<div class="form-group">
							<input class="input-field" type="text" [(ngModel)]="questionValue" [ngModelOptions]="{standalone: true}">
						</div>
						<div>
							<button type="button" class="btn btn-primary mt-3" (click)="addQuestion()">
								{{ "labels.add" | translate }}
							</button>
						</div>
					</div>
				</div>
				<br>
				<br>
				<div class="form-group">
					<h3> {{ "labels.finishAddNewSurvey" | translate }} </h3>
					<p>
						{{editMode?'Tryk på "gem ændringer" for lancere denne survey med de seneste ændringer. Du kan altid komme tilbage og redigere igen.':'Tryk på "tilføj" for lancere denne survey. Du kan altid redigere og slå den fra igen.'}}
					</p>
					<button [hidden]="!editMode" [disabled]="!surveyForm.form.valid" class="btn-add mr-5">{{
						"buttons.saveChangeBtn" | translate }}</button>
					<button type="button" (click)="goBack()" class="btn-cancel btn-danger">{{ "buttons.undo" | translate
						}}</button>
				</div>
			</form>
		</div>
		<div class="col-8">
			<div class="question-box" #surveyContainer (dragover)="onDragOver($event)">
				<div class="survey-container draggable" *ngFor="let item of surveyQuestions; let i = index;" #draggable
					draggable="true" (dragstart)="onDragStart($event,i)" (dragend)="onDragEnd($event,i)"
					[attr.data-surveyId]="item.id">

					<div class="survey-card">
						<span class="row">
							<p>{{i + 1}}. {{ "labels.questions" | translate }}</p>
						</span>
						<span class="row">
							<h3 *ngIf="item != editQuestionRef; else editQuestionText" (dblclick)="editQuestion(item)" class="pl-2">
								{{item.question}}
							</h3>
						</span>
						<ng-template #editQuestionText>
							<input class="edit-question-input" type="text" [(ngModel)]="editQuestionNewText"
								(mousemove)="$event.stopPropagation()" (submit)="applyQuestionTextChange()">
							<a class="remove-icon ml-4 text-white " (click)="applyQuestionTextChange()">
								<i class="fa fa-floppy-o"></i>
							</a>
							<a class="remove-icon ml-4 text-white " (click)="editQuestionRef = null">
								<i class="fa fa-close"></i>
							</a>
						</ng-template>
						<div class="row">
							<div class="col-10 survey-btn-container p-2">
								<button class="range-btn" [ngClass]="{'test':item.answerType == 0}"
									(click)="onAnswerTypeChanged($event, item)" value="0">
									{{ "labels.range1-5" | translate }}
								</button>
								<button class="txt-btn ml-2" [ngClass]="{'test':item.answerType == 1}"
									(click)="onAnswerTypeChanged($event, item)" value="1">
									{{ "labels.free Text" | translate }}
								</button>
								<button class="txt-btn ml-2" [ngClass]="{'test':item.answerType == 2}"
									(click)="onAnswerTypeChanged($event, item)" value="2">
									{{ "labels.yesOrNo" | translate }}
								</button>
								<button class="txt-btn ml-2" [ngClass]="{'test':item.answerType == 3}"
									(click)="onAnswerTypeChanged($event, item)" value="3">
									{{ "labels.multiChoice" | translate }}
								</button>
							</div>
							<div class="col-2 d-flex justify-content-center align-items-center pl-0">
								<a *ngIf="item.answerType == 3" class="remove-icon ml-2 text-white" (click)="addChoice(item)">
									<i class="fa fa-plus"></i>
								</a>
								<a class="remove-icon ml-2 text-white " (click)="editQuestion(item)">
									<i class="fa fa-pencil"></i>
								</a>
								<a class="remove-icon ml-2 text-white " (click)="removeQuestion(item)">
									<i class="fa fa-trash-o"></i>
								</a>
							</div>


							<!-- Slider to add comment. Should be hidden if freetext -->
							<div *ngIf="item.answerType != 1">
								<p>{{ "labels.needComment" | translate }}</p>
								<input type="checkbox" id="checkbox" (change)="toggleComment(item)" [checked]="item.comment">
							</div>



						</div>
						<br>

						<div *ngIf="item.answerType == 3" class="row">
							<div *ngFor="let choice of item.surveyAnswerChoices; let i = index" class="col-12 pl-0">
								<span class="ml-2" class="choice-box" style="display: flex;">
									<p style="width: 15px" class="mr-2">{{i + 1}}. </p>
									<input [(ngModel)]="choice.text" class="choice-input input-field" (change)="updateChoice(choice)">
									<div class="ml-3">
										<a class="remove-icon text-white">
											<i class="fa fa-trash" (click)="deleteChoice(item, choice)"></i>
										</a>
									</div>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>