import { Component, OnInit } from '@angular/core';
import { EmailLogApiService } from '../../../core/api/email-log-api.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { EmailLog } from 'src/app/core/models/email-log';
import { DateFormatEnum } from '../../../core/helpers/date-format-enum';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ShowEmailComponent } from '../../components/show-email/show-email.component';
import { ModalService } from '../../../core/modal/modal.service';

@Component({
    selector: 'app-email-archive-page',
    templateUrl: './email-archive-page.component.html',
    styleUrls: ['./email-archive-page.component.scss']
})
export class EmailArchivePageComponent implements OnInit {

    public emailLogs: EmailLog[];
    public dateFormatEnum = DateFormatEnum;

    public page = 0;
    public readonly ITEMS_PER_PAGE = 50;

    constructor(private emailLogApiService: EmailLogApiService,
        private spinner: SpinnerService,
        private modalService: ModalService) { }

    ngOnInit() {
        this.loadData();
    }

    public showEmail(emailLog: EmailLog) {
        const options: NgbModalOptions = {
            centered: true,
            backdrop: 'static',
            size: 'lg',
        };

        const modalRef = this.modalService.open(ShowEmailComponent, options);
        modalRef.componentInstance.emailLog = emailLog;
    }

    private loadData() {
        this.spinner.show();
        this.emailLogApiService.GetEmails(this.page, this.ITEMS_PER_PAGE).subscribe(result => {
            this.emailLogs = result.emailLogs;
            this.spinner.hide();
        });
    }

    public onPrevPageClick() {
        if (this.page <= 0) return;
        this.page--;
        this.loadData();
    }

    public onNextPageClick() {
        this.page++;
        this.loadData();
    }

}
