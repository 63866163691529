import { TodoTask } from "../todo-task";

export class ApproveCvsSentTodoTask extends TodoTask {

  taskDescription = "Nye konsulenter er blevet valgt og sendt til godkendelse. Venligst klik her";
  taskName = "Account Manager: Ny valgte konsulenter til godkendelse";
  completeTaskManually = true;

  constructor(id: number, fullname: string, relatedContext: number) {
    super(id, fullname);
    this.relatedContext = relatedContext
  }

  public getRouteLink(): string {
    return `/tasks/${this.relatedContext}/selected`;
  }

}