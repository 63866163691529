import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ActivityApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}Activities`;
    }
    getActivityTypes() {
        return this.httpClient.get(`${this.apiBaseUrl}/getTypes`);
    }
    postActivity(todoTask) {
        return this.httpClient.post(`${this.apiBaseUrl}`, todoTask);
    }
    getActivities() {
        return this.httpClient.get(`${this.apiBaseUrl}`);
    }
    patch(todoTask) {
        return this.httpClient.patch(this.apiBaseUrl, todoTask);
    }
    getTodoTypes() {
        return this.httpClient.get(`${this.apiBaseUrl}/getTodoTypes`);
    }
}
ActivityApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityApiService_Factory() { return new ActivityApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ActivityApiService, providedIn: "root" });
