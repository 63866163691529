/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./linked-in-social-auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./linked-in-social-auth.component";
import * as i3 from "@angular/router";
import * as i4 from "../core/api/linked-in-api.service";
import * as i5 from "../core/services/linked-in.service";
import * as i6 from "../core/services/spinner.service";
var styles_LinkedInSocialAuthComponent = [i0.styles];
var RenderType_LinkedInSocialAuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkedInSocialAuthComponent, data: {} });
export { RenderType_LinkedInSocialAuthComponent as RenderType_LinkedInSocialAuthComponent };
export function View_LinkedInSocialAuthComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_LinkedInSocialAuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-linked-in-social-auth", [], null, null, null, View_LinkedInSocialAuthComponent_0, RenderType_LinkedInSocialAuthComponent)), i1.ɵdid(1, 114688, null, 0, i2.LinkedInSocialAuthComponent, [i3.ActivatedRoute, i4.LinkedInApiService, i5.LinkedInService, i3.Router, i6.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkedInSocialAuthComponentNgFactory = i1.ɵccf("app-linked-in-social-auth", i2.LinkedInSocialAuthComponent, View_LinkedInSocialAuthComponent_Host_0, {}, {}, []);
export { LinkedInSocialAuthComponentNgFactory as LinkedInSocialAuthComponentNgFactory };
