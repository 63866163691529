import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';

export class WelcomeTask extends TodoTask{

    taskDescription = `${this.fullName} er blevet oprettet i systemet venligst kald brugeren. Klik her for at komme til brugerens kontakt side`
    type = TaskTypeEnum.Welcome;
    taskName = "Velkomst hilsen til konsulenten"
    completeTaskManually = true;

    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }

}