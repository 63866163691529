import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
import { TodoTask } from '../todo-task';
export class AcceptCvChangesTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} har sendt sit cv til godkendelse. \nVenligst check dette cv`;
        this.type = TaskTypeEnum.CVCreatedNotSend;
        this.taskName = "Gammel cv sendt til godkendelse";
        this.completeTaskManually = false;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}
