/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../main-menu/components/main-menu/main-menu.component.ngfactory";
import * as i5 from "../main-menu/components/main-menu/main-menu.component";
import * as i6 from "../core/services/authentiation.service";
import * as i7 from "./main-profile.component";
import * as i8 from "../core/services/lang.service";
import * as i9 from "../core/api/user-api.service";
var styles_MainProfileComponent = [i0.styles];
var RenderType_MainProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainProfileComponent, data: {} });
export { RenderType_MainProfileComponent as RenderType_MainProfileComponent };
export function View_MainProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "logo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "logo"], ["routerLink", "/"], ["style", "margin-top:5px;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "PITTEN"], ["height", "45"], ["src", "assets/images/pitten_plain_red.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "inner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-main-menu", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).closeMenusOnOutsideClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MainMenuComponent_0, i4.RenderType_MainMenuComponent)), i1.ɵdid(8, 114688, null, 0, i5.MainMenuComponent, [i2.Router, i6.AuthenticationService, i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "section", [["class", "content-body"], ["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 4, 0, currVal_2); _ck(_v, 8, 0); _ck(_v, 11, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_MainProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-profile", [], null, null, null, View_MainProfileComponent_0, RenderType_MainProfileComponent)), i1.ɵdid(1, 114688, null, 0, i7.MainProfileComponent, [i8.LangService, i9.UserApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainProfileComponentNgFactory = i1.ɵccf("app-main-profile", i7.MainProfileComponent, View_MainProfileComponent_Host_0, {}, {}, []);
export { MainProfileComponentNgFactory as MainProfileComponentNgFactory };
