import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Admin } from '../models/admin';
import { Observable, throwError } from 'rxjs';
import { AdminSmall } from '../models/admin-small';
import { AdminSearchParameters } from '../models/admin-search-parameters';
import { AdminSearchResultWrapper } from '../models/admin-search-result-wrapper';
import { catchError } from 'rxjs/operators';
import { AdminConsultantsWrapper } from '../models/AdminConsultantsWrapper';
import { TransferInfo } from '../models/transfer-info';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  private apiBaseUrl = `${environment.apiUrl}admin`;

  constructor(private httpClient: HttpClient) { }

  public find(adminSearchParameters: AdminSearchParameters): Observable<AdminSearchResultWrapper> {
    return this.httpClient.post<AdminSearchResultWrapper>(`${this.apiBaseUrl}/find`, adminSearchParameters)
      .pipe(
        catchError(this.handleError)
      );
  }

  public search(searchWord: string) {
    return this.httpClient.get<Admin[]>(`${this.apiBaseUrl}/search?searchWord=${searchWord}`);
  }

  public findByInitials(initials: string) {
    return this.httpClient.get<Admin>(`${this.apiBaseUrl}?initials=${initials}`);
  }

  public getAllAdmins(isAccountManager: boolean = null): Observable<Admin[]> {
    if (isAccountManager == null) {
      return this.httpClient.get<Admin[]>(`${this.apiBaseUrl}/getAll`);
    }
    return this.httpClient.get<Admin[]>(`${this.apiBaseUrl}/getAll?isAccountManager=${isAccountManager}`);
  }

  /**Gets all admin related consultants */
  public getConsultants(adminId: number, page) {
    return this.httpClient.get<AdminConsultantsWrapper>(`${this.apiBaseUrl}/getConsultants?adminId=${adminId}&page=${page}`);
  }

  public getToRecruitedBy(): Observable<AdminSmall[]> {
    return this.httpClient.get<AdminSmall[]>(`${this.apiBaseUrl}/getToRecruitedBy`);
  }

  public save(admin: Admin): Observable<void> {
    return this.httpClient.post<void>(`${this.apiBaseUrl}`, admin);
  }

  public delete(adminId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiBaseUrl}/${adminId}`);
  }

  public transfer(info: TransferInfo) {
    return this.httpClient.patch(`${this.apiBaseUrl}/transfer`, info);
  }

  public makeSuperAdmin(admin: Admin) {
    return this.httpClient.patch(`${this.apiBaseUrl}/makeSuperAdmin`, admin);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
