import { TaskTypeEnum } from "../../../../core/models/enum/task-type-enum";
import { DatePipe } from '@angular/common';
import { TodoTaskStatusEnum } from 'src/app/core/models/enum/todo-task-status-enum';
import { Activity } from "src/app/core/models/activity";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export abstract class TodoTask {
    //Defined in sub classes
    readonly taskTypeId: number;
    readonly completeTaskManually: boolean;
    public taskName: string;
    public taskDescription: string;

    readonly id: number;
    readonly fullName: string;
    public relatedContext: number;
    public createdDate: Date;
    public priority: number;
    public profileId: number;
    public taskStatus: TodoTaskStatusEnum;
    public taskTitle: string;
    public activity: Activity;
    public deadline: Date;
    public completedDate: Date;
    public selected: boolean;
    public ngbDeadline: NgbDate;
    public showNote = false;

    constructor(id: number, fullname: string) {
        this.id = id;
        this.fullName = fullname;
    }

    public abstract getRouteLink(): string;
}