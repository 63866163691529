import { UserRoleEnum } from '../models/enum/user-role-enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/authentiation.service";
export class AuthGuard {
    constructor(router, authenticationService) {
        this.router = router;
        this.authenticationService = authenticationService;
    }
    canActivate(route, state) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role
            if (route.data.userRoleEnum &&
                route.data.userRoleEnum.indexOf(currentUser.userRoleEnumDto) === -1) {
                // role not authorised so redirect to home page
                let navigatePager = '/edit-my-profile-page';
                if (currentUser.userRoleEnumDto === UserRoleEnum.Admin) {
                    navigatePager = '/';
                }
                else if (currentUser.userRoleEnumDto === UserRoleEnum.CustomerProfile) {
                    navigatePager = 'customer';
                }
                this.router.navigate([navigatePager]);
                return false;
            }
            // authorised so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService)); }, token: AuthGuard, providedIn: "root" });
