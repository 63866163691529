import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
import { TodoTask } from '../todo-task';

export class CvCreatedNotSendTask extends TodoTask{
    taskDescription = `${this.fullName} har ikke sendt sit CV til godkendelse \nvenligst informere konsulenten `;
    type = TaskTypeEnum.CVCreatedNotSend;
    taskName = "Cv oprettet men ikke sendt";
    completeTaskManually = false;

    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/personal-data`
    }
    public taskClickEvent(): string {
        throw new Error("Method not implemented.");
    }

}