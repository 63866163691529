/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./spinner.component";
import * as i4 from "../../../core/services/spinner.service";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "showSpinnerContainer": 0, "hideSpinnerContainer": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "spinner"], ["src", "assets/images/spinner.gif"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.spinner.showSpinner, !_co.spinner.showSpinner); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [i4.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpinnerComponentNgFactory = i1.ɵccf("app-spinner", i3.SpinnerComponent, View_SpinnerComponent_Host_0, {}, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
