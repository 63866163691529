import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';

export class OldCvTask extends TodoTask{
    taskDescription = `${this.fullName}'s cv er ikke blevet opdateret i mere end et år. ` +
        'Venligst informerer konsulenten angående dette'
    type = TaskTypeEnum.OldCv;
    taskName = "Opdater CV";
    completeTaskManually = false;

    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/personal-data`
    }

}