 <ng-container *ngIf="showAsBackground; else regular">
  <div
    class="searchimage"
    title="{{alt}}"
    [ngStyle]="{'background-image': 'url(' + (getUrl() || missingImageSrc) + ')'}"
  ></div>
</ng-container>

<ng-template #regular>
    <img
    src="{{getUrl() || missingImageSrc}}"
    class="profile-image img-responsive"
    [ngClass]="{'rounded-circle': roundedImg, 'fit-image': isCard}"
    alt="{{alt}}">
</ng-template>
