import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

    @Input() public title: string;
    @Input() public message: string;
    @Input() public confirmButton: string;
    @Input() public declineButton: string;

    constructor(
        private activeModal: NgbActiveModal,
    ) {}

    public ngOnInit() {
    }

    public decline() {
        this.activeModal.close(false);
    }

    public accept() {
        this.activeModal.close(true);
    }
}
