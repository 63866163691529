import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(private translateService: TranslateService) { }

    //Add accept language to all requests
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var currentLang = this.translateService.currentLang;
        if (currentLang) {
            request = request.clone({
                headers: request.headers.append('Content-Language', this.translateService.currentLang)
            });
        }
        return next.handle(request);
    }
}
