import { Component, OnInit, Input } from '@angular/core';
import { EmailLog } from 'src/app/core/models/email-log';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-show-email',
    templateUrl: './show-email.component.html',
    styleUrls: ['./show-email.component.scss']
})
export class ShowEmailComponent implements OnInit {
    @Input() public emailLog: EmailLog;

    constructor(private activeModal: NgbActiveModal) { }

    public ngOnInit() {
    }

    public close() {
        this.activeModal.close();
    }

}
