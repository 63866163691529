import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyQuestion } from 'src/app/core/models/survey-question';

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionComponent implements OnInit {
  @Input() public question: SurveyQuestion
  @Output() public answerChanged = new EventEmitter<string>();

  public answer: string = "";

  constructor() { }

  ngOnInit() {
    console.log(this.question)
  }

  emitValue(value: string) {
    this.answerChanged.emit(value)
  }

}