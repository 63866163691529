<section class="body">
  <header class="header">
    <div class="logo-container">
      <a routerLink="/" class="logo" style="margin-top:5px;">
        <img src="assets/images/pitten_plain_red.svg" height="45" alt="PITTEN" />
      </a>
    </div>
    <!-- <div class="language-picker-area">
      <div class="picker-label">
        <select class="language-picker" (change)="useLang($event.target.value)" [(ngModel)]="currentLang">
          <option value="da"> Dansk </option>
          <option value="en"> English </option>
        </select>
      </div>
    </div> -->
  </header>
  <div class="inner-wrapper">
    <app-main-menu></app-main-menu>
    <section role="main" class="content-body">
      <router-outlet></router-outlet>
    </section>
  </div>
</section>
