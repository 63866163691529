<section class="body">
    <header class="header d-flex justify-content-between">
        <div class="logo-container">
            <a routerLink="/" class="logo" style="margin-top: 5px">
                <img src="assets/images/Pitten_plain_new.svg" alt="PITTEN" />
            </a>
        </div>

        <!-- This language picker is temporarily also placed in the login-page.html, create-profile.html and forgot-pasword.html files-->
        <div class="customer-language-picker-area">
            <div class="picker-label">
                <label class="switch">
                    <input
                        type="checkbox"
                        (change)="useLang($event.target.checked ? 'en' : 'da')"
                        [checked]="currentLang === 'en'"
                    />
                    <span class="slider round">
                        <span class="lang lang-da">Dansk</span>
                        <span class="slider-color"></span>
                        <span class="lang lang-en">English</span>
                    </span>
                </label>
            </div>
        </div>
        <div class="todo-wrapper">
            <div class="todo-list">
                <button
                    *ngIf="!isTodoShown"
                    class="btn btn-primary btn-outline-primary todo-button"
                    (click)="toggleTodoList(true)"
                >
                    ToDo
                </button>
                <button
                    *ngIf="isTodoShown"
                    class="btn btn-primary btn-outline-primary todo-button"
                    (click)="toggleTodoList(false)"
                >
                    <i class="fa fa-times"></i>
                </button>
                <app-todo-list-full
                    *ngIf="isTodoShown"
                    class="todo-list-full-shown"
                ></app-todo-list-full>
            </div>
            <div
                [class.fixed-bg]="isTodoShown"
                (click)="toggleTodoList(false)"
            ></div>
        </div>
        <!-- <button (click)="openChangePasswordModal()"></button> -->
    </header>
    <div class="inner-wrapper">
        <ng-template #changePasswordModal let-modal>
            <div class=""></div>
            <div class="modal-body">
                <section class="change-password__container">
                    <ng-container
                        *ngIf="!showConfirmation; else confirmationTemplate"
                    >
                        <div class="change-password__text">
                            <p class="change-password__title">
                                {{ "customerTasksPage.firstLoginPasswordTitle" | translate }}
                            </p>
                            <p class="change-password__subtitle">
                                {{
                                    "customerTasksPage.firstLoginPasswordSubtitle"
                                        | translate
                                }}
                            </p>
                        </div>
                        <form
                            [formGroup]="changePasswordForm"
                            (ngSubmit)="onSubmit()"
                            class="change-password__form"
                        >
                            <input
                                type="password"
                                class="change-password__input"
                                [placeholder]="
                                    'changePassword.passwordPlaceholder'
                                        | translate
                                "
                                formControlName="password"
                            />
                            <input
                                type="password"
                                class="change-password__input"
                                [placeholder]="
                                    'changePassword.confirmPasswordPlaceholder'
                                        | translate
                                "
                                formControlName="passwordConfirm"
                            />
                            <button type="submit" class="change-password__submit-btn new-btn new-btn-dark">
                                {{ "changePassword.changePasswordButton" | translate }}
                            </button>
                        </form>
                    </ng-container>

                    <ng-template #confirmationTemplate>
                        <div class="change-password__confirmation">
                            <p class="change-password__title">
                                {{
                                    "changePassword.passwordChangedTitle"
                                        | translate
                                }}
                            </p>
                            <p class="change-password__subtitle">
                                {{
                                    "changePassword.passwordChangedSubtitle"
                                        | translate
                                }}
                                <a routerLink="/">{{
                                    "changePassword.here" | translate
                                }}</a
                                >.
                            </p>
                        </div>
                    </ng-template>
                </section>
            </div>
        </ng-template>

        <app-main-menu></app-main-menu>
        <section role="main" class="content-body">
            <router-outlet></router-outlet>
        </section>
    </div>
</section>
