import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
export class CvSentTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} har sendt sit CV til godkendelse, review CV'et og enten godkend eller afvis`;
        this.type = TaskTypeEnum.CVSentForApproval;
        this.taskName = "Cv er blevet sendt ";
        this.completeTaskManually = false;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}
