import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkedInApiService } from '../core/api/linked-in-api.service';
import { AuthenticationService } from '../core/services/authentiation.service';
import { SpinnerService } from '../core/services/spinner.service';
import { LinkedInUser } from '../core/models/LinkedIn-user';
import { removeSummaryDuplicates } from '@angular/compiler';
import { link } from 'fs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserTmp } from '../core/models/user-tmp';
import { User } from '../core/models/user';

@Component({
  selector: 'app-linked-in-auth',
  templateUrl: './linked-in-auth.component.html',
  styleUrls: ['./linked-in-auth.component.scss']
})
export class LinkedInAuthComponent implements OnInit {

  private code: string;
  private state: string;
  private initialMail: string;
  public newUserRequired: boolean = false;

  public profileForm: FormGroup;
  public passwordControl: FormControl = new FormControl();

  constructor(
    private activatedRoute: ActivatedRoute,
    private linkedInApiService: LinkedInApiService,
    private authService: AuthenticationService,
    private router: Router,
    private spinner: SpinnerService,
    private formbuilder: FormBuilder,
    private toastrService: ToastrService) { }

  ngOnInit() {
    this.passwordControl.validator = Validators.required;

    var redirectUrl = window.location.origin + window.location.pathname;
    this.code = this.activatedRoute.snapshot.queryParams.code;
    this.state = this.activatedRoute.snapshot.queryParams.state;
    if (this.code == null || this.state == null) {
      this.router.navigate(['/']);
    }
    this.spinner.show();
    this.linkedInApiService.authenticate(this.code, this.state, redirectUrl).subscribe(result => {
      if (result["error"]) {
        this.router.navigate(['/']);
        return;
      }
      this.auth(result);
    });
  }

  public cancel(){
    this.router.navigate(['/']);
  }

  public submit(){
    if (!this.profileForm.invalid) {
      this.spinner.show();
      var userTmp = new UserTmp();
      Object.assign(userTmp, this.profileForm.value);
      userTmp.email = this.initialMail;
      this.linkedInApiService.createUser(userTmp).subscribe(result => {
        if (result) {
          this.spinner.hide();
          this.authService.linkedInLogin(result);
          this.router.navigate(['/edit-my-profile-page']);
        }
      });
    }
    else{
      this.spinner.hide();
      this.toastrService.error("Der er fejl i felterne");
    }
  }

  //Auth if user already exist
  private auth(linkedInUser: LinkedInUser) {
    if (linkedInUser.user) {
      this.authService.linkedInLogin(linkedInUser.user);
      this.spinner.hide();
      this.router.navigate(['/edit-my-profile-page']);
    }
    else{
      this.spinner.hide();
      this.initialMail = linkedInUser.linkedInData.email;
      this.buildForm(linkedInUser.linkedInData);
      this.newUserRequired = true;
    }
  }

  //Builds the formGroup and controls
  private buildForm(data){
    var preferredLocale = data.firstName.preferredLocale;
    this.profileForm = this.formbuilder.group({
      firstname: [data.firstName.localized[this.getLocaleKey(preferredLocale)], Validators.required],
      lastname: [data.lastName.localized[this.getLocaleKey(preferredLocale)], Validators.required],
      email: [data.email, Validators.required]
    });
    this.profileForm.addControl('password', this.passwordControl);
  }

  //Gets localized language and key
  private getLocaleKey(preferredLocale): string{
    var language = preferredLocale.language
    var country = preferredLocale.country
    return language + "_" + country;
  }
}
