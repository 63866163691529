import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';

export class CvNotCreatedTask extends TodoTask {
    taskDescription =
        `Denne bruger har ikke oprettet sit CV, venligst informere konsulenten angående dette`;
    type = TaskTypeEnum.CVNotCreated;
    taskName = "Cv er ikke blevet oprettet";
    completeTaskManually = false;

    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/personal-data`
    }
    public taskClickEvent(): string {
        throw new Error("Method not implemented.");
    }
}