import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../core/services/spinner.service';
import { environment } from 'src/environments/environment';
import { FacebookApiService } from '../core/api/facebook-api.service';
import { FacebookService } from '../core/services/facebook.service';

@Component({
  selector: 'app-facebook-auth',
  templateUrl: './facebook-auth.component.html',
  styleUrls: ['./facebook-auth.component.scss']
})
export class FacebookAuthComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private facebookApiService: FacebookApiService,
    private router: Router,
    private spinner: SpinnerService,
    private facebookService: FacebookService
    ) { }

  ngOnInit() {
    this.spinner.show();
    const code = this.activatedRoute.snapshot.queryParamMap.get("code");
    if (code) {
      const redirectUri = window.location.origin + '/facebook-auth';
      this.facebookApiService.get(code, redirectUri).subscribe(_ => {
        const state = this.activatedRoute.snapshot.queryParamMap.get("state");
        this.spinner.hide();
        this.facebookService.authenticated = true;
        this.router.navigate(['tasks/' + state]);
      });
    }
  }

}
