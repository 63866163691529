import { TodoTask } from "../todo-task";

export class ConsultantsSentToCustomerTask extends TodoTask {

  taskDescription = `Sourcer har sendt nye konsulenter. Venligst klik her for at se dem`;
  taskName = "Nye konsulenter modtaget";
  completeTaskManually = true;

  constructor(id: number, fullname: string, relatedContext: number) {
    super(id, fullname);
    this.relatedContext = relatedContext;
  }

  public getRouteLink(): string {
    return `/customer/tasks/${this.relatedContext}/consultants`;
  }
}