import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

/**Controls the url prefix for which language this application is running on */
export class LangService {

  constructor(private translateService: TranslateService) {

  }

  public changeLang(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('lang', lang);
  }

  public getSavedLang(): string {
    let lang = localStorage.getItem('lang');
    if (lang) {
      return lang;
    }
    return 'da';
  }
}
