import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public showSpinner: boolean = false;
  constructor() { }

  public show() {
    this.showSpinner = true;
  }

  public hide() {
    this.showSpinner = false;
  }
}
