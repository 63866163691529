import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveysComponent } from './components/surveys/surveys.component';
import { AddSurveyComponent } from './components/add-survey/add-survey.component';
import { SurveyAttemptComponent } from './components/survey-attempt/survey-attempt.component';
import { SurveyResultComponent } from './components/survey-result/survey-result.component';

const routes: Routes = [
    {
        path: '',
        component: SurveysComponent,
    },
    {
        path: 'add',
        component: AddSurveyComponent,
    },
    {
        path: 'edit/:id',
        component: AddSurveyComponent,
    },
    {
        path: 'result/:id',
        component: SurveyResultComponent,
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class SurveysRoutingModule { }