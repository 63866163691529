import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
    HttpClient,
    HttpParams,
    HttpHeaders,
    HttpResponse,
} from "@angular/common/http";
import { Task } from "../models/task";
import { Observable } from "rxjs";
import { TaskSearchParameters } from "../models/task-search-parameters";
import { TaskSearchResult } from "../models/task-search-result";
import { AddProfileToTaskComponent } from "../../search/components/add-profile-to-task/add-profile-to-task.component";
import { ProfileTask } from "../models/profile-task";
import { TaskSmall } from "../models/task-small";
import { AddProfilesToTask } from "../models/add-profiles-to-task";
import { ProfileTaskSearchParameters } from "../models/profile-task-search-parameters";
import { ProfileTaskSearchResult } from "../models/profile-task-search-result";
import { TaskSendEmail } from "../models/task-send-email";
import { TaskConsultantComment } from "../models/task-consultant-comment";
import { ProfileContractInfo } from "../models/profile-contract-info";
import { MiniProfileTask } from "../models/mini-profile-task";
import { TaskPositionEnum } from "../models/enum/task-position-enum";

@Injectable({
    providedIn: "root",
})
export class TaskApiService {
    private apiBaseUrl = `${environment.apiUrl}task`;

    constructor(private httpClient: HttpClient) {}

    public get(taskId: number): Observable<Task> {
        return this.httpClient.get<Task>(`${this.apiBaseUrl}/${taskId}`);
    }

    public searchTasks(searchText: string) {
        return this.httpClient.get<TaskSmall[]>(
            `${this.apiBaseUrl}/taskSmalls?searchText=${searchText}`
        );
    }

    public searchActiveTasks(searchText: string, profileId: number) {
        return this.httpClient.get<TaskSmall[]>(
            `${this.apiBaseUrl}/taskActiveSmalls?searchText=${searchText}&profileId=${profileId}`
        );
    }

    public find(
        taskSearchParameters: TaskSearchParameters
    ): Observable<TaskSearchResult> {
        return this.httpClient.post<TaskSearchResult>(
            `${this.apiBaseUrl}/find`,
            taskSearchParameters
        );
    }

    public getAll(): Observable<TaskSmall[]> {
        return this.httpClient.get<TaskSmall[]>(`${this.apiBaseUrl}/getAll`);
    }

    public getAllNoFilter(): Observable<TaskSmall[]> {
        return this.httpClient.get<TaskSmall[]>(
            `${this.apiBaseUrl}/getAllNoFilter`
        );
    }

    public getTasksByProfileId(profileId: number) {
        return this.httpClient.get<TaskSmall[]>(
            `${this.apiBaseUrl}/getTasksByProfileId/${profileId}`
        );
    }

    public getAsCsv(taskId: number): Observable<HttpResponse<Blob>> {
        return this.httpClient.get<Blob>(
            `${this.apiBaseUrl}/getAsCsv/${taskId}`,
            {
                observe: "response",
                responseType: "blob" as "json",
            }
        );
    }

    public findProfileTasks(
        profileTaskSearchParameters: ProfileTaskSearchParameters
    ): Observable<ProfileTaskSearchResult> {
        return this.httpClient.post<ProfileTaskSearchResult>(
            `${this.apiBaseUrl}/findProfileTasks`,
            profileTaskSearchParameters
        );
    }

    /**
     * getProfileTasks
     * Finds all the tasks this profile is in
     */
    public getProfileTasks(profileId: number): Observable<MiniProfileTask[]> {
        return this.httpClient.get<MiniProfileTask[]>(
            `${this.apiBaseUrl}/getProfileTasks/${profileId}`
        );
    }

    /**
     * getProfiles
     * Finds the profiles related to specific task by id
     */
    public getProfiles(taskId: number): Observable<ProfileContractInfo[]> {
        return this.httpClient.get<ProfileContractInfo[]>(
            `${this.apiBaseUrl}/getProfiles/${taskId}`
        );
    }

    public addProfiles(addProfilesToTask: AddProfilesToTask): Observable<void> {
        return this.httpClient.post<void>(
            `${this.apiBaseUrl}/addProfiles`,
            addProfilesToTask
        );
    }

    public saveComment(
        taskConsultantComment: TaskConsultantComment
    ): Observable<TaskConsultantComment> {
        return this.httpClient.post<TaskConsultantComment>(
            `${this.apiBaseUrl}/saveComment`,
            taskConsultantComment
        );
    }

    public updateTasksProfileRelations(
        profileTasks: ProfileTask[]
    ): Observable<void> {
        return this.httpClient.post<void>(
            `${this.apiBaseUrl}/updateTasksProfileRelations`,
            profileTasks
        );
    }

    public deleteTaskProfileRelation(profileTaskId: number): Observable<void> {
        return this.httpClient.delete<void>(
            `${this.apiBaseUrl}/deleteTaskProfileRelation/${profileTaskId}`
        );
    }

    public sendEmail(taskSendEmail: TaskSendEmail): Observable<void> {
        return this.httpClient.post<void>(
            `${this.apiBaseUrl}/sendEmail`,
            taskSendEmail
        );
    }

    public save(task: Task): Observable<void> {
        return this.httpClient.post<void>(`${this.apiBaseUrl}/save`, task);
    }

    /**
     * Updates a small portion of the task, including UpdatedDate if necessary.
     */
    public patchSmall(task: TaskSmall): Observable<void> {
        // Automatically set the UpdatedDate to the current time if TaskPositionEnum has changed.
        if (task.taskPosition !== undefined) {
            task.updatedDate = new Date();
        }

        return this.httpClient.patch<void>(
            `${this.apiBaseUrl}/patchSmallTask`,
            task
        );
    }

    public delete(taskId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiBaseUrl}/${taskId}`);
    }

    public extractData(
        taskStatus: TaskPositionEnum | null,
        startDate: string,
        endDate: string
    ): Observable<HttpResponse<Blob>> {
        const params = { taskStatus, startDate, endDate };
        return this.httpClient.post<Blob>(
            `${this.apiBaseUrl}/extractData`,
            params,
            { observe: "response", responseType: "blob" as "json" }
        );
    }
}
