import {Component, OnInit, Input, HostListener, ElementRef, OnDestroy} from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentiation.service';
import { Router } from '@angular/router';
import { User } from '../../../core/models/user';
import { UserRoleEnum } from '../../../core/models/enum/user-role-enum';
import { NotificationApiService } from 'src/app/core/api/notification-api.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

    @Input() showNotification: boolean;

    public showNavigationMenu = false;
    public showNotificationMenu = false;
    public currentUser : User;
    public isAdmin : boolean;
    public roleText = '';
    public unreadNotificationCount = 0; // Used for the count in the red bubble on the notification-menu button

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private eRef: ElementRef
    ) { }

    @HostListener('document:click',['$event.target'])
    public closeMenusOnOutsideClick(target) {
        if (!this.eRef.nativeElement.contains(target)) {
            this.showNavigationMenu = false;
            this.showNotificationMenu = false;
        }
    }

    public ngOnInit() {
        this.authenticationService.currentUser.subscribe(user => {
            if (user) {
                this.currentUser = user;
                if (this.currentUser.userRoleEnumDto == UserRoleEnum.Admin) {
                    this.isAdmin = true;
                    this.setUserRoleText(this.currentUser.userRoleEnumDto);
                }
            }
        });
    }

    public onUnreadNotificationCountUpdated = (unreadNotificationCount) => { // called from child-component
        this.unreadNotificationCount = unreadNotificationCount;
    }

    public closeMenus(e){
        e.preventDefault();
        this.showNavigationMenu = false;
        this.showNotificationMenu = false;
    }

    public logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    public toggleNavigationMenu() {
        this.showNavigationMenu = !this.showNavigationMenu;
        if (this.showNavigationMenu) this.showNotificationMenu = false; // Hide other menu
    }

    public toggleNotificationMenu() {
        this.showNotificationMenu = !this.showNotificationMenu;
        if (this.showNotificationMenu) this.showNavigationMenu = false; // Hide other menu
    }

    private setUserRoleText(userRoleEnum: UserRoleEnum){
        switch (userRoleEnum) {
            case UserRoleEnum.Admin:
                this.roleText = 'Administrator';
                break;
            case UserRoleEnum.Profile:
                this.roleText = 'Konsulent';
                break;
            case UserRoleEnum.CustomerProfile:
                this.roleText = 'Kunde profil';
                break;
            default:
                break;
        }
    }
}
