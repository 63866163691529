import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalStateService } from './modal-state.service';

@Injectable()
export class ModalService {
    constructor(private ngbModal: NgbModal, private stateService: ModalStateService) { }

    public open(content: any, options?: NgbModalOptions): NgbModalRef {
        const modalRef = this.ngbModal.open(content, options);
        this.stateService.register(modalRef);

        return modalRef;
    }
}
