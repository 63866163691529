
<div class="modal-header panel-heading">
  <h2 class="modal-title"> {{ "labels.createNewActivity" | translate }}  </h2>
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="activityForm">
    <label class="col-12" for="profileId"> {{ "labels.consultant" | translate }} </label>
    <div class="input-group col-6 pb-5">
      <input *ngIf="profile.id" disabled type="text" class="task-search form-control" [value]="profile.firstname + ' ' + profile.surname">
      <input *ngIf="!profile.id" #profileSearchField type="text" class="task-search form-control" placeholder="Søg efter profil" name="profileId" formControlName="profileId"
          [ngbTypeahead]="profileResult" [inputFormatter]="profileFormatter" [resultFormatter]="profileFormatter"
          (selectItem)="onProfileFieldchanged($event)">
    </div>

    <label class="col-12" for="activityTypeId"> {{ "labels.activityType" | translate }} </label>
    <div class="input-group col-6 pb-5">
      <select class="form-control" formControlName="activityTypeId" name="type" (change)="onSelectChange($event)">
        <option [ngValue]="null" [selected]="true" data-default> {{ "labels.chooseYourType" | translate }} </option>
        <option *ngFor="let activityType of $activityTypes | async" [value]="activityType.id">
          {{activityType.name}}
        </option>
      </select>
    </div>

    <label for="note" class="col-12"> {{ "labels.internNote" | translate }} </label>
    <div class="input-group col-12 pb-5">
      <textarea placeholder="Beskriv opgaven" formControlName="note" class="form-control"></textarea>
    </div>

    <label class="col-12"> {{ "labels.timelog" | translate }} </label>
    <div class="input-group pb-2">
      <div class="col-9">
        <label class="pr-4 radio-label" for="now" (change)="changeStatus($event)">
          <input type="radio" name="status" formControlName="status" id="now" [value]="true" >
          <span class="checkmark"></span>
          <div> {{ "labels.performActivityNow" | translate }} </div>
        </label>
        <label for="later" class="radio-label" (change)="changeStatus($event)">
          <input type="radio" name="status" formControlName="status" id="later" [value]="false">
          <span class="checkmark"></span>
          <div> {{ "labels.performActivityFuture" | translate }} </div>
          <div class="additional-text">
            {{ "placeholders.willSaveTodo" | translate }}
          </div>
        </label>
      </div>
    </div>
    <hr class="mb-2">

    <div *ngIf="!performActivityNow">
      <label for="adminId" class="col-12"> {{ "labels.admin" | translate }} </label>
      <div class="input-group col-6 pb-5">
        <input type="text" class="task-search form-control" placeholder="Søg på admin" name="adminId" formControlName="adminId"
          [ngbTypeahead]="adminResult" [inputFormatter]="adminFormatter" [resultFormatter]="adminFormatter"
          (selectItem)="onAdminFieldChanged($event)">
      </div>

      <label for="deadline" class="col-12"> {{ "labels.deadline" | translate }} </label>
      <div class="input-group col-6 pb-5">
        <span class="input-group-addon">
            <i class="fa fa-calendar clickable" (click)="ds.toggle()"></i>
        </span>
        <input [readOnly]="true" class="form-control" placeholder="dd-mm-yyyy" name="deadline" formControlName="deadline"
            ngbDatepicker  (click)="ds.toggle()" #ds="ngbDatepicker">
      </div>
    </div>
  </form>
</div>

<div class="modal-footer panel-footer">
  <button type="button" (click)="close()" class="btn btn-outline-primary"> {{ "labels.cancel" | translate }} </button>
  <button type="button" (click)="save()" class="btn btn-primary"> {{ "labels.save" | translate }} </button>
</div>