import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  public authenticated: boolean;

  constructor() { }

  public getFacebookPageAccess(taskId) {
    const baseUrl = 'https://www.facebook.com/v8.0/dialog/oauth?'
    const redirectUri = window.location.origin + '/facebook-auth';
    const scope = 'pages_manage_posts%2Cpages_read_engagement'
    var faceboolUrl = `${baseUrl}client_id=${environment.facebookAppId}&redirect_uri=${redirectUri}&state=${taskId}&scope=${scope}`;
    window.location.href = faceboolUrl;
  }
}
