<div *ngFor="let item of question.surveyAnswerChoices">
  <input type="radio"
         [(ngModel)]="answer"
         [value]="item.text"
         [name]="'question'+question.id"
         [id]="'question'+question.id+'_'+item.id"
         style="margin-right: 5px;"
         (change)="emitValue(item.text)">
  <label for="{{'question'+question.id+'_'+item.id}}">{{item.text}}</label>
</div>
