
<section class="panel">
    <div class="panel-body">
        <div class="row">
            <div class="col-12 text-right">
                <div class="mb-md">
                    <button id="addToTable" class="btn btn-primary" (click)="addSurvey()">
                        {{ "buttons.addSurvey" | translate }}
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
        <table class="table table-bordered table-striped mb-none" id="datatable-editable">
            <thead>
                <tr>
                    <th (click)="sort()" [ngClass]="sortDescending ? 'asc' : 'desc'" class="sortable">
                        {{ "labels.survey" | translate }}
                        <div class="arrows"></div>
                    </th>
                    <th>{{ "labels.external" | translate }}</th>
                    <th style="width: 50px;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let survey of surveys">
                    <td class="survey-td" (click)="showSurvey(survey)"><a> {{survey.title}}</a></td>
                    <td class="survey-td" (click)="showSurvey(survey)"><a> {{survey.externalTitle}}</a></td>
                    <td class="actions">
                        <a (click)="share(survey, shareModal)">
                            <i class="fa fa-link"></i>
                        </a>&nbsp;
                        <a class="cancel-row" (click)="edit(survey)">
                            <i class="fa fa-pencil"></i>
                        </a>&nbsp;
                        <a (click)="delete(survey)" class="delete-row">
                            <i class="fa fa-trash-o"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>

<ng-template #shareModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title dark-modal-header" id="modal-basic-title">
            {{ "labels.surveyLink" | translate }}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-12">
            <p>{{ "labels.shareLink" | translate }}</p>
        </div>
        <div class="col-9 pr-0" style="display: inline-block;">
            <input style="width: 100%;" #urlToCopy value="{{url}}{{contextSharedSurvey.accessUid}}" readonly=true type="text">
        </div>
        <div class="col-3 pl-0" style="display: inline-block;">
            <button (click)="copyGeneratedUrlEvent(urlToCopy)" class="copy-button"><i class="fa fa-copy copy-icon"></i></button>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss">Ok</button>
    </div>
</ng-template>