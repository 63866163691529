export enum ProfileStatusEnum {
  Na = 0,
  NotTotalCreated = 1,
  CreatedNotSendToApproval = 2,
  CreatedAwaitingApproval = 3,
  Edited = 4,
  EditedAwaitingApproval = 5,
  Approved = 6,
  //Edited not send for admins so they can force send this cv
  EditedNotSendAdmin = 7,
}

