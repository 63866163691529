import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileImageComponent } from './profile-image/profile-image.component';



@NgModule({
  declarations: [ProfileImageComponent],
  imports: [
    CommonModule
  ],
  exports: [ProfileImageComponent]
})
export class ProfileImageModule { }
