/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./context-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./context-menu.component";
var styles_ContextMenuComponent = [i0.styles];
var RenderType_ContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContextMenuComponent, data: {} });
export { RenderType_ContextMenuComponent as RenderType_ContextMenuComponent };
function View_ContextMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [["class", "m-0"]], null, null, null, null, null))], null, null); }
function View_ContextMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onContextMenuClick($event, _v.context.$implicit.menuEvent) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContextMenuComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.index < (_co.contextMenuItems.length - 1)); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.menuText; _ck(_v, 1, 0, currVal_0); }); }
export function View_ContextMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "contextmenu"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "left.px": 0, "top.px": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "m-0 p-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContextMenuComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.contextPositionX, _co.contextPositionY); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.contextMenuItems; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-context-menu", [], null, null, null, View_ContextMenuComponent_0, RenderType_ContextMenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContextMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContextMenuComponentNgFactory = i1.ɵccf("app-context-menu", i3.ContextMenuComponent, View_ContextMenuComponent_Host_0, { contextPositionX: "contextPositionX", contextPositionY: "contextPositionY", contextMenuItems: "contextMenuItems" }, { onContextMenuItemClick: "onContextMenuItemClick" }, []);
export { ContextMenuComponentNgFactory as ContextMenuComponentNgFactory };
