<div class="navigation-menu" [ngClass]="{ 'small-text': isAdmin }">
    <div id="userbox" *ngIf="currentUser" class="userbox">
        <app-profile-image
            *ngIf="!isAdmin"
            [imagePath]="currentUser.imagePath"
            [alt]="currentUser.name"
            [width]="100"
            [showAsBackground]="true"
        >
        </app-profile-image>
        <div class="user-info" [ngClass]="{ 'small-text': isAdmin }">
            <span class="name"
                ><p>{{ currentUser.name }}</p></span
            >
            <span *ngIf="roleText" class="role"
                ><p>{{ roleText }}</p></span
            >
            <span *ngIf="currentUser.email" class="email"
                ><p>{{ currentUser.email }}</p></span
            >
        </div>
    </div>
    <ul class="nav nav-main">
        <ng-container *ngIf="currentUser && currentUser.userRoleEnumDto == 2">
            <li
                *ngFor="let link of adminLinks"
                [ngClass]="{ menuheader: link.addClass }"
            >
                <a routerLink="{{ link.route }}" (click)="closeMenus($event)">
                    <span>{{ link.menuItemName }}</span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="currentUser && currentUser.userRoleEnumDto == 1">
            <li *ngFor="let link of profileLinks">
                <a routerLink="{{ link.route }}" (click)="closeMenus($event)">
                    <span>{{ link.menuItemName }}</span>
                </a>
            </li>
        </ng-container>

        <ng-container *ngIf="currentUser && currentUser.userRoleEnumDto == 3">
            <li *ngFor="let link of customerProfileLinks">
                <a routerLink="{{ link.route }}" (click)="closeMenus($event)">
                    <img [src]="link.icon" alt="Icon" />
                    <span class="menu-item">{{ link.menuItemName }}</span>
                </a>
            </li>
        </ng-container>

        <li class="data" [ngClass]="{ 'small-text': isAdmin }">
            <a
                href="/assets/data-protection-and-privacy-policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
                ><img src="../../../../assets/images/AlignBottom.svg" /><span
                    class="menu-item menu-item-long"
                    >{{ "labels.dataAndPrivacy" | translate }}</span
                ></a
            >
        </li>

        <li class="change-password" [ngClass]="{ 'small-text': isAdmin }">
        <a (click)="requestChangePassword()" (click)="closeMenus($event)">
            <img src="../../../../assets/images/padlock.svg" />
            <span class="menu-item">{{ "menu.changePassword" | translate }}</span>
        </a>
        </li>


        <li class="logout" [ngClass]="{ 'small-text': isAdmin }">
            <a (click)="logout()"
                ><img src="../../../../assets/images/SignOut.svg" /><span
                    class="menu-item"
                    >{{ "menu.logOut" | translate }}</span
                ></a
            >
        </li>
    </ul>
</div>
