    import { DocumentFile } from './document-file';
import { Location } from './location';
export class ProfilePersonalData {
    public id: number;
    public experienceSince: number;
    public jobTitle: string;
    public firstname: string;
    public surname: string;
    public address: string;
    public zipcode: string;
    public city: string;
    public phone1: string;
    public phone2: string;
    public doWantMails: boolean;
    public wantFullEmployment: boolean;
    public email: string;
    public emailWork: string;
    public birth: Date;
    public businessName: Date;
    public availableFrom: Date;
    public documentFileNames: DocumentFile[];
    public documents: File[];
    public consultantRolePrimaryId?: number;
    public consultantRoleSecondaryId: number;
    public locationPrimaryId?: number;
    public locationsSecondaryIds: number[];
    public locationsSecondaryStringIds: string;
}
