import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
export class CvNotCreatedTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `Denne bruger har ikke oprettet sit CV, venligst informere konsulenten angående dette`;
        this.type = TaskTypeEnum.CVNotCreated;
        this.taskName = "Cv er ikke blevet oprettet";
        this.completeTaskManually = false;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
    taskClickEvent() {
        throw new Error("Method not implemented.");
    }
}
