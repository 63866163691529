import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-yes-no-question',
  templateUrl: './yes-no-question.component.html',
  styleUrls: ['./yes-no-question.component.scss']
})
export class YesNoQuestionComponent implements OnInit {

  @Input() index: number;
  @Output() answerChanged = new EventEmitter<string>()

  public surveyAnswer: string;
  constructor() { }

  ngOnInit() {
  }

  public emitValue(event) {
    this.answerChanged.emit(event.target.value)
  }

}
