import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FacebookApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}Facebook`;
    }
    get(code, redirectUri) {
        return this.httpClient.get(`${this.apiBaseUrl}?code=${code}&redirectUri=${redirectUri}`);
    }
    pagePost(post) {
        return this.httpClient.post(`${this.apiBaseUrl}/pagePost`, post);
    }
}
FacebookApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FacebookApiService_Factory() { return new FacebookApiService(i0.ɵɵinject(i1.HttpClient)); }, token: FacebookApiService, providedIn: "root" });
