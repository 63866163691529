import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SurveyApiService } from 'src/app/core/api/survey-api.service';
import { ActivatedRoute } from '@angular/router';
import { SurveyResult } from 'src/app/core/models/survey-result';
import { SurveyAttempt } from 'src/app/core/models/survey-attempt';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver/src/FileSaver';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ContextMenuItems } from 'src/app/core/models/context-menu-items';


@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.scss']
})
export class SurveyResultComponent implements OnInit {


  @ViewChild('scroll', { static: false }) someInput: ElementRef;
  @ViewChild('tableRow', { static: false }) tableRow: ElementRef;


  public surveyResult: SurveyResult;
  public truncateList: number[] = [];
  public isDown: boolean = false;

  public startX: number = 0;
  public scrollLeft: number = 0;
  public lastChance: number = 0;
  public contextMenu: boolean = false;
  public contextPositionX: number = 0;
  public contextPositionY: number = 0;
  public rightClickMenuItems: ContextMenuItems[] = [{ menuText: "Slet", menuEvent: "delete" }]
  public showFullAnswerId: number = -1;
  constructor(
    private surveyApiService: SurveyApiService,
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService) {
  }
  ngOnInit() {
    this.loadData();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    if (!this.isDown) return
    const x = e.pageX - this.someInput.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2;
    this.someInput.nativeElement.scrollLeft = this.scrollLeft - walk
    this.lastChance = x
  }
  public mouseDown(e) {
    this.isDown = true

    this.startX = e.pageX - this.someInput.nativeElement.offsetLeft
    this.scrollLeft = this.someInput.nativeElement.scrollLeft
  }
  public mouseUp() {
    this.isDown = false
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.tableRow.nativeElement.contains(event.target)) {
      if (this.contextMenu) {
        this.contextMenu = false
      }
    } else {
      this.contextMenu = false
    }
  }

  public onrightClick(event) {
    event.preventDefault();
    this.contextPositionX = event.pageX - 110 + this.scrollLeft
    this.contextPositionY = event.pageY - this.someInput.nativeElement.offsetTop - this.tableRow.nativeElement.offsetTop
    this.contextMenu = true;
  }

 public toggleComment(answer) {
    let selectedAnswer = answer;
    selectedAnswer.showCommentText = !selectedAnswer.showCommentText;
  }

  public test(answer) {
    if (answer.surveyQuestion.answerType == 2) {
      if (answer.answer === "true") {
        return "Ja"
      } else {
        return "Nej"
      }
    } else {
      return answer.answer
    }

  }

  public onDeleteClicked(attempt: SurveyAttempt) {
    this.surveyApiService.deleteSurveyAttempt(attempt.id).subscribe(_ => {
      let arrIndex = this.surveyResult.surveyAttempts.findIndex(element => element.id == attempt.id);
      this.surveyResult.surveyAttempts.splice(arrIndex, 1);
    });
    this.contextMenu = false
  }

  public onDownloadClicked() {
    this.surveyApiService.getSurveyCsvByPost(this.surveyResult).subscribe((resp: HttpResponse<Blob>) => {
      saveAs(resp.body, resp.headers.get('x-file-name'));
    })
  }

  showFullAnswer(id: number) {
      if(this.showFullAnswerId === id) {
          this.showFullAnswerId = -1;
      }
      else {
          this.showFullAnswerId = id;
          this.truncateList = [];
      }
    }

  private loadData() {
    this.spinner.show();
    var id = this.activatedRoute.snapshot.params.id;
    this.surveyApiService.getResult(id).subscribe(result => {
      this.spinner.hide();
      this.surveyResult = result;
    })
  }
  handleMenuItemClick(event, survey) {
    switch (event.data) {
      case "delete":
        this.onDeleteClicked(survey)
        break;
    }
  }
}