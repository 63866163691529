import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AdminApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}admin`;
    }
    find(adminSearchParameters) {
        return this.httpClient.post(`${this.apiBaseUrl}/find`, adminSearchParameters)
            .pipe(catchError(this.handleError));
    }
    search(searchWord) {
        return this.httpClient.get(`${this.apiBaseUrl}/search?searchWord=${searchWord}`);
    }
    findByInitials(initials) {
        return this.httpClient.get(`${this.apiBaseUrl}?initials=${initials}`);
    }
    getAllAdmins(isAccountManager = null) {
        if (isAccountManager == null) {
            return this.httpClient.get(`${this.apiBaseUrl}/getAll`);
        }
        return this.httpClient.get(`${this.apiBaseUrl}/getAll?isAccountManager=${isAccountManager}`);
    }
    /**Gets all admin related consultants */
    getConsultants(adminId, page) {
        return this.httpClient.get(`${this.apiBaseUrl}/getConsultants?adminId=${adminId}&page=${page}`);
    }
    getToRecruitedBy() {
        return this.httpClient.get(`${this.apiBaseUrl}/getToRecruitedBy`);
    }
    save(admin) {
        return this.httpClient.post(`${this.apiBaseUrl}`, admin);
    }
    delete(adminId) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${adminId}`);
    }
    transfer(info) {
        return this.httpClient.patch(`${this.apiBaseUrl}/transfer`, info);
    }
    makeSuperAdmin(admin) {
        return this.httpClient.patch(`${this.apiBaseUrl}/makeSuperAdmin`, admin);
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
AdminApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminApiService_Factory() { return new AdminApiService(i0.ɵɵinject(i1.HttpClient)); }, token: AdminApiService, providedIn: "root" });
