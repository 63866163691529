import { TodoTask } from "../todo-task";
export class ContractFollowUpTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} kontrakt udløber snart. Venligst lav en opfølgning af kontrakten.`;
        this.taskName = "Kontrakt udløber snart";
        this.completeTaskManually = true;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}
