import { KpiLiveData } from './../models/kpi-live-data';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { TodoTasksDto } from 'src/app/dashboard/components/todo-list-full/models/todo-task-dto';
import { Widget } from 'src/app/core/models/widget'
import { ComponentTypeEnum } from '../models/enum/component-type-enum';
import { TodosSearchResult } from '../models/todos-search-result';
import { TodoSortOrderEnum } from '../models/enum/todo-sort-order-enum';
import { Admin } from '../models/admin';
import { TodoTaskSmall } from '../models/todo-task-small';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {
  private tasksNumber;
  private _tasksNumber = new BehaviorSubject<number>(0);
  tasksNumber$ = this._tasksNumber.asObservable();

  private apiBaseUrl = `${environment.apiUrl}dashboard`;

  constructor(private httpClient: HttpClient) { }

  public getTasksNumber(): Observable<number> {
    this.httpClient.get<number>(`${this.apiBaseUrl}/getTodoTasksCount`).subscribe(val => {
      this._tasksNumber.next(val);
      this.tasksNumber = val;
    });
    return this.tasksNumber$;
  }

  public getWidgets(): Observable<Widget[]> {
    return this.httpClient.get<Widget[]>(`${this.apiBaseUrl}/getWidgets`);
  }

  public postWidget(widgets: Widget): Observable<Widget> {
    return this.httpClient.post<Widget>(`${this.apiBaseUrl}/postWidget`, widgets);
  }

  public deleteWidget(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiBaseUrl}/deleteWidget/${id}`);
  }

  public patchWidget(widget: Widget): Observable<Widget> {
    return this.httpClient.patch<Widget>(`${this.apiBaseUrl}/patchWidget`, widget);
  }

  public getTasks(): Observable<TodoTasksDto[]> {
    return this.httpClient.get<TodoTasksDto[]>(`${this.apiBaseUrl}/getTasks`);
  }

  public getAllNewTasks(page: number, pageSize: number, selectedTypeId: number, selectedAdminId: number, isActivity: boolean, sortOrder: TodoSortOrderEnum, isTodoTaskCompleted: boolean) {
    var url = `${this.apiBaseUrl}/getAllNewTasks?page=${page}&pageSize=${pageSize}`;
    if (selectedTypeId) {
      url = url.concat(`&taskTypeId=${selectedTypeId}`)
    }
    if (selectedAdminId) {
      url = url.concat(`&adminId=${selectedAdminId}`)
    }
    url = url.concat(`&isActivity=${isActivity}`)
    url = url.concat(`&sortOrder=${sortOrder}`)
    url = url.concat(`&isTodoTaskCompleted=${isTodoTaskCompleted}`)
    url = url.concat(`&sortOrder=${sortOrder}`)
    return this.httpClient.get<TodosSearchResult>(url);
  }

  public getFinishedTasks(): Observable<TodoTasksDto[]> {
    return this.httpClient.get<TodoTasksDto[]>(`${this.apiBaseUrl}/getFinishedTasks`);
  }

  public changeTaskAssignee(item: TodoTaskSmall) {
    return this.httpClient.patch(`${this.apiBaseUrl}/changeTaskAssignee`, item);
  }


  public completeTask(taskId: number): Observable<TodoTasksDto> {
    this._tasksNumber.next(this.tasksNumber - 1);
    this.tasksNumber--;
    return this.httpClient.patch<TodoTasksDto>(`${this.apiBaseUrl}/completeTask`, taskId);
  }

  public unCompleteTask(taskId: number): Observable<TodoTasksDto> {
    this._tasksNumber.next(this.tasksNumber + 1);
    this.tasksNumber++;
    return this.httpClient.patch<TodoTasksDto>(`${this.apiBaseUrl}/unCompleteTask`, taskId);
  }

  public setTaskPending(taskId: number) {
    return this.httpClient.patch(`${this.apiBaseUrl}/setTaskPending`, taskId);
  }

  public updatePriority(task: TodoTasksDto) {
    return this.httpClient.patch(`${this.apiBaseUrl}/updatePriority`, task);
  }

  public getKpiLiveData(): Observable<KpiLiveData> {
    return this.httpClient.get<KpiLiveData>(`${this.apiBaseUrl}/kpiLiveData`);
  }

  public getComponents() {
    return this.httpClient.get<ComponentTypeEnum[]>(`${this.apiBaseUrl}/getComponents`);
  }

  public patchTask(task: TodoTasksDto) {
    return this.httpClient.patch(`${this.apiBaseUrl}/patchTask`, task);
  }
}
