import { SendEmailModel } from './../../search/models/send-email-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ProfilePersonalData } from '../models/profile-personal-data';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileSearchParameters } from '../models/profile-search-parameters';
import { SearchResultWrapper } from '../models/search-result-wrapper';
import { FocusResultWrapper } from '../models/focus-result-wrapper';
import { BaseSearchParameters } from '../models/base-search-parameters';
import { ProfileStatus } from '../models/profile-status';
import { ProfileSearchResult } from '../models/profile-search-result';
import { ProfileFocusResult } from '../models/profile-focus-result';
import { ProfileConsultantAdminData } from '../models/profile-consultant-admin-data';
import { IdContainer } from '../models/id-container'
import { MinMaxDto } from '../models/min-max-dto';
import { PresentableProfile } from '../models/presentable-profile';
import { DocumentFile } from '../models/document-file';
import { ProfileTaskSmall } from '../models/profile-task-small';
import { MiniProfile } from '../models/mini-profile';
import { ProfileContract } from '../models/profile-contract';

@Injectable({
    providedIn: 'root'
})
export class ProfileApiService {


    private apiBaseUrl = `${environment.apiUrl}profile`;

    constructor(private httpClient: HttpClient) { }

    public get(profileId: number): Observable<ProfilePersonalData> {
        if (profileId) {
            const params = new HttpParams().set('profileId', profileId + '');
            return this.httpClient.get<ProfilePersonalData>(`${this.apiBaseUrl}/getProfile`, { params });
        }
        else {
            return this.httpClient.get<ProfilePersonalData>(`${this.apiBaseUrl}/getProfile`);
        }
    }

    public getProfileStatus(profileId: number): Observable<ProfileStatus> {
        const params = new HttpParams().set('profileId', profileId + '');
        return this.httpClient.get<ProfileStatus>(`${this.apiBaseUrl}/getProfileStatus`, { params });
    }

    public getOwnProfilesContract() {
        return this.httpClient.get<ProfileContract[]>(`${this.apiBaseUrl}/getOwnProfilesContract`);
    }

    public findProfiles(profileSearchParameters: ProfileSearchParameters): Observable<SearchResultWrapper> {
        return this.httpClient.post<SearchResultWrapper>(`${this.apiBaseUrl}/findProfiles`, profileSearchParameters);
    }

    public searchProfiles(searchText: string) {
        return this.httpClient.get<MiniProfile>(`${this.apiBaseUrl}/searchProfiles?searchText=${searchText}`);
    }

    public getAsCsv(profileSearchParameters: ProfileSearchParameters): Observable<HttpResponse<Blob>> {
        return this.httpClient.post<Blob>(`${this.apiBaseUrl}/getAsCsv`, profileSearchParameters, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    public getFocusProfiles(baseSearchParameters: BaseSearchParameters): Observable<FocusResultWrapper> {
        return this.httpClient.post<FocusResultWrapper>(`${this.apiBaseUrl}/GetWithFocus`, baseSearchParameters);
    }

    // Hvilken model?
    public getTopTenConsultants(baseSearchParameters: BaseSearchParameters): Observable<FocusResultWrapper> {
        return this.httpClient.post<FocusResultWrapper>(`${this.apiBaseUrl}/getTopTenConsultants`, baseSearchParameters);
    }

    public approveProfile(profileId: number): Observable<ProfileStatus> {
        const params = new HttpParams().set('profileId', profileId + '');
        return this.httpClient.post<ProfileStatus>(`${this.apiBaseUrl}/approveProfile`, params);
    }

    public sendProfileToApproval(): Observable<ProfileStatus> {
        return this.httpClient.post<ProfileStatus>(`${this.apiBaseUrl}/sendProfileToApproval`, undefined);
    }

    public forceSendProfileToApproval(profileId: number) {
        return this.httpClient.post<ProfileStatus>(`${this.apiBaseUrl}/forceSendProfileToApproval`, profileId);
    }

    public save(profilePersonalData: ProfilePersonalData): Observable<ProfileStatus> {
        return this.httpClient.post<ProfileStatus>(`${this.apiBaseUrl}`, profilePersonalData);
    }

    public delete(profileId: number) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${profileId}`);
    }

    public removeDeletableProfiles() {
        return this.httpClient.delete(`${this.apiBaseUrl}/removeDeletableProfiles`);
    }

    public getMinMaxExperienceSince() {
      return this.httpClient.get<MinMaxDto>(`${this.apiBaseUrl}/getminmaxexp`)
    }

    //TODO: Should these routes api calls be moved to another service maybe new widget-api.service.ts file?
    public getThisMonthProfiles() : Observable<ProfileSearchResult[]> {
        return this.httpClient.get<ProfileSearchResult[]>(`${this.apiBaseUrl}/getthisMonthProfiles`)
    }

    public getExpiringProfiles() : Observable<ProfileFocusResult[]> {
        return this.httpClient.get<ProfileFocusResult[]>(`${this.apiBaseUrl}/getExpiringProfiles`)
    }
    public getConsultantsOnContract() : Observable<ProfileFocusResult[]> {
        return this.httpClient.get<ProfileFocusResult[]>(`${this.apiBaseUrl}/getConsultantsOnContract`)
    }

    public getOwnProfiles() : Observable<ProfileConsultantAdminData[]> {
        return this.httpClient.get<ProfileConsultantAdminData[]>(`${this.apiBaseUrl}/getownprofiles`)
    }

    public getPresentableProfiles(profileIds: number[]){
        let query = '';
        for (let i = 0; i < profileIds.length; i++) {
            i == 0 ? query += '?' : query += '&';
            const element = profileIds[i];
            query += `profileId=${element}`
        }
        return this.httpClient.get<PresentableProfile[]>(`${this.apiBaseUrl}/getPresentableProfiles${query}`)
    }

    public getSuggestedProfiles() {
        return this.httpClient.get<ProfileTaskSmall[]>(`${this.apiBaseUrl}/getSuggestedProfiles`);
    }

    public sendEmail(sendEmail: SendEmailModel): Observable<void> {
        return this.httpClient.post<void>(`${this.apiBaseUrl}/sendEmailToConsultants`, sendEmail);
    }

    public postDocuments(formData: FormData) {
        return this.httpClient.post<DocumentFile[]>(`${this.apiBaseUrl}/postDocuments`, formData);
    }

    public deleteDocument(id: number) {
        return this.httpClient.delete<void>(`${this.apiBaseUrl}/deleteDocument/${id}`);
    }

    public getProfileById(profileId: number): Observable<ProfilePersonalData> {
        return this.httpClient.get<ProfilePersonalData>(`${this.apiBaseUrl}/${profileId}`);
      }


}
