import { Component, OnInit } from '@angular/core';
import { UserApiService } from '../core/api/user-api.service';
import { LangService } from '../core/services/lang.service';

@Component({
  selector: 'app-main-profile',
  templateUrl: './main-profile.component.html',
  styleUrls: ['./main-profile.component.scss']
})
export class MainProfileComponent implements OnInit {

  public currentLang;
  constructor(
    private langService: LangService,
    private userApiService: UserApiService
    ) {
    this.currentLang = this.langService.getSavedLang();
  }

  ngOnInit() {

  }

  public useLang(lang: string) {
    this.langService.changeLang(lang);
    this.userApiService.patchLanguage(lang).subscribe();
  }

}
