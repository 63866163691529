import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthenticationService {
    constructor(http) {
        this.http = http;
        this.firstLogin = false;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    get currentUserValue() {
        return this.currentUserSubject.value;
    }
    login(email, password, twoFactorCode) {
        const userTmp = new User();
        userTmp.email = email;
        userTmp.password = password;
        userTmp.twoFactorCode = twoFactorCode;
        return this.http.post(`${environment.apiUrl}user/authenticate`, userTmp)
            .pipe(map(user => {
            // login successful if there's a jwt token in the response
            // and if TwoFactor isn't required/has been accepted
            if (user && user.token && !user.requireTwoFactor) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.saveUserInStorage(user);
                this.currentUserSubject.next(user);
            }
            return user;
        }));
    }
    linkedInLogin(user) {
        this.saveUserInStorage(user);
        this.currentUserSubject.next(user);
        return user;
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
    saveUserInStorage(user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
