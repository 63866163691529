import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
import { TodoTask } from '../todo-task';
export class CvCreatedNotSendTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} har ikke sendt sit CV til godkendelse \nvenligst informere konsulenten `;
        this.type = TaskTypeEnum.CVCreatedNotSend;
        this.taskName = "Cv oprettet men ikke sendt";
        this.completeTaskManually = false;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
    taskClickEvent() {
        throw new Error("Method not implemented.");
    }
}
