import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentiation.service';
import { UserRoleEnum } from '../models/enum/user-role-enum';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role
            if (route.data.userRoleEnum &&
                route.data.userRoleEnum.indexOf(currentUser.userRoleEnumDto) === -1) {
                // role not authorised so redirect to home page
                let navigatePager = '/edit-my-profile-page';
                if (currentUser.userRoleEnumDto === UserRoleEnum.Admin) {
                    navigatePager = '/';
                }
                else if (currentUser.userRoleEnumDto === UserRoleEnum.CustomerProfile) {
                    navigatePager = 'customer';
                }

                this.router.navigate([navigatePager]);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
