import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SurveyAnswerChoiceApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}surveyAnswerChoice`;
    }
    ;
    postChoice(choice) {
        return this.httpClient.post(this.apiBaseUrl, choice);
    }
    patchChoice(choice) {
        return this.httpClient.patch(this.apiBaseUrl, choice);
    }
    deleteChoice(id) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${id}`);
    }
}
SurveyAnswerChoiceApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SurveyAnswerChoiceApiService_Factory() { return new SurveyAnswerChoiceApiService(i0.ɵɵinject(i1.HttpClient)); }, token: SurveyAnswerChoiceApiService, providedIn: "root" });
