/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-archive-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./email-archive-page.component";
import * as i5 from "../../../core/api/email-log-api.service";
import * as i6 from "../../../core/services/spinner.service";
import * as i7 from "../../../core/modal/modal.service";
var styles_EmailArchivePageComponent = [i0.styles];
var RenderType_EmailArchivePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailArchivePageComponent, data: {} });
export { RenderType_EmailArchivePageComponent as RenderType_EmailArchivePageComponent };
function View_EmailArchivePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [["style", "border-bottom:1px solid #aaa; cursor:pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showEmail(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.createdDate, _co.dateFormatEnum.Full)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.subject; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.emails; _ck(_v, 7, 0, currVal_2); }); }
export function View_EmailArchivePageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 12, "table", [["cellpadding", "5"], ["cellspacing", "0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailArchivePageComponent_1)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "email-pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "email-page-button"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrevPageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["Side ", " "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", "email-page-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNextPageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.emailLogs; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("labels.mailLogs")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("profilePage.contractDate")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("labels.subject")); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("labels.recipient")); _ck(_v, 13, 0, currVal_3); var currVal_5 = (_co.page <= 0); _ck(_v, 18, 0, currVal_5); var currVal_6 = (_co.page + 1); _ck(_v, 21, 0, currVal_6); }); }
export function View_EmailArchivePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-archive-page", [], null, null, null, View_EmailArchivePageComponent_0, RenderType_EmailArchivePageComponent)), i1.ɵdid(1, 114688, null, 0, i4.EmailArchivePageComponent, [i5.EmailLogApiService, i6.SpinnerService, i7.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailArchivePageComponentNgFactory = i1.ɵccf("app-email-archive-page", i4.EmailArchivePageComponent, View_EmailArchivePageComponent_Host_0, {}, {}, []);
export { EmailArchivePageComponentNgFactory as EmailArchivePageComponentNgFactory };
