import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from '../../../../../core/models/enum/task-type-enum';

export class ProfileLogAddedTask extends TodoTask{
    taskDescription = `${this.fullName} har skrevet eller redigeret en kommentar`;
    type = TaskTypeEnum.ProfileLogAdded;
    taskName = "Kommentar skrevet af konsulent";
    completeTaskManually = true;

    public getRouteLink(): string {
        return `/search/profile/edit/${this.profileId}/profile-log`;
    }
}
