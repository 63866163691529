import { Activity } from "src/app/core/models/activity";
import { TodoTask } from "../todo-task";

export class ActivityTask extends TodoTask {

  completeTaskManually = true;

  constructor(id: number, fullname: string, activity: Activity) {
    super(id, fullname);
    this.activity = activity;
    this.taskDescription = activity.activityType.description;
    this.taskName = activity.activityType.name;
    this.completeTaskManually = true;
  }

  public getRouteLink(): string {
    return `/search/profile/edit/${this.profileId}/personal-data`;
  }
}