<h1> {{ "labels.mailLogs" | translate }} </h1>
<table cellpadding="5" cellspacing="0">
  <tr>
    <th>{{ "profilePage.contractDate" | translate }}</th>
    <th>{{ "labels.subject" | translate }}</th>
    <th>{{ "labels.recipient" | translate }}</th>
  </tr>
  <tr *ngFor="let emailLog of emailLogs" (click)="showEmail(emailLog)"
    style="border-bottom:1px solid #aaa; cursor:pointer;">
    <td>{{emailLog.createdDate | date: dateFormatEnum.Full}}</td>
    <td>{{emailLog.subject}}</td>
    <td>{{emailLog.emails}}</td>
  </tr>
</table>

<div class="email-pagination">
  <div class="email-page-button" [class.disabled]="page <= 0" (click)="onPrevPageClick()"><i
      class="fa fa-chevron-left"></i></div>
  <div>Side {{ page + 1 }} </div>
  <div class="email-page-button" (click)="onNextPageClick()"><i
      class="fa fa-chevron-right"></i></div>
</div>