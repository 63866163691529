<div class="rating">
  <input type="radio" [(ngModel)]="surveyAnswer" (change)="emitValue($event)"
      value="5" id="1+{{item ? item.id : ''}}" name="relevance+{{item ? item.id : ''}}" checked>
      <label for="1+{{item ? item.id : ''}}">☆</label>
  <input type="radio" [(ngModel)]="surveyAnswer" (change)="emitValue($event)"
      value="4" id="2+{{item ? item.id : ''}}" name="relevance+{{item ? item.id : ''}}">
      <label for="2+{{item ? item.id : ''}}">☆</label>
  <input type="radio" [(ngModel)]="surveyAnswer" (change)="emitValue($event)"
      value="3" id="3+{{item ? item.id : ''}}" name="relevance+{{item ? item.id : ''}}">
      <label for="3+{{item ? item.id : ''}}">☆</label>
  <input type="radio" [(ngModel)]="surveyAnswer" (change)="emitValue($event)"
      value="2" id="4+{{item ? item.id : ''}}" name="relevance+{{item ? item.id : ''}}">
      <label for="4+{{item ? item.id : ''}}">☆</label>
  <input type="radio" [(ngModel)]="surveyAnswer" (change)="emitValue($event)"
      value="1" id="5+{{item ? item.id : ''}}" name="relevance+{{item ? item.id : ''}}">
      <label for="5+{{item ? item.id : ''}}">☆</label>
</div>
<div>
  <input class="rating__input rating__icon--none mr-2" id="no-rating+{{item ? item.id : ''}}"
      type="radio" value="0" [(ngModel)]="surveyAnswer" (change)="emitValue($event)" name="relevance+{{item ? item.id : ''}}">
  <label style="padding: 5px 10px 5px 0px;" for="no-rating+{{item ? item.id : ''}}">
    Dette spørgsmål er ikke relevant for mig</label>
</div>