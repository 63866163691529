<div class="" *ngIf="surveyResult">
  <h2>{{surveyResult.survey.title}}</h2>
  <br>
  <div class="row">
    <button (click)="onDownloadClicked()" style="margin: 20px" class="btn btn-primary pull-right">Download som
      CSV</button>
  </div>
  <div class="table-bordered table mb-none table-scroll" [class.active]="isDown" #scroll (mousedown)="mouseDown($event)" (mouseup)="mouseUp()"
    style="cursor: grab;overflow: auto;">
    <div class="table-wrap">
      <table>
        <thead>
          <tr class="p-5">
            <th class="fixed-side" scope="col">Konsulent</th>
            <th class="fixed-side" scope="col">Dato for besvarelse</th>
            <th class="text-center" scope="col" *ngFor="let surveyQuestion of surveyResult.survey.surveyQuestions">
              {{ surveyQuestion.order + ". " + surveyQuestion.question}}</th>
          </tr>
        </thead>
        <tbody>
          <tr #tableRow *ngFor="let surveyAttempt of surveyResult.surveyAttempts" (contextmenu)="onrightClick($event)">
            <td class="fixed-side">
              <p style="width: 200px;" class="m-0 p-1">{{surveyAttempt.fullname}}</p>
            </td>
            <td class="fixed-side">
              <p class="m-0 p-1">{{ surveyAttempt.createdDate | date: 'dd/MM/yyyy'}}</p>
            </td>
              <td *ngFor="let answers of surveyAttempt.surveyAnswers">
                <div class="d-flex justify-content-center" (click)="answers.comment ? toggleComment(answers) : (answers.surveyQuestion.answerType == 1 ? showFullAnswer(answers.id) : null)" [ngClass]="{'cursor': (answers.surveyQuestion.answerType == 1 && answers.answer.length > 30) || answers.comment}">
                  <p id="text"
                  [ngClass]="{'truncate':answers.surveyQuestion.answerType == 1 && !truncateList.includes(answers.id),'cursor':answers.surveyQuestion.answerType == 1 && answers.answer.length > 30, 'text-center':answers.surveyQuestion.answerType != 1}"
                  class="m-0 p-1" style="width: auto;">{{(test(answers).length>30)? (test(answers) | slice:0:30) + '...': (test(answers)) }}</p>
                  <span class="cursor" *ngIf="answers.surveyQuestion.answerType == 1">
                    <i [ngClass]="{'d-none':(answers.answer.length < 30 && !truncateList.includes(answers.id) && answers.id !== showFullAnswerId), 'fa-caret-up':truncateList.includes(answers.id) || answers.id === showFullAnswerId, 'fa-caret-down': !truncateList.includes(answers.id) && answers.id !== showFullAnswerId}"
                    class="fa ml-2 text-secondary mt-1" aria-hidden="true"></i>
                  </span>
                  <span *ngIf="answers.comment" (click)="toggleComment(answers)" class="cursor">
                <i class="fa text-secondary mt-1" [ngClass]="{'fa-caret-up': answers.showCommentText, 'fa-caret-down': !answers.showCommentText}"></i>
              </span>
                </div>
                <div>
                  <p *ngIf="answers.id === showFullAnswerId" class="text-center cursor" (click)="showFullAnswer(answers.id)">
                    {{ answers.answer }}
                  </p>
                </div>
                <div class="text-center cursor" *ngIf="answers.showCommentText" (click)="toggleComment(answers)">
                  <p>{{ answers.comment }}</p>
              </div>
              </td>
            <div *ngIf="contextMenu">
              <app-context-menu [contextPositionX]="contextPositionX" [contextPositionY]="contextPositionY"
                [contextMenuItems]="rightClickMenuItems"
                (onContextMenuItemClick)="handleMenuItemClick($event,surveyAttempt)">
              </app-context-menu>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <b> Antal svar: {{ surveyResult.surveyAttempts.length }} </b>
</div>