import * as SignalR from "@microsoft/signalr";
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/router";
export class NotificationService {
    constructor(toastrService, router) {
        this.toastrService = toastrService;
        this.router = router;
        this.listeners = [];
    }
    startConnection(user) {
        setTimeout(() => {
            this.hubConnection = new SignalR.HubConnectionBuilder()
                .withUrl(environment.appUrl + `notificationHub?userId=${user.id}`).build();
            this.hubConnection.start()
                .then(() => {
                console.log("Connection to notification hub has been started");
            })
                .catch((error) => {
                console.log(error);
            });
        }, 2000);
    }
    notificationListener() {
        setTimeout(() => {
            this.hubConnection.on('sendNotification', (data) => {
                var test = this.toastrService.info(data.content, data.header);
                test.onTap.subscribe(action => {
                    this.router.navigate([data.link]);
                });
                // Notify listeners
                this.listeners.forEach(listener => listener(data));
            });
        }, 2500);
    }
    addNotificationListener(listener) {
        this.listeners.push(listener);
    }
    removeNotificationListener(listener) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }
    hasConn() {
        return this.hubConnection != null ? true : false;
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.Router)); }, token: NotificationService, providedIn: "root" });
