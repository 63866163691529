import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerProfile } from '../models/customer-profile';
import { Task } from '../models/task';

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileApiService {

  private apiBaseUrl = `${environment.apiUrl}customerProfile`

  constructor(private httpClient: HttpClient) { }

  public save(customer: CustomerProfile) {
    return this.httpClient.post<CustomerProfile>(`${this.apiBaseUrl}`, customer);
  }

  public delete(customer: CustomerProfile) {
    return this.httpClient.post<CustomerProfile>(`${this.apiBaseUrl}/delete`, customer);
  }

  public getByCustomerId(customerId: number) {
    var queryParam = "";
    if (customerId) {
      queryParam = `?customerId=${customerId}`;
    }
    return this.httpClient.get<CustomerProfile[]>(`${this.apiBaseUrl}${queryParam}`);
  }

  public getTasks() {
    return this.httpClient.get<Task[]>(`${this.apiBaseUrl}/getTasks`);
  }

  public deleteTask(id: number) {
    return this.httpClient.delete(`${this.apiBaseUrl}/deleteTask/${id}`);
  }

  public getRecievedCustomers(taskId: number) {
    return this.httpClient.get(`${this.apiBaseUrl}/customersByTask`);
  }
}
