import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConsultantStatusPipe } from "./consultant-status.pipe";
import { TaskPositionPipe } from "./task-position.pipe";
import { TranslateTodoPipe } from "./translate-todo.pipe";
import { EscapeHtmlPipe } from "./escape-html.pipe";
import { IsHtmlEmptyPipe } from "./is-html-empty.pipe";
import { TranslateContentPipe } from "./translate-content.pipe";

@NgModule({
    declarations: [
        TaskPositionPipe,
        ConsultantStatusPipe,
        TranslateTodoPipe,
        EscapeHtmlPipe,
        IsHtmlEmptyPipe,
        TranslateContentPipe,
    ],
    imports: [CommonModule],
    exports: [
        TaskPositionPipe,
        ConsultantStatusPipe,
        TranslateTodoPipe,
        EscapeHtmlPipe,
        IsHtmlEmptyPipe,
        TranslateContentPipe,
    ],
})
export class SharedPipesModule {}
