import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CustomerProfileApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}customerProfile`;
    }
    save(customer) {
        return this.httpClient.post(`${this.apiBaseUrl}`, customer);
    }
    delete(customer) {
        return this.httpClient.post(`${this.apiBaseUrl}/delete`, customer);
    }
    getByCustomerId(customerId) {
        var queryParam = "";
        if (customerId) {
            queryParam = `?customerId=${customerId}`;
        }
        return this.httpClient.get(`${this.apiBaseUrl}${queryParam}`);
    }
    getTasks() {
        return this.httpClient.get(`${this.apiBaseUrl}/getTasks`);
    }
    deleteTask(id) {
        return this.httpClient.delete(`${this.apiBaseUrl}/deleteTask/${id}`);
    }
    getRecievedCustomers(taskId) {
        return this.httpClient.get(`${this.apiBaseUrl}/customersByTask`);
    }
}
CustomerProfileApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerProfileApiService_Factory() { return new CustomerProfileApiService(i0.ɵɵinject(i1.HttpClient)); }, token: CustomerProfileApiService, providedIn: "root" });
