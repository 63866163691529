import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
export class WelcomeTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName} er blevet oprettet i systemet venligst kald brugeren. Klik her for at komme til brugerens kontakt side`;
        this.type = TaskTypeEnum.Welcome;
        this.taskName = "Velkomst hilsen til konsulenten";
        this.completeTaskManually = true;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}
