

export enum TaskTypeEnum{
    CVNotCreated = 0,
    CVCreatedNotSend = 1,
    CVSentForApproval = 2,
    CVRejected = 3,
    Welcome = 4,
    CVNotOk = 5,
    ProfileLogAdded = 6,
    AvailableDateChanged = 7,
    OldCv = 8,
}