
import { CvNotCreatedTask } from './tasks/cv-not-created-task';
import { CvCreatedNotSendTask } from './tasks/cv-created-not-send';
import { CvNotOkTask } from './tasks/cv-not-ok-task';
import { CvSentTask } from './tasks/cv-sent-task';
import { ProfileLogAddedTask } from './tasks/profile-log-added';
import { TodoTask } from './todo-task';
import { DateChangedTask } from './tasks/date-changed-task';
import { WelcomeTask } from './tasks/welcome-task';
import { OldCvTask } from './tasks/old-cv-task';
import { AcceptCvChangesTask } from './tasks/accept-cv-changes-task';
import { InvitationSentTask } from './tasks/invitation-sent-task-';
import { ConsultantsSentToCustomerTask } from './tasks/consultants-sent-to-customer-task';
import { ContractFollowUpTask } from './tasks/contract-follow-up-task';
import { ActivityTask } from './tasks/activity-task';
import { TodoTasksDto } from '../models/todo-task-dto';
import { ApproveCvsSentTodoTask } from './tasks/approve-cvs-sent-task';
import { AccountManagerNewTaskCustomer } from './tasks/account-manager-new-task-customer';
import { StartupPackageTask } from './tasks/startup-package-todo';

export class TodoTaskFactory {

    public createTodoTask(task: TodoTasksDto): TodoTask {
        switch (task.taskTypeId) {
            //Cv not created
            case 1: {
                return new CvNotCreatedTask(task.id, task.fullName);
            }
            //Cv not send for approval
            case 2: {
                return new CvCreatedNotSendTask(task.id, task.fullName);
            }
            //Cv not send to approval
            case 3: {
                return new CvSentTask(task.id, task.fullName);
            }
            //Welcome the new user
            case 4: {
                return new WelcomeTask(task.id, task.fullName);
            }
            //Cv Not Ok
            case 5: {
                return new CvNotOkTask(task.id, task.fullName);
            }
            //Profile log has been added
            case 6: {
                return new ProfileLogAddedTask(task.id, task.fullName);
            }
            //Available date has been changed
            case 7: {
                return new DateChangedTask(task.id, task.fullName);
            }
            //Old cv task
            case 8: {
                return new OldCvTask(task.id, task.fullName);
            }
            case 11: {
                return new AcceptCvChangesTask(task.id, task.fullName);
            }
            case 14: {
                return new InvitationSentTask(task.id, task.fullName, task.relatedContext);
            }
            case 15: {
                return new ConsultantsSentToCustomerTask(task.id, task.fullName, task.relatedContext);
            }
            case 18: {
                return new ContractFollowUpTask(task.id, task.fullName);
            }
            case 19: {
                return new ActivityTask(task.id, task.fullName, task.activity);
            }
            case 20: {
                return new ApproveCvsSentTodoTask(task.id, task.fullName, task.relatedContext);
            }
            case 21: {
                return new AccountManagerNewTaskCustomer(task.id, task.fullName);
            }
            case 22: {
                return new StartupPackageTask(task.id, task.fullName);
            }
            default: {
                return null;
            }
        }

    }
}