import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class FacebookService {
    constructor() { }
    getFacebookPageAccess(taskId) {
        const baseUrl = 'https://www.facebook.com/v8.0/dialog/oauth?';
        const redirectUri = window.location.origin + '/facebook-auth';
        const scope = 'pages_manage_posts%2Cpages_read_engagement';
        var faceboolUrl = `${baseUrl}client_id=${environment.facebookAppId}&redirect_uri=${redirectUri}&state=${taskId}&scope=${scope}`;
        window.location.href = faceboolUrl;
    }
}
FacebookService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FacebookService_Factory() { return new FacebookService(); }, token: FacebookService, providedIn: "root" });
