import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, OperatorFunction } from 'rxjs';
import { SurveyResultComponent } from 'src/app/surveys/components/survey-result/survey-result.component';
import { environment } from 'src/environments/environment';
import { SharedSurvey } from '../models/shared-survey';
import { Survey } from '../models/survey';
import { SurveyQuestion } from '../models/survey-question';
import { SurveyReciever } from '../models/survey-reciever';
import { SurveyResponse } from '../models/survey-response';
import { SurveyResult } from '../models/survey-result';

@Injectable({
    providedIn: 'root'
})

export class SurveyApiService {
    private apiBaseUrl = `${environment.apiUrl}survey`;

    constructor(private httpClient: HttpClient) { };

    public postSurvey(survey: Survey) {
        return this.httpClient.post<Survey>(`${this.apiBaseUrl}`, survey);
    }

    public postSurveyQuestion(question: SurveyQuestion) {
        return this.httpClient.post<SurveyQuestion>(`${this.apiBaseUrl}/postQuestion`, question);
    }

    public postAnswer(surveyResponse: SurveyResponse) {
        return this.httpClient.post(`${this.apiBaseUrl}/postAnswer`, surveyResponse);
    }

    public get(id: string): Observable<Survey> {
        return this.httpClient.get<Survey>(`${this.apiBaseUrl}/${id}`);
    }

    public getSurvey(id: string): Observable<Survey> {
        return this.httpClient.get<Survey>(`${this.apiBaseUrl}/getSurvey/${id}`);
    }

    public getSharedSurvey(uid: string){
        return this.httpClient.get<Survey>(`${this.apiBaseUrl}/getSharedSurvey?accessUid=${uid}`)
    }

    public getSurveys(): Observable<Survey[]> {
        return this.httpClient.get<Survey[]>(`${this.apiBaseUrl}/getSurveys`);
    }

    public getSurveysDisableFiltered(): Observable<Survey[]> {
        return this.httpClient.get<Survey[]>(`${this.apiBaseUrl}/getSurveys/open`);
    }


    public getResult(id): Observable<SurveyResult> {
        return this.httpClient.get<SurveyResult>(`${this.apiBaseUrl}/getResult/${id}`)
    }

    public getSharedSurveyURL(id) {
        return this.httpClient.get<SharedSurvey>(`${this.apiBaseUrl}/getSharedSurveyURL/${id}`);
    }

    public getSurveyCsvByPost(result: SurveyResult) {
        return this.httpClient.post<Blob>(`${this.apiBaseUrl}/getSurveyCsvByPost`, result, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    public deleteSurvey(surveyId: number) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${surveyId}`);
    }

    public deleteSurveyAttempt(surveyAttemptId: number) {
        return this.httpClient.delete(`${this.apiBaseUrl}/DeleteSurveyAttempt/${surveyAttemptId}`);
    }

    public deleteQuestion(questionId: number) {
        return this.httpClient.delete(`${this.apiBaseUrl}/deleteQuestion/${questionId}`);
    }

    public patchSurvey(survey: Survey) {
        return this.httpClient.patch(`${this.apiBaseUrl}`, survey);
    }

    public patchSurveyQuestion(question: SurveyQuestion) {
        return this.httpClient.patch(`${this.apiBaseUrl}/patchSurveyQuestion`, question);
    }

    public sendSurvey(surveyReciever: SurveyReciever) {
        return this.httpClient.post(`${this.apiBaseUrl}/sendSurvey`, surveyReciever);
    }

    public putSharedSurvey(sharedSurvey: SharedSurvey) {
        return this.httpClient.put<SharedSurvey>(`${this.apiBaseUrl}/putSharedSurvey`, sharedSurvey);
    }
}