import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LinkedInApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = `${environment.apiUrl}linkedIn`;
    }
    getAccessToken(code, redirectUrl, state) {
        var url = `${this.apiBaseUrl}/GetAccessToken?code=${code}&redirectUrl=${redirectUrl}&state=${state}`;
        return this.httpClient.get(url);
    }
    getProfileLiteInfo(accessToken) {
        return this.httpClient.get(`${this.apiBaseUrl}/GetPerson?accessToken=${accessToken}`);
    }
    authenticate(code, state, redirectUrl) {
        return this.httpClient.get(`${this.apiBaseUrl}/Auth?code=${code}&state=${state}&redirectUrl=${redirectUrl}`);
    }
    socialAuth(code, state, redirectUrl) {
        return this.httpClient.get(`${this.apiBaseUrl}/AuthOrg?code=${code}&state=${state}&redirectUrl=${redirectUrl}`);
    }
    createUser(userTmp) {
        return this.httpClient.post(`${this.apiBaseUrl}/createUser`, userTmp);
    }
    linkedInPost(linkedInPost) {
        return this.httpClient.post(`${this.apiBaseUrl}/LinkedInPost`, linkedInPost);
    }
}
LinkedInApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkedInApiService_Factory() { return new LinkedInApiService(i0.ɵɵinject(i1.HttpClient)); }, token: LinkedInApiService, providedIn: "root" });
