import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {NotificationListItem} from '../models/notification-list-item';
import {Observable} from 'rxjs';
import {NotificationListResult} from '../models/notification-list-result';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {

  private apiBaseUrl = `${environment.apiUrl}notification`;

    constructor(private httpClient: HttpClient) { }

    public post() {
      return this.httpClient.post(this.apiBaseUrl, null);
    }

    public getCurrentUserNotifications(page:number, itemsPerPage:number): Observable<NotificationListResult> {
        const params = new HttpParams()
            .set("page", ""+page)
            .set("limit", ""+itemsPerPage);
        return this.httpClient.get<NotificationListResult>(`${this.apiBaseUrl}/user`, { params: params });
    }

    public getCurrentUserUnreadNotificationCount(): Observable<number> {
        return this.httpClient.get<number>(`${this.apiBaseUrl}/user-unread-count`, {});
    }

    public setNotificationRead(notificationId:number): Observable<void> {
        return this.httpClient.post<void>(`${this.apiBaseUrl}/read/${notificationId}`, { });
    }
}
