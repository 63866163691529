import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DashboardApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this._tasksNumber = new BehaviorSubject(0);
        this.tasksNumber$ = this._tasksNumber.asObservable();
        this.apiBaseUrl = `${environment.apiUrl}dashboard`;
    }
    getTasksNumber() {
        this.httpClient.get(`${this.apiBaseUrl}/getTodoTasksCount`).subscribe(val => {
            this._tasksNumber.next(val);
            this.tasksNumber = val;
        });
        return this.tasksNumber$;
    }
    getWidgets() {
        return this.httpClient.get(`${this.apiBaseUrl}/getWidgets`);
    }
    postWidget(widgets) {
        return this.httpClient.post(`${this.apiBaseUrl}/postWidget`, widgets);
    }
    deleteWidget(id) {
        return this.httpClient.delete(`${this.apiBaseUrl}/deleteWidget/${id}`);
    }
    patchWidget(widget) {
        return this.httpClient.patch(`${this.apiBaseUrl}/patchWidget`, widget);
    }
    getTasks() {
        return this.httpClient.get(`${this.apiBaseUrl}/getTasks`);
    }
    getAllNewTasks(page, pageSize, selectedTypeId, selectedAdminId, isActivity, sortOrder, isTodoTaskCompleted) {
        var url = `${this.apiBaseUrl}/getAllNewTasks?page=${page}&pageSize=${pageSize}`;
        if (selectedTypeId) {
            url = url.concat(`&taskTypeId=${selectedTypeId}`);
        }
        if (selectedAdminId) {
            url = url.concat(`&adminId=${selectedAdminId}`);
        }
        url = url.concat(`&isActivity=${isActivity}`);
        url = url.concat(`&sortOrder=${sortOrder}`);
        url = url.concat(`&isTodoTaskCompleted=${isTodoTaskCompleted}`);
        url = url.concat(`&sortOrder=${sortOrder}`);
        return this.httpClient.get(url);
    }
    getFinishedTasks() {
        return this.httpClient.get(`${this.apiBaseUrl}/getFinishedTasks`);
    }
    changeTaskAssignee(item) {
        return this.httpClient.patch(`${this.apiBaseUrl}/changeTaskAssignee`, item);
    }
    completeTask(taskId) {
        this._tasksNumber.next(this.tasksNumber - 1);
        this.tasksNumber--;
        return this.httpClient.patch(`${this.apiBaseUrl}/completeTask`, taskId);
    }
    unCompleteTask(taskId) {
        this._tasksNumber.next(this.tasksNumber + 1);
        this.tasksNumber++;
        return this.httpClient.patch(`${this.apiBaseUrl}/unCompleteTask`, taskId);
    }
    setTaskPending(taskId) {
        return this.httpClient.patch(`${this.apiBaseUrl}/setTaskPending`, taskId);
    }
    updatePriority(task) {
        return this.httpClient.patch(`${this.apiBaseUrl}/updatePriority`, task);
    }
    getKpiLiveData() {
        return this.httpClient.get(`${this.apiBaseUrl}/kpiLiveData`);
    }
    getComponents() {
        return this.httpClient.get(`${this.apiBaseUrl}/getComponents`);
    }
    patchTask(task) {
        return this.httpClient.patch(`${this.apiBaseUrl}/patchTask`, task);
    }
}
DashboardApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardApiService_Factory() { return new DashboardApiService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardApiService, providedIn: "root" });
