import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { ProfileStatusEnum } from '../models/enum/Profile-status-enum';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public firstLogin: boolean = false;


    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public login(email: string, password: string, twoFactorCode?: string) {
        const userTmp = new User();
        userTmp.email = email;
        userTmp.password = password;
        userTmp.twoFactorCode = twoFactorCode;
        return this.http.post<User>(`${environment.apiUrl}user/authenticate`, userTmp)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                // and if TwoFactor isn't required/has been accepted
                if (user && user.token && !user.requireTwoFactor) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.saveUserInStorage(user);
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    public linkedInLogin(user: User) {
        this.saveUserInStorage(user);
        this.currentUserSubject.next(user);
        return user;
    }

    public logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    private saveUserInStorage(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }
}
