import { SharedSurvey } from './shared-survey';
import { SurveyQuestion } from './survey-question';

export class Survey{
    id: number;
    deleted?:boolean;
    title: string;
    externalTitle: string;
    description: string;
    answerLimit: number;
    createdDate: Date;
    isOpen: boolean;
    surveyQuestions: SurveyQuestion[];
}