import { CustomerTaskStatusEnum } from "./enum/customer-task-status-enum";
import { TaskPositionEnum } from "./enum/task-position-enum";
import { TaskSkill } from "./taskSkill";

export class Task {
    public id?: number;
    public customer: string;
    public customerId: number;
    public chosenCustomerProfiles: number[];
    public customerContact: string;
    public description: string;
    public freeText: string;
    public title: string;
    public jobId: string;
    public header: string;
    public intro: string;
    public location: string;
    public startDate: Date;
    public endDate: Date;
    public dateFreeText: string;
    public deadline: Date;
    public adminId: number;
    // customer managment modal
    public isNewProject: boolean = false;
    public consultantRoleId?: number;
    public yearsOfExperience: string;
    public isTeam: boolean = false;
    public isRemote: boolean = false;
    public hours: string;
    public travelActivity: boolean = false;
    public projectOwner: string;
    public isPublishable: boolean = false;
    public option: number;
    public taskSkills: TaskSkill[];
    public taskSkillsDeleted: TaskSkill[];
    public taskPosition: TaskPositionEnum;
    public hasChosenProfiles: boolean;
    public languageRequirement: string;
    public customerTaskStatus: CustomerTaskStatusEnum
    public comment: string;
    public commentDate: Date;
    public CommentAdmin: string;
}
