import { TaskTypeEnum } from "src/app/core/models/enum/task-type-enum";
import { TodoTask } from "../todo-task"

export class InvitationSentTask extends TodoTask {

  taskDescription = "Nye konsulenter er blevet inviteret til samtale. Venligst klik her for at se den relevante opgave.";
  taskName = "Ny invitation fra kunde";
  completeTaskManually = true;

  constructor(id: number, fullname: string, relatedContext: number) {
    super(id, fullname);
    this.relatedContext = relatedContext
  }

  public getRouteLink(): string {
    return `/tasks/${this.relatedContext}/selected`;
  }

}