/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./notification-menu.component";
import * as i4 from "../../../core/api/notification-api.service";
import * as i5 from "../../../core/services/notification.service";
import * as i6 from "@angular/router";
var styles_NotificationMenuComponent = [i0.styles];
var RenderType_NotificationMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationMenuComponent, data: {} });
export { RenderType_NotificationMenuComponent as RenderType_NotificationMenuComponent };
function View_NotificationMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "notification-empty"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Du har ingen notifikationer\n"]))], null, null); }
function View_NotificationMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "notification-pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "notification-page-button"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrevPageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Side ", " af ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "notification-page-button"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNextPageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.page <= 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.page + 1); var currVal_2 = _co.getTotalNumberOfPages(); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = (_co.page >= (_co.getTotalNumberOfPages() - 1)); _ck(_v, 5, 0, currVal_3); }); }
function View_NotificationMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "notification-item-body-collapsible notification-item-body-collapsed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "notification-item-body-summary"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "notification-item-body-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.content; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.createdDate, _co.dateFormatEnum.Full)); _ck(_v, 4, 0, currVal_1); }); }
function View_NotificationMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "notification-item-body-collapsible notification-item-body-expanded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "notification-item-body-summary"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "notification-item-body-other"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "notification-item-body-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGoToClick(_v.parent.context.$implicit.link) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["G\u00E5 til"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "notification-item-body-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵppd(9, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.content; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.createdDate, _co.dateFormatEnum.Full)); _ck(_v, 8, 0, currVal_1); }); }
function View_NotificationMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "notification-list-item"]], [[2, "notification-unread", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickNotificationItem($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "notification-item-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationMenuComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationMenuComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.expandedNotificationId != _v.context.$implicit.id); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.expandedNotificationId == _v.context.$implicit.id); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isRead; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.header; _ck(_v, 2, 0, currVal_1); }); }
export function View_NotificationMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "notification-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Notifikationer\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationMenuComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationMenuComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "notification-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationMenuComponent_3)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalNotifications === 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.totalNotifications !== 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.notificationListItems; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_NotificationMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-menu", [], null, null, null, View_NotificationMenuComponent_0, RenderType_NotificationMenuComponent)), i1.ɵdid(1, 245760, null, 0, i3.NotificationMenuComponent, [i4.NotificationApiService, i5.NotificationService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationMenuComponentNgFactory = i1.ɵccf("app-notification-menu", i3.NotificationMenuComponent, View_NotificationMenuComponent_Host_0, {}, { onUnreadCountChanged: "onUnreadCountChanged" }, []);
export { NotificationMenuComponentNgFactory as NotificationMenuComponentNgFactory };
