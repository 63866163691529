import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  private _setTodosVisibility = new BehaviorSubject<boolean>(false);

  setTodosVisibility$ = this._setTodosVisibility.asObservable();

  constructor() { }

  public toggleTodoList(value) {
    this._setTodosVisibility.next(value);
  }
}
