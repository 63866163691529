import { EventEmitter } from '@angular/core';
import { ProfileDetailModeEnum } from '../models/enums/profile-detail-mode-enum';
import { ProfileStatusEnum } from 'src/app/core/models/enum/Profile-status-enum';
import { ProfileSectionStatusEnum } from 'src/app/core/models/enum/profile-section-status-enum';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/authentiation.service";
import * as i2 from "../../core/api/profile-api.service";
import * as i3 from "../../core/services/spinner.service";
export class ProfileDetailsService {
    constructor(authenticationService, profileApiService, spinner) {
        this.profileApiService = profileApiService;
        this.spinner = spinner;
        this.onDataSaved = new EventEmitter();
        this.disableEditModeAttr = false;
        this.profileIdSubject = new BehaviorSubject(0);
        this.cvLangSubject = new BehaviorSubject(null);
        this.cvLang$ = this.cvLangSubject.asObservable();
        authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }
    disableEditMode() {
        this.disableEditModeAttr = true;
    }
    setProfileId(profileId) {
        this.profileId = profileId;
        this.profileIdSubject.next(profileId);
    }
    getProfileId() {
        return this.profileId;
    }
    getProfileIdObserver() {
        return this.profileIdSubject.asObservable();
    }
    setProfileDetailModeEnum(profileDetailModeEnum) {
        this.profileDetailModeEnum = profileDetailModeEnum;
    }
    setProfileStatus(profileStatus) {
        this.profileStatus = profileStatus;
    }
    // Method to update the profileSectionStatus, which can be called by each of the profile section components
    updateProfileSectionStatus(sectionEnum, statusEnum) {
        const updatedStatus = this.profileStatus.profileSectionStatuss.map(section => {
            if (section.profileSectionEnum === sectionEnum) {
                section.profileSectionStatusEnum = statusEnum;
            }
            return section;
        });
        this.profileStatus.profileSectionStatuss = updatedStatus;
    }
    // Create a method to update the ProfileStatusEnum
    updateProfileStatusEnum(statusEnum) {
        this.profileStatus.profileStatusEnum = statusEnum;
    }
    getProfileSectionStatus() {
        if (!this.profileStatus) {
            return [];
        }
        return this.profileStatus.profileSectionStatuss;
    }
    getProfileStatus() {
        if (this.profileStatus) {
            return this.profileStatus.profileStatusEnum;
        }
        return ProfileStatusEnum.Na;
    }
    getProfileDetailModeEnum() {
        return this.profileDetailModeEnum;
    }
    getCurrentUser() {
        return this.currentUser;
    }
    isAdmin() {
        return this.profileDetailModeEnum === ProfileDetailModeEnum.Admin ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.AdminEdit;
    }
    isProfile() {
        return this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileCreate ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileEdit;
    }
    editMode() {
        if (this.disableEditModeAttr) {
            return false;
        }
        return this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileCreate ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.ProfileEdit ||
            this.profileDetailModeEnum === ProfileDetailModeEnum.AdminEdit ||
            this.currentUser.canEdit !== false;
    }
    getSectionStatus(profileSectionEnum) {
        if (this.profileStatus) {
            return this.profileStatus.profileSectionStatuss.find((profileSection) => profileSection.profileSectionEnum === profileSectionEnum).profileSectionStatusEnum;
        }
        return ProfileSectionStatusEnum.NA;
    }
    isProfileUnderCreation() {
        if (this.profileStatus) {
            return this.profileStatus.profileStatusEnum === ProfileStatusEnum.NotTotalCreated;
        }
        return false;
    }
    initProfileSectionStatus() {
        this.spinner.show();
        this.profileApiService.getProfileStatus(this.profileId).subscribe(result => {
            this.setProfileStatus(result);
            this.spinner.hide();
        });
    }
    get lang() {
        return this.cvLangSubject.value;
    }
    setLang(lang) {
        this.cvLangSubject.next(lang);
    }
}
ProfileDetailsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileDetailsService_Factory() { return new ProfileDetailsService(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.ProfileApiService), i0.ɵɵinject(i3.SpinnerService)); }, token: ProfileDetailsService, providedIn: "root" });
