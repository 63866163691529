import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
    return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}

export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
}

export function setDownloadPath(filePath: string) {
    return filePath = filePath ? `${environment.apiUrl}file/getFile?path=${filePath}` : undefined;
  }

export function isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean {
    return value !== undefined && value !== null;
}

export function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return '';
    }
}

/**
 * Return name of the month in Danish.
 */
export function getMonthName(month: number): string;
/**
 * Return name of the month.
 *
 * If `isUk` is `true`, returns month in English, otherwise in Danish.
 */
export function getMonthName(month: number, isUk: boolean): string;
export function getMonthName(month: number, isUk: boolean = false): string {
    switch (month) {
        case 1:
            return isUk ? 'January' : 'Januar';
        case 2:
            return isUk ? 'February' : 'Februar';
        case 3:
            return isUk ? 'March' : 'Marts';
         case 4:
            return 'April';
        case 5:
            return isUk ? 'May' : 'Maj';
        case 6:
            return isUk ? 'June' : 'Juni';
        case 7:
            return isUk ? 'July' : 'Juli';
        case 8:
            return 'August';
        case 9:
            return 'September';
        case 10:
            return isUk ? 'October' : 'Oktober';
        case 11:
            return 'November';
        case 12:
            return 'December';
    }

    return '';
}

export function toFormData<T>(formValue: T): FormData {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
        let value = formValue[key];
        let name = key;
        if (key.indexOf('ngb') > -1) {
            value = convertToDate(value).toJSON();
            name = key.replace('ngb', '');
        }
        formData.append(name, value === undefined || value === 'null' || value === null ? '' : value);
    }

    return formData;
}

export function convertToNgbDate(date: Date): NgbDate {
    if (!date) {
        return undefined;
    }

    const dateTmp = new Date(date);
    const ngbDate = new NgbDate(dateTmp.getFullYear(), dateTmp.getMonth() + 1, dateTmp.getDate());
    return ngbDate;
}

export function convertToDate(ngbDate: NgbDate): Date {
    if (!ngbDate) {
        return undefined;
    }

    const date = new Date(ngbDate.year,
        ngbDate.month - 1,
        +ngbDate.day,
        12, 0, 0);
    return date;
}

//Convert a c# friendly string representation of Date
export function convertToDateString(date: Date) : string {
    //var date = new Date();
    var day = date.getDate();       // yields date
    var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
    var year = date.getFullYear();  // yields year
    var hour = date.getHours();     // yields hours
    var minute = date.getMinutes(); // yields minutes
    var second = date.getSeconds(); // yields seconds
    var secondString = second < 10 ? '0' + second.toString() : second.toString()
    return addZero(day) + "/" + addZero(month) + "/" + year + " " + hour + ':' + minute + ':' + secondString;
}

function addZero(n) {
    return n < 10 ? '0' + n : n;
}

export function regExpEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
    return element && element.className && element.className.split &&
        element.className.split(/\s+/).indexOf(className) >= 0;
}

if (typeof Element !== 'undefined' && !Element.prototype.closest) {
    // Polyfill for ie10+

    if (!Element.prototype.matches) {
        // IE uses the non-standard name: msMatchesSelector
        Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    Element.prototype.closest = function (s: string) {
        let el = this;
        if (!document.documentElement.contains(el)) {
            return null;
        }
        do {
            if (el.matches(s)) {
                return el;
            }
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

export function closest(element: HTMLElement, selector): HTMLElement {
    if (!selector) {
        return null;
    }

    return element.closest(selector);
}

export function escapeHtml(unescaped: string): string {
    return unescaped.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export function removeHtml(unescaped: string): string {
    return unescaped.replace(/&/g, "")
        .replace(/</g, "")
        .replace(/>/g, "")
        .replace(/"/g, "")
        .replace(/'/g, "");
}

export function getEnumValues(type: any): number[] {
    return Object.keys(type).map(x => Number(x)).filter(x => isNaN(x) === false);
}
