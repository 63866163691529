import { Component, OnInit, Input } from "@angular/core";
import { Task } from "../../../core/models/task";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
    NgbActiveModal,
    NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { TaskApiService } from "../../../core/api/task-api.service";
import { SpinnerService } from "../../../core/services/spinner.service";
import { ToastrService } from "ngx-toastr";
import { convertToNgbDate, convertToDate } from "../../../core/Util/util";
import { NgbDateCustomParserFormatter } from "../../../core/Util/ngb-date-parser-customer-formatter";
import { AuthenticationService } from "../../../core/services/authentiation.service";
import { User } from "../../../core/models/user";
import { GeneralHelper } from "src/app/core/helpers/general-helper";
import { CommonDataService } from "src/app/core/api/common-data.service";
import { ConsultantRole } from "src/app/core/models/consultant-role";
import { Observable } from "rxjs";
import { Customer } from "src/app/core/models/customer";
import { isNullOrUndefined } from "util";
import { TaskSkill } from "src/app/core/models/taskSkill";
import { AdminApiService } from "src/app/core/api/admin-api.service";
import { Admin } from "src/app/core/models/admin";
import { UserRoleEnum } from "src/app/core/models/enum/user-role-enum";
import { CustomerProfile } from "src/app/core/models/customer-profile";
import { CustomerProfileApiService } from "src/app/core/api/customer-profile-api.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-task-managment-modal",
    templateUrl: "./task-managment-modal.component.html",
    styleUrls: ["./task-managment-modal.component.scss"],
    providers: [
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
    ],
})
export class TaskManagmentModalComponent implements OnInit {
    @Input() public taskId?: number;

    public task: Task;
    public taskForm: FormGroup;
    public currentUser: User;
    public requiredCompetences: TaskSkill[] = [];
    public wantedCompetences: TaskSkill[] = [];
    public techonologies: TaskSkill[] = [];
    public taskSkillsForDeletion: TaskSkill[] = [];
    public selectableYear = [];
    public months: Array<Number> = [];
    public consultantRoles$: Observable<ConsultantRole[]>;
    public customers$: Observable<Customer[]>;
    public customerProfiles$: Observable<CustomerProfile[]>;
    public selectedCustomerProfiles: CustomerProfile[] = [];
    public admins$: Observable<Admin[]>;
    public editMode: boolean = false;
    public isTaskOpen: boolean = true;
    public isConsultantOpen: boolean = true;
    public isVisionOpen: boolean = true;
    public isAdmin: boolean = false;
    public isFritextFieldShown: boolean = false;
    public selectedOption: number;
    public formSubmitted: boolean = false;
    toastrMsgs: any;

    constructor(
        private formBuilder: FormBuilder,
        private taskApiService: TaskApiService,
        private activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private spinner: SpinnerService,
        private authenticationService: AuthenticationService,
        private commonDataService: CommonDataService,
        private adminApiService: AdminApiService,
        private customerProfileApiService: CustomerProfileApiService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.translateService
            .stream("customerTasksPage.toastrMsgs")
            .subscribe((result) => {
                this.toastrMsgs = result;
            });
        this.isFritextFieldShown = false;
        if (this.taskId) {
            this.editMode = true;
        }
        this.authenticationService.currentUser.subscribe(
            (x) => (this.currentUser = x)
        );
        this.isAdmin = this.currentUser.userRoleEnumDto == UserRoleEnum.Admin;
        this.setMonths();
        this.selectableYear = GeneralHelper.getListOfYear();
        this.task = new Task();
        this.consultantRoles$ = this.commonDataService.getConsultantRoles();
        this.customers$ = this.commonDataService.getCustomers();
        this.admins$ = this.adminApiService.getAllAdmins();
        if (this.editMode) {
            this.taskApiService.get(this.taskId).subscribe((task) => {
                this.task = task;
                this.buildForm();
                if (this.isAdmin) {
                    this.customerProfiles$ =
                        this.customerProfileApiService.getByCustomerId(
                            this.task.customerId
                        );
                    this.customerProfiles$.subscribe((cps) => {
                        this.selectedCustomerProfiles = cps
                            ? cps
                                  .filter((cp) =>
                                      this.task.chosenCustomerProfiles.some(
                                          (ccp) => ccp === cp.id
                                      )
                                  )
                                  .map((cp) => cp)
                            : [];
                    });
                }
            });
        } else {
            this.buildForm();
        }
    }

    public onSelectChange(event) {
        let select = event.target as HTMLSelectElement;
        if (select.value == "0: null") {
            select.classList.add("with-placeholder");
            select.classList.remove("without-placeholder");
        } else {
            select.classList.add("without-placeholder");
            select.classList.remove("with-placeholder");

            let value = select.value
                ? +select.value.replace(/^\d+: (\d+)/, "$1")
                : null;
            if (
                select.getAttribute("formcontrolname") === "customerId" &&
                this.isAdmin
            )
                this.customerProfiles$ =
                    this.customerProfileApiService.getByCustomerId(value);
        }

        if (select.getAttribute("formcontrolname") === "customerId")
            this.selectedCustomerProfiles = [];
    }

    currentPage: number = 1;

    goToNextPage(): void {
        // Check if we're on the first page
        if (this.currentPage === 1) {
            // List of form controls to validate for the first page
            const firstPageControls = [
                "title",
                "description",
                "isNewProject",
                "location",
            ];

            // Check if all specified controls are valid
            const isFirstPageValid = firstPageControls.every((controlName) => {
                const control = this.taskForm.get(controlName);
                if (control) {
                    control.markAsTouched(); // This will trigger the display of validation messages if any field is invalid
                    return control.valid;
                }
                return false;
            });

            // If all controls are valid, navigate to the next page
            if (isFirstPageValid) {
                this.currentPage++;
            } else {
                // Optionally show a toastr message or some other indication that validation failed
                this.toastr.error(
                    "Udfyld alle de nødvendige felter før du fortsætter"
                );
            }
        }
    }

    public save() {
        if (this.taskForm.invalid) {
            this.toastr.error("Ikke alle felter er udfyldt korrekt");
            this.formSubmitted = true;
            return;
        }

        this.spinner.show();
        Object.assign(this.task, this.taskForm.value);
        this.task.startDate = convertToDate(this.taskForm.value.ngbStartDate);
        this.task.endDate = convertToDate(this.taskForm.value.ngbEndDate);
        this.task.deadline = convertToDate(this.taskForm.value.ngbDeadline);

        let taskSkills = this.requiredCompetences
            .concat(this.wantedCompetences)
            .concat(this.techonologies);

        this.task.taskSkillsDeleted = this.taskSkillsForDeletion;
        this.task.taskSkills = taskSkills.filter((ts) => ts.id === null);
        this.task.chosenCustomerProfiles = this.selectedCustomerProfiles.map(
            (p) => p.id
        );
        this.taskApiService.save(this.task).subscribe((result) => {
            if (this.taskId) {
                this.toastr.success(this.toastrMsgs.changesSaved);
            } else {
                this.toastr.success(this.toastrMsgs.taskCreated);
            }

            this.activeModal.close(result);
            this.spinner.hide();
        });
    }

    public handleSelectPerson(data: {
        person: CustomerProfile;
        selected: boolean;
    }) {
        const { person, selected } = data;
        if (
            selected &&
            !this.selectedCustomerProfiles.some((p) => p.id === person.id)
        ) {
            // Add to Array
            this.selectedCustomerProfiles.push(person);
        }
        if (
            !selected &&
            this.selectedCustomerProfiles.some((p) => p.id === person.id)
        ) {
            // Remove from array
            this.selectedCustomerProfiles.splice(
                this.selectedCustomerProfiles.findIndex(
                    (p) => p.id === person.id
                ),
                1
            );
        }
    }

    public selectedCustomerProfilesHas(person: CustomerProfile) {
        return this.selectedCustomerProfiles.some((p) => p.id === person.id);
    }

    public getConsultantName(person: CustomerProfile) {
        return person.firstname + " " + person.surname;
    }

    // Mapper to TaskSkills
    private mapTaskSkills = (
        skillName: string,
        isRequired: boolean,
        isTech: boolean
    ): TaskSkill => {
        let ts = new TaskSkill();
        ts.id = null;
        ts.skill = skillName;
        ts.isRequired = isRequired;
        ts.isTech = isTech;
        return ts;
    };

    public addRequiredCompetence() {
        let competence: string = this.taskForm.get("requiredCompetences").value;
        if (!isNullOrUndefined(competence)) {
            competence = competence.trim();
            if (competence != "") {
                this.taskForm.controls["requiredCompetences"].reset();
                this.requiredCompetences.push(
                    this.mapTaskSkills(competence, true, false)
                );
            }
        }
    }

    public addWantedCompetence() {
        let competence: string = this.taskForm.get("wantedCompetences").value;
        competence = competence.trim();
        if (!isNullOrUndefined(competence)) {
            competence = competence.trim();
            if (competence != "") {
                this.taskForm.controls["wantedCompetences"].reset();
                this.wantedCompetences.push(
                    this.mapTaskSkills(competence, false, false)
                );
            }
        }
    }

    public addRequiredTechnology() {
        let competence: string = this.taskForm.get(
            "requiredTechnologies"
        ).value;
        competence = competence.trim();
        if (!isNullOrUndefined(competence)) {
            competence = competence.trim();
            if (competence != "") {
                this.taskForm.controls["requiredTechnologies"].reset();
                this.techonologies.push(
                    this.mapTaskSkills(competence, true, true)
                );
            }
        }
    }

    public close() {
        this.activeModal.close();
    }

    public deleteRequiredCompetence(competence: TaskSkill) {
        this.requiredCompetences.splice(
            this.requiredCompetences.indexOf(competence),
            1
        );
        if (competence.id !== null) this.taskSkillsForDeletion.push(competence);
    }

    public deleteWantedCompetence(competence: TaskSkill) {
        this.wantedCompetences.splice(
            this.wantedCompetences.indexOf(competence),
            1
        );
        if (competence.id !== null) this.taskSkillsForDeletion.push(competence);
    }

    public deleteRequiredTechnology(technology: TaskSkill) {
        this.techonologies.splice(this.techonologies.indexOf(technology), 1);
        if (technology.id !== null) this.taskSkillsForDeletion.push(technology);
    }

    //Events
    public onNewProjectChanged(event) {}

    private setMonths() {
        for (let index = 1; index < 25; index++) {
            this.months.push(index);
        }
    }

    private buildForm() {
        let hasOption = this.task.option != null || undefined ? true : false;
        this.taskForm = this.formBuilder.group({
            title: [this.task.title, Validators.required],
            customer: [this.task.customer],
            customerContact: [this.task.customerContact],
            customerId: [this.task.customerId],
            description: [this.task.description, Validators.required],
            freeText: [this.task.freeText],
            header: [this.task.header],
            location: [this.task.location, Validators.required],
            ngbStartDate: [convertToNgbDate(this.task.startDate)],
            ngbEndDate: [convertToNgbDate(this.task.endDate)],
            dateFreeText: [this.task.dateFreeText],
            ngbDeadline: [convertToNgbDate(this.task.deadline)],
            isNewProject: [this.task.isNewProject],
            consultantRoleId: [this.task.consultantRoleId, Validators.required],
            yearsOfExperience: [
                this.task.yearsOfExperience,
                Validators.required,
            ],
            hours: [this.task.hours],
            travelActivity: [this.task.travelActivity],
            projectOwner: [this.task.projectOwner],
            isPublishable: [this.task.isPublishable],
            isTeam: [this.task.isTeam],
            // need to add from backend
            chosenCustomerProfiles: [],
            isRemote: [this.task.isRemote],
            option: [this.task.option],
            optionChecked: [hasOption],
            requiredCompetences: [],
            wantedCompetences: [],
            requiredTechnologies: [],
            adminId: [
                this.task.adminId == null
                    ? this.currentUser.adminId
                    : this.task.adminId,
            ],
            languageRequirement: this.task.languageRequirement,
        });

        this.selectedOption = this.task.option;
        this.isFritextFieldShown = this.task.dateFreeText ? true : false;
        this.splitTaskSkills();
    }

    private splitTaskSkills() {
        const splitTaskSkills = TaskSkill.split(this.task.taskSkills);
        splitTaskSkills.requiredCompetences.forEach((ts) => {
            this.requiredCompetences.push(ts);
        });
        splitTaskSkills.wantedCompetences.forEach((ts) => {
            this.wantedCompetences.push(ts);
        });
        splitTaskSkills.technologies.forEach((ts) => {
            this.techonologies.push(ts);
        });
    }

    private get f() {
        return this.taskForm.controls;
    }
}
