import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class TodoService {
    constructor() {
        this._setTodosVisibility = new BehaviorSubject(false);
        this.setTodosVisibility$ = this._setTodosVisibility.asObservable();
    }
    toggleTodoList(value) {
        this._setTodosVisibility.next(value);
    }
}
TodoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TodoService_Factory() { return new TodoService(); }, token: TodoService, providedIn: "root" });
