import { TodoTask } from '../todo-task';
import { TaskTypeEnum } from 'src/app/core/models/enum/task-type-enum';
export class OldCvTask extends TodoTask {
    constructor() {
        super(...arguments);
        this.taskDescription = `${this.fullName}'s cv er ikke blevet opdateret i mere end et år. ` +
            'Venligst informerer konsulenten angående dette';
        this.type = TaskTypeEnum.OldCv;
        this.taskName = "Opdater CV";
        this.completeTaskManually = false;
    }
    getRouteLink() {
        return `/search/profile/edit/${this.profileId}/personal-data`;
    }
}
