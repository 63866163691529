// import { ErrorHandler, Injectable, Injector } from '@angular/core';
// import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { HttpInterceptor, HttpHandler } from '@angular/common/http';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../services/spinner.service';
import { Injectable } from '@angular/core';
import { EMPTY_PARSE_LOCATION } from '@angular/compiler';
import { AuthenticationService } from '../services/authentiation.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
// https://scotch.io/bar-talk/error-handling-with-angular-6-tips-and-best-practices192

    constructor(private authenticationService: AuthenticationService,
                private toastr: ToastrService,
                private spinner: SpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.spinner.hide();
                    let errorMessage = '';
                    let reThrowError = true;
                    let hideError = false;
                    if (error.status === 401 || error.status === 403) {
                        this.authenticationService.logout();
                        location.reload();
                    } else if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error}`;
                    } else {
                        if (error.status === 400) {
                            errorMessage = error.error;
                            reThrowError = false;
                        } else if(error.status === 404) {
                            hideError = true
                            errorMessage = error.error;
                        } else {
                            errorMessage = 'Der er desværre sket en fejl!';
                        }
                    }
                    if (!hideError){
                        this.toastr.error(errorMessage);
                    }
                    if (reThrowError) {
                        return throwError(errorMessage);
                    }
                    return throwError(error);
                })
            );
    }
}
