import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from './api/api.module';
import { ModalService } from './modal/modal.service';
import { ModalStateService } from './modal/modal-state.service';
import { ConfirmModalService } from './helpers/confirm-modal.service';
import { LangComponent } from './lang/lang.component';

/*
  This module must be included only once in AppModule
  Import here modules with services that you need through the whole app
*/
@NgModule({
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        ApiModule
    ],
    providers: [
        ModalService,
        ModalStateService,
        ConfirmModalService
    ],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule,
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
